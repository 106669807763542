import { assertDefined } from '@/lib/common/guards/assertDefined'
import { subscribe } from '@/lib/common/socket/app-sync'
import { IEventMessage } from '@/lib/common/socket/IEventMessage'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('socket.registerJobHandlers')

export const registerJobHandlers = (
  jobId: string,
  handlers: {
    [key: string]: (data: any, subscription: any) => void
  }
) => {
  const subscription = subscribe(`job-${jobId}`, async (message: IEventMessage) => {
    if (message.type !== 'error') {
      log.debug('job response', message)
    } else {
      log.error('job error', message)
    }
    const handler = handlers[message.type]
    assertDefined(handler, 'Handler missing for type ' + message.type)
    handler(message.data, subscription)
  })
  log.info('subscription', jobId, handlers, subscription)
}
