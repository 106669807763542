import { IArrayElement } from '@/lib/client/common/util/IArrayElement'
import { IElement } from '@/lib/client/redux/campaign/edit/types'
import { IFormState } from '@/lib/client/redux/form/types'
import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'
import { ICampaignElementType } from '@/lib/common/entities/campaign/element/ICampaignElementType'

export type IDraftElementForm = {
  element?: ICampaignElement
  focusedVariantId: number | undefined
  type: DraftType
  originalElement?: ICampaignElement
  isSingleVariantMode: boolean
  formSubmitted: boolean
}

export type IDraftElementState = IFormState<IDraftElementForm>

export enum DraftType {
  Default,
  EditSuggestion
}

export enum DraftError {
  DuplicateVariant = 'duplicate-variant'
}

export type IDraftElement<T extends ICampaignElementType> = IElement<
  IDraftElementForm['element'],
  T
>
export type IDraftVariants<T extends ICampaignElementType> = NonNullable<
  IDraftElement<T>['variants']
>
export type IDraftVariant<T extends ICampaignElementType> = IArrayElement<IDraftVariants<T>>
