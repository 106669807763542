import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { getElements } from '@/lib/client/redux/campaign/edit/selectors/common'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import {
  IURLPreviewImageSelectEvent,
  IURLPreviewTextSelectEvent
} from '@/lib/client/redux/preview/types'
import { IThunkConfig } from '@/lib/client/redux/types'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('selectElement')

type IEvent = IURLPreviewTextSelectEvent | IURLPreviewImageSelectEvent

export const selectElement = createAsyncThunk<void, IEvent, IThunkConfig>(
  'editCampaign/selectElement',
  async (ev, { getState, dispatch }) => {
    const selector = ev.selector
    const elements = getElements(getState())
    const element = elements.find(element => element.selector === selector)
    if (element === undefined) {
      dispatch(EditCampaignAction.createElement(ev))
    }
  }
)
