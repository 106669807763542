import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { validateEditCampaign } from '@/lib/client/redux/campaign/util/validateEditCampaign'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const validateFormValue = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    actionCreator: EditCampaignAction.setFormValue,
    effect: async (action, { getState, dispatch }) => {
      const id = action.payload.id
      const { values, info, errors } = EditCampaignSelector.getState(getState())
      const newErrors = await validateEditCampaign(id, values, info, errors)
      dispatch(EditCampaignAction.setFormErrors(newErrors))
    }
  })
}
