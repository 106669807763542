import {
  IClientBaseVariant,
  INewBaseVariant,
  IServerBaseVariant,
  ISuggestedBaseVariant
} from '@/lib/common/entities/campaign/variant/IBaseVariant'

export interface ITextInfo {
  value?: string
  html?: string
  tone?: ITextVariantTone
  creationMethod: ITextVariantCreationMethod
  textType: TextVariantType
  styles: ITextVariantStyle[]
}

export type IServerTextVariant = IServerBaseVariant<'text', ITextInfo>
export type IClientTextVariant = IClientBaseVariant<'text', ITextInfo>
export type INewTextVariant = INewBaseVariant<'text', ITextInfo>
export type ISuggestedTextVariant = ISuggestedBaseVariant<'text', ITextInfo>
export type ITextVariant = IClientTextVariant | INewTextVariant | ISuggestedTextVariant

export type ITextVariantStyle =
  | ITextColorStyle
  | IBackgroundColorStyle
  | IFontSizeStyle
  | IBorderColorStyle
  | IBorderWidthStyle
  | IPaddingStyle
  | IBorderRadiusStyle

export enum TextVariantType {
  Normal = 'normal',
  Advanced = 'advanced'
}

export enum TextVariantStyleType {
  TextColor = 'textColor',
  BackgroundColor = 'backgroundColor',
  FontSize = 'fontSize',
  BorderColor = 'borderColor',
  BorderWidth = 'borderWidth',
  Padding = 'padding',
  BorderRadius = 'borderRadius'
}

export interface ITextColorStyle {
  type: TextVariantStyleType.TextColor
  value: number
}

export interface IBackgroundColorStyle {
  type: TextVariantStyleType.BackgroundColor
  value: number
}

export interface IFontSizeStyle {
  type: TextVariantStyleType.FontSize
  value: number
}

export interface IBorderColorStyle {
  type: TextVariantStyleType.BorderColor
  value: number
}

export interface IBorderWidthStyle {
  type: TextVariantStyleType.BorderWidth
  value: number
}

export interface IPaddingStyle {
  type: TextVariantStyleType.Padding
  value: IPaddingValue
}

export interface IBorderRadiusStyle {
  type: TextVariantStyleType.BorderRadius
  value: IBorderRadiusValue
}

export interface IPaddingValue {
  top: number
  right: number
  bottom: number
  left: number
}

export interface IBorderRadiusValue {
  topLeft: number
  topRight: number
  bottomLeft: number
  bottomRight: number
}

export type ITextVariantTone =
  | 'formal'
  | 'informal'
  | 'optimistic'
  | 'assertive'
  | 'encouraging'
  | 'friendly'
  | 'curious'
  | 'neutral'

export type ITextVariantCreationMethod = 'control' | 'custom' | 'generated'

export interface ITextVariantGenerateOptions {
  [key: string]: {
    tone?: ITextVariantTone
    currentVariants: string[]
    lengthRange: number // +- characters
    topPerformingVariant?: string
  }
}
