import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  IPreviewState,
  IURLPreviewEvent,
  PreviewInteractionMode,
  PreviewSize,
  URLPreviewIFrameStatus,
  URLPreviewLoadStatus
} from '@/lib/client/redux/preview/types'

const initialState: IPreviewState = {
  url: '',
  interactionMode: undefined,
  size: PreviewSize.Desktop,
  iframeStatus: URLPreviewIFrameStatus.Initial,
  loadStatus: URLPreviewLoadStatus.Initial,
  errorCount: 0
}

export const PreviewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<string>) => {
      state.url = action.payload
      state.interactionMode = undefined
      state.loadStatus = URLPreviewLoadStatus.Initial
      state.errorCount = 0
    },

    // undefined sets the interaction mode to default for that step
    setInteractionMode: (state, action: PayloadAction<PreviewInteractionMode | undefined>) => {
      state.interactionMode = action.payload
    },

    setSize: (state, action: PayloadAction<PreviewSize>) => {
      state.size = action.payload
    },

    processEvent: (state, action: PayloadAction<IURLPreviewEvent>) => {
      switch (action.payload.type) {
        case 'ready':
          state.iframeStatus = URLPreviewIFrameStatus.Ready
          state.errorCount = 0
          break
        case 'load':
          state.loadStatus = URLPreviewLoadStatus.Success
          state.errorCount = 0
          break
        case 'error':
          state.loadStatus = URLPreviewLoadStatus.Error
          state.errorCount++
          break
      }
    }
  }
})
