import { IEmbedMutation } from '@/lib/client/embed/mutation/types'
import { IBaseMutation } from '@/lib/client/embed/shared/types'
import {
  ITextVariantStyle,
  TextVariantStyleType,
  TextVariantType
} from '@/lib/common/entities/campaign/variant/ITextVariant'
import { ISampleTextInfo } from '@/lib/server/data/campaign/embed/types'

export function getTextMutations(info: ISampleTextInfo) {
  const { textType, value, html, styles } = info
  const mutations: (IEmbedMutation | IBaseMutation)[] = []
  if ((textType === TextVariantType.Normal || textType === undefined) && value !== undefined) {
    mutations.push({ type: 'textValue', value })
  }
  if (textType === TextVariantType.Advanced && html !== undefined) {
    mutations.push({ type: 'textValue', value: html, html: true })
  }
  if (styles) {
    for (const style of styles) {
      mutations.push(transformStyle(style))
    }
  }
  return mutations
}

function transformStyle(style: ITextVariantStyle): IEmbedMutation | IBaseMutation {
  switch (style.type) {
    case TextVariantStyleType.TextColor:
      return {
        type: 'textColor',
        value: style.value
      }
    case TextVariantStyleType.FontSize:
      return {
        type: 'fontSize',
        value: style.value
      }
    case TextVariantStyleType.BackgroundColor:
      return {
        type: 'backgroundColor',
        value: style.value
      }
    case TextVariantStyleType.BorderColor:
      return {
        type: 'borderColor',
        value: style.value
      }
    case TextVariantStyleType.BorderWidth:
      return {
        type: 'borderWidth',
        value: style.value
      }
    case TextVariantStyleType.Padding:
      return {
        type: 'padding',
        value: {
          top: style.value.top,
          right: style.value.right,
          bottom: style.value.bottom,
          left: style.value.left
        }
      }
    case TextVariantStyleType.BorderRadius:
      return {
        type: 'borderRadius',
        value: {
          topLeft: style.value.topLeft,
          topRight: style.value.topRight,
          bottomLeft: style.value.bottomLeft,
          bottomRight: style.value.bottomRight
        }
      }
  }
}
