import { createSelector } from '@reduxjs/toolkit'

import { IState } from '@/lib/client/redux/types'

export const GenerateImageSelector = {
  getPrompt: (state: IState) => state.generateImage.prompt,
  getStatus: (state: IState) => state.generateImage.status,
  getType: (state: IState) => state.generateImage.type,
  getCategoriesVisible: (state: IState) => state.generateImage.categoriesVisible,
  getImages: createSelector(
    (state: IState) => state.generateImage.status,
    status => {
      if (status?.type === 'result') {
        return status.images.map(status => status.image.url)
      } else {
        return []
      }
    }
  )
}
