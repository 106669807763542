import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { UserApi } from '@/lib/client/redux/user/UserApi'

export const useFeatureFlags = () => {
  const team = getTeamSlug()
  const { data } = UserApi.useGetUserContextQuery({ team })
  return parseFeatureFlags(data?.featureFlags)
}

export const parseFeatureFlags = (flags: string[] | undefined) => {
  const lookup = new Set(flags ?? [])
  return {
    hasCampaignDateCompareFeature: lookup.has('campaign_date_compare'),
    hasCalendarFeature: lookup.has('calendar'),
    hasViewVariantFeature: lookup.has('view_variant'),
    hasVariantAdvancedModeFeature: lookup.has('variant_advanced_mode')
  }
}
