import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { getStep } from '@/lib/client/redux/campaign/edit/selectors/common'
import { CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { PreviewAction } from '@/lib/client/redux/preview/PreviewAction'
import { StorageAction } from '@/lib/client/redux/storage/StorageAction'
import { StorageKey } from '@/lib/client/redux/storage/StorageKey'
import { StorageSelector } from '@/lib/client/redux/storage/StorageSelector'
import { IThunkConfig } from '@/lib/client/redux/types'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('setStepEditElements')

export const setStepEditElements = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/setStepEditElements',
  async (_, { getState, dispatch }) => {
    const step = getStep(getState())
    if (step < CampaignStep.EditElements) {
      const hasSeenModeHelp = StorageSelector.get(getState(), StorageKey.hasSeenModeHelp)
      if (hasSeenModeHelp) {
        dispatch(EditCampaignAction.setStep(CampaignStep.EditElements))
      } else {
        dispatch(StorageAction.set({ key: StorageKey.hasSeenModeHelp, value: true }))
      }
    } else {
      dispatch(EditCampaignAction.setStep(CampaignStep.EditElements))
      dispatch(EditCampaignAction.resetSelectedVariants())
    }
    dispatch(PreviewAction.setInteractionMode(undefined))
  }
)
