import { IStorageKey } from '@/lib/client/common/entities/IStorageKey'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { StorageApi } from '@/lib/client/redux/common/storage/StorageApi'
import { IThunkConfig } from '@/lib/client/redux/types'

export const StorageAction = {
  set: createAsyncThunk<void, { key: IStorageKey; value: any }, IThunkConfig>(
    'storage/set',
    async (args, { dispatch }) => {
      dispatch(StorageApi.endpoints.setStorage.initiate(args))
    }
  )
}
