import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftType } from '@/lib/client/redux/campaign/draft-element/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'

export type INewDraft = {
  element: ICampaignElement
  focusedVariantId?: number
  type?: DraftType
  isSingleVariantMode?: boolean
  originalElement?: ICampaignElement
}

export const setDraft = createAsyncThunk<void, INewDraft | undefined, IThunkConfig>(
  'draftElement/setDraft',
  async (draft, { getState, dispatch }) => {
    if (draft === undefined) {
      const elementId = getState().draftElement.values.element?.id
      if (!elementId) {
        return
      }
      dispatch(EditCampaignAction.ensureValidElementSelection(elementId))
      dispatch(DraftElementAction.init({}))
    } else {
      dispatch(
        DraftElementAction.init({
          element: draft.element,
          focusedVariantId: draft.focusedVariantId,
          type: draft.type,
          isSingleVariantMode: draft.isSingleVariantMode,
          originalElement: draft.originalElement || draft.element
        })
      )
    }
  }
)
