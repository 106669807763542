import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { getDraftElement } from '@/lib/client/redux/campaign/draft-element/selectors/common'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'

interface Args {
  src: string
  thumb?: string
}
export const toggleImageVariant = createAsyncThunk<void, Args, IThunkConfig>(
  'draftElement/toggleImageVariant',
  async ({ src, thumb }, { getState, dispatch }) => {
    const element = getDraftElement<'image'>(getState())
    const variant = element?.variants.find(v => v.info.src === src)
    if (variant) {
      await dispatch(DraftElementAction.deleteVariant(variant))
    } else {
      dispatch(
        DraftElementAction.addVariants({
          variants: [
            {
              __type__: NewVariantType.Draft,
              id: generateTemporaryId(),
              type: 'image',
              info: {
                src,
                thumb: thumb ?? src
              }
            }
          ],
          position: 'end'
        })
      )
    }
  }
)
