import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import React from 'react'

export interface IDialogOptions {
  id?: string
}

export type IDialogAnimationState = 'show' | 'idle' | 'hide'

export interface IDialog<T = any> {
  id: string
  visible: boolean
  animationState: IDialogAnimationState
  element: React.ReactElement
  options?: IDialogOptions
  result?: T
  onResult?: (result?: T) => void
}

interface State {
  dialogs: IDialog[]
}

const initialState: State = {
  dialogs: []
}

export const DialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<IDialog>) => {
      state.dialogs.push(action.payload)
    },

    hideDialog: (state, action: PayloadAction<{ id: string; result?: any }>) => {
      const dialog = state.dialogs.find(dialog => dialog.id === action.payload.id)
      if (dialog) {
        dialog.visible = false
        dialog.result = action.payload.result
      }
    },

    setAnimationState: (
      state,
      action: PayloadAction<{ id: string; state: IDialogAnimationState }>
    ) => {
      const dialog = state.dialogs.find(dialog => dialog.id === action.payload.id)
      if (dialog) {
        dialog.animationState = action.payload.state
      }
    },

    removeDialog: (state, action: PayloadAction<string>) => {
      state.dialogs = state.dialogs.filter(dialog => dialog.id !== action.payload)
    }
  }
})
