import _qs from 'qs'

export const qs = (query: any) => {
  if (typeof query === 'string') {
    return query && !query.startsWith('?') ? '?' + query : query
  } else {
    const value = _qs.stringify(query)
    return value ? '?' + value : ''
  }
}
