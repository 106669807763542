import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftType } from '@/lib/client/redux/campaign/draft-element/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { ITextVariant } from '@/lib/common/entities/campaign/variant/ITextVariant'

export const editVariant = createAsyncThunk<
  void,
  { element: ITextElement; variant: ITextVariant; isSingleVariantMode?: boolean },
  IThunkConfig
>(
  'editCampaign/editVariant',
  async ({ element, variant, isSingleVariantMode = true }, { dispatch }) => {
    const draftElement = { ...element }

    draftElement.variants = draftElement.variants.map(v => {
      if (v.id !== variant.id) return v

      return {
        ...variant,
        __type__: NewVariantType.Draft
      } as any // todo - spencer
    })

    dispatch(
      DraftElementAction.setDraft({
        element: draftElement,
        focusedVariantId: variant.id,
        isSingleVariantMode,
        type: DraftType.EditSuggestion,
        originalElement: element
      })
    )
  }
)
