import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { CampaignApi } from '@/lib/client/redux/campaign/CampaignApi'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { LocationAction } from '@/lib/client/redux/location/LocationAction'
import { IThunkConfig } from '@/lib/client/redux/types'
import { INewCampaign } from '@/lib/common/entities/campaign/ICampaign'

export const submitForm = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/submitForm',
  async (_, { getState, dispatch }) => {
    const team = getTeamSlug()
    const payload = EditCampaignSelector.getForm(getState()) as INewCampaign

    dispatch(EditCampaignAction.setIsSubmitting(true))
    const response = await dispatch(
      CampaignApi.endpoints.createCampaign.initiate({ team, payload })
    )
    if ('data' in response) {
      await dispatch(LocationAction.push({ path: `/campaign/${response.data}`, inTeam: true }))
    } else {
      dispatch(
        EditCampaignAction.setFormError({
          id: 'general',
          error: {
            type: 'general',
            message: 'There was an error creating the campaign.'
          }
        })
      )
    }
    dispatch(EditCampaignAction.setIsSubmitting(false))
  }
)
