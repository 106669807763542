import { IGenerateTextData, IGenerateTextOptions } from '@/lib/client/common/api/text/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { generateTextVariants } from '@/lib/client/redux/campaign/util/generateTextVariants'
import { populateVariantPool } from '@/lib/client/redux/campaign/util/populateVariantPool'
import { IListenersMiddleware } from '@/lib/client/redux/types'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'

export const suggestSimilarVariant = (middleware: IListenersMiddleware) =>
  middleware.startListening({
    actionCreator: EditCampaignAction.suggestSimilarVariant,
    effect: async (action, listenerApi) => {
      const { dispatch, getState } = listenerApi
      const { element, source } = action.payload

      const options: IGenerateTextOptions = {
        value: source.info.value,
        tone: source.info.tone,
        styles: source.info.styles
      }
      const position = element.variants.findIndex(v => v.id === source.id)
      const data: IGenerateTextData = {
        url: EditCampaignSelector.getIngestedUrl(getState()),
        element,
        options,
        count: 1,
        position,
        type: NewVariantType.Suggested
      }
      const variants = await generateTextVariants(data, listenerApi)
      dispatch(EditCampaignAction.addVariantsToElement({ element, variants, position }))

      await populateVariantPool({ ...data, count: 10 }, listenerApi)
    }
  })
