import { createSelector } from '@reduxjs/toolkit'

import { IVariantPool } from '@/lib/client/redux/campaign/edit/types'
import { IState } from '@/lib/client/redux/types'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import {
  ICampaignVariant,
  IClientCampaignVariant
} from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { ISuggestedImageVariant } from '@/lib/common/entities/campaign/variant/IImageVariant'
import { ISuggestedTextVariant } from '@/lib/common/entities/campaign/variant/ITextVariant'
import {
  isClientVariant,
  isNewVariant,
  isSuggestedVariant
} from '@/lib/common/entities/campaign/variant/util'
import { assertDefined } from '@/lib/common/guards/assertDefined'

export const getState = (state: IState) => state.editCampaign
export const getForm = (state: IState) => state.editCampaign.values
export const getId = (state: IState) => state.editCampaign.values.id
export const getName = (state: IState) => state.editCampaign.values.name
export const isEnabled = (state: IState) => state.editCampaign.values.isEnabled
export const getStep = (state: IState) => state.editCampaign.info.step
export const getEditMode = (state: IState) => state.editCampaign.info.editMode
export const getIngestedUrl = (state: IState) => state.editCampaign.values.ingestedUrl
export const getElements = (state: IState) => state.editCampaign.values.elements
export const getGoals = (state: IState) => state.editCampaign.values.goals
export const getTrafficAllocation = (state: IState) => state.editCampaign.values.trafficAllocation
export const getDraftGoal = (state: IState) => state.editCampaign.info.draftGoal
export const getErrors = (state: IState) => state.editCampaign.errors
export const getCampaignType = (state: IState) => state.editCampaign.values.type
export const getExclusiveAudienceMode = (state: IState) =>
  state.editCampaign.values.exclusiveAudienceMode
export const getVariantPool = (state: IState) => state.editCampaign.info.variantPool
export const getVariantPoolFactory =
  (elementId: number) =>
  (state: IState): IVariantPool | undefined =>
    state.editCampaign.info.variantPool[elementId]
export const getVariantPaginationMap = (state: IState) =>
  state.editCampaign.info.variantPaginationMap
export const getSearchImages = (state: IState) => state.editCampaign.info.searchImages
export const getGenerationStatus = (state: IState) => state.editCampaign.info.generating
export const getSwatches = (state: IState) => state.editCampaign.info.colorSwatches
export const getSelectedVariantMap = (state: IState) => state.editCampaign.info.selectedVariantMap
export const getElementCollapseMap = (state: IState) => state.editCampaign.info.elementCollapseMap
export const getSuggestionsCollapseMap = (state: IState) =>
  state.editCampaign.info.suggestionsCollapseMap
export const getVariantsCollapseMap = (state: IState) => state.editCampaign.info.variantsCollapseMap
export const getSessionVariantIds = (state: IState) =>
  state.editCampaign.info.addedVariantIdsInSession
export const getElementErrors = (state: IState) => state.editCampaign.info.elementErrors
export const getElement = (elementId: number) => (state: IState) => {
  const elements = getElements(state)
  const element = elements.find(e => e.id === elementId)
  assertDefined(element, 'element is undefined')
  return element
}

export const getGenerationStatusFactory = createSelector(
  (id: number) => id,
  (id: number) => createSelector(getGenerationStatus, map => map[id])
)

export const isReady = createSelector(getErrors, errors => Object.values(errors).length === 0)

export const getSuggestedVariantsFactory = (elementId: number) =>
  createSelector(getElements, elements => {
    const element = elements.find(e => e.id === elementId)
    if (!element) {
      return []
    }
    if (element.type === 'text') {
      return element.variants.filter(v => isSuggestedVariant(v)) as ISuggestedTextVariant[]
    }
    if (element.type === 'image') {
      return element.variants.filter(v => isSuggestedVariant(v)) as ISuggestedImageVariant[]
    }

    return []
  })

export const getClientVariantsFactory = (elementId: number) =>
  createSelector(getElements, elements => {
    const element = elements.find(e => e.id === elementId)
    if (!element) {
      return []
    }

    return element.variants.filter(v => isClientVariant(v)) as IClientCampaignVariant[]
  })

export const getClientVariantsExcludingSessionFactory = (elementId: number) =>
  createSelector(getElements, getSessionVariantIds, (elements, sessionVariantIds) => {
    const element = elements.find(e => e.id === elementId)
    if (!element) {
      return []
    }

    return element.variants.filter(v => {
      if ((sessionVariantIds[element.id] || []).includes(v.id)) {
        return
      }
      return isClientVariant(v)
    }) as IClientCampaignVariant[]
  })

export const getDraftVariantsFactory = (elementId: number) =>
  createSelector(getElements, (elements): ICampaignVariant[] => {
    const element = elements.find(e => e.id === elementId)
    if (!element) {
      return []
    }
    if (element.type === 'text') {
      return element.variants.filter(v => isNewVariant(v))
    }
    if (element.type === 'image') {
      return element.variants.filter(v => isNewVariant(v))
    }

    return []
  })

export const getAllVariantsFactory = (elementId: number) =>
  createSelector(getElements, elements => {
    const element = elements.find(e => e.id === elementId)
    if (!element) {
      return []
    }
    return element.variants
  })

export const getSessionVariants = createSelector(
  getElements,
  getSessionVariantIds,
  (elements, addedVariantIds) => {
    const result: {
      [elementId: string]: ICampaignVariant[]
    } = {}

    elements.forEach(element => {
      result[element.id] = []
      if (addedVariantIds[element.id] !== undefined) {
        element.variants.forEach(variant => {
          if (addedVariantIds[element.id].includes(variant.id)) {
            result[element.id].unshift(variant)
          }
        })
      }
    })
    return result
  }
)

export const getTotalSuggestedVariants = createSelector(
  getElements,
  getGenerationStatus,
  (elements, statusMap) => {
    for (const element of elements) {
      const status = statusMap[element.id]
      if (status?.type === NewVariantType.Suggested) {
        return undefined
      }
    }
    return elements
      .map(element => element.variants.filter(v => isSuggestedVariant(v)).length)
      .reduce((acc, curr) => acc + curr, 0)
  }
)

export const getElementObjective = (elementId: number) =>
  createSelector(getElements, elements => {
    const element = elements.find(e => e.id === elementId)
    if (!element) {
      return undefined
    }
    return element?.type === 'text' ? element.objective : undefined
  })
