import { addAITextVariant } from '@/lib/client/redux/campaign/draft-element/listener/addAITextVariant'
import { checkForErrors } from '@/lib/client/redux/campaign/draft-element/listener/checkForErrors'
import { focusElement } from '@/lib/client/redux/campaign/draft-element/listener/focusElement'
import { suggestSimilarVariant } from '@/lib/client/redux/campaign/draft-element/listener/suggestSimilarVariant'
import { updateAnimationStart } from '@/lib/client/redux/campaign/draft-element/listener/updateAnimationStart'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const DraftElementListeners = (middleware: IListenersMiddleware) => {
  updateAnimationStart(middleware)
  checkForErrors(middleware)
  focusElement(middleware)
  addAITextVariant(middleware)
  suggestSimilarVariant(middleware)
}
