import { createSelector } from '@reduxjs/toolkit'

import { getMutations } from '@/lib/client/embed/mutation/getMutations'
import {
  getDraftElement,
  getDraftVariant
} from '@/lib/client/redux/campaign/draft-element/selectors/common'
import {
  getDraftGoal,
  getEditMode,
  getElements,
  getIngestedUrl,
  getSelectedVariantMap,
  getStep
} from '@/lib/client/redux/campaign/edit/selectors/common'
import {
  CampaignEditMode,
  CampaignPreviewType,
  CampaignStep,
  ISelectedVariantMap
} from '@/lib/client/redux/campaign/edit/types'
import { PreviewSelector } from '@/lib/client/redux/preview/PreviewSelector'
import {
  IURLPreviewConfiguration,
  IURLPreviewSelection,
  IURLPreviewUpdates,
  PreviewInteractionMode,
  URLPreviewType
} from '@/lib/client/redux/preview/types'
import { IState } from '@/lib/client/redux/types'
import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'
import { TrackEventType } from '@/lib/common/entities/campaign/event/TrackEventType'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('campaign.edit.selectors.preview')

export const getPreviewType = createSelector(getStep, getEditMode, (step, mode) => {
  if (mode === CampaignEditMode.Update) {
    return CampaignPreviewType.CampaignPreview
  } else if (step === CampaignStep.ClosePopups) {
    return CampaignPreviewType.ClosePopups
  } else if (step === CampaignStep.EditGoals) {
    return CampaignPreviewType.EditGoals
  } else {
    return CampaignPreviewType.EditElements
  }
})

export const getPreviewUrl = (state: IState): string => {
  const type = getPreviewType(state)
  switch (type) {
    case CampaignPreviewType.ClosePopups:
      return getClosePopupUrl(state)
    case CampaignPreviewType.EditElements:
    case CampaignPreviewType.CampaignPreview:
      return getEditElementsUrl(state)
    case CampaignPreviewType.EditGoals:
      return getEditGoalsUrl(state)
  }
}

export const getPreviewConfiguration = (state: IState) => {
  const type = getPreviewType(state)
  switch (type) {
    case CampaignPreviewType.ClosePopups:
      return getClosePopupConfiguration(state)
    case CampaignPreviewType.EditElements:
      return getEditElementsConfiguration(state)
    case CampaignPreviewType.EditGoals:
      return getEditGoalsConfiguration(state)
    case CampaignPreviewType.CampaignPreview:
      return getCampaignPreviewConfiguration(state)
  }
}

export const getPreviewSelection = (state: IState): IURLPreviewSelection => {
  const type = getPreviewType(state)
  switch (type) {
    case CampaignPreviewType.ClosePopups:
      return getClosePopupSelection(state)
    case CampaignPreviewType.EditElements:
      return getEditElementsSelection(state)
    case CampaignPreviewType.EditGoals:
      return getEditGoalsSelection(state)
    case CampaignPreviewType.CampaignPreview:
      return getCampaignPreviewSelection(state)
  }
}

export const getPreviewUpdates = (state: IState): IURLPreviewUpdates => {
  const type = getPreviewType(state)
  switch (type) {
    case CampaignPreviewType.ClosePopups:
      return getClosePopupUpdates(state)
    case CampaignPreviewType.EditElements:
    case CampaignPreviewType.CampaignPreview:
      return getEditElementsUpdates(state)
    case CampaignPreviewType.EditGoals:
      return getEditGoalsUpdates(state)
  }
}

const getClosePopupUrl = (state: IState) => getIngestedUrl(state)

const getClosePopupConfiguration = createSelector(
  PreviewSelector.getInteractionMode,
  (interactionMode): IURLPreviewConfiguration => {
    const mode = interactionMode ?? PreviewInteractionMode.Default
    return {
      type: URLPreviewType.None,
      interactionMode: mode,
      showOutlines: mode === PreviewInteractionMode.Selecting,
      elements: []
    }
  }
)
const getClosePopupSelection = createSelector(
  (_: IState) => undefined,
  () => []
)

const getClosePopupUpdates = createSelector(
  (_: IState) => undefined,
  () => ({}) as IURLPreviewUpdates
)

const getEditElementsUrl = (state: IState) => getIngestedUrl(state)
const getEditElementsConfiguration = createSelector(
  PreviewSelector.getInteractionMode,
  (interactionMode): IURLPreviewConfiguration => {
    const mode = interactionMode ?? PreviewInteractionMode.Selecting
    return {
      type: URLPreviewType.Text | URLPreviewType.Image,
      elements: [],
      interactionMode: mode,
      showOutlines: mode === PreviewInteractionMode.Selecting
    }
  }
)

const getCampaignPreviewConfiguration = createSelector(
  PreviewSelector.getInteractionMode,
  getElements,
  (interactionMode, elements): IURLPreviewConfiguration => {
    const mode = interactionMode ?? PreviewInteractionMode.Selecting
    return {
      type: URLPreviewType.None,
      elements: elements.map(e => e.selector),
      interactionMode: mode,
      showOutlines: mode === PreviewInteractionMode.Selecting
    }
  }
)

const getCampaignPreviewSelection = createSelector(getElements, elements => {
  return elements.map(e => e.selector)
})

const getEditElementsSelection = createSelector(getElements, elements =>
  elements.map(e => e.selector)
)

const getElementsUpdates = createSelector(
  getElements,
  getSelectedVariantMap,
  (elements, selectedVariantMap) => {
    return elements.reduce((updates, e) => {
      const variant = getSelectedVariant(e, selectedVariantMap)
      if (variant) {
        updates[e.selector] = getMutations(e.type, variant.info)
      }
      return updates
    }, {} as IURLPreviewUpdates)
  }
)

const getDraftElementUpdates = createSelector(
  getDraftElement,
  getSelectedVariantMap,
  (element, selectedVariantMap) => {
    if (element) {
      const variant = getSelectedVariant(element, selectedVariantMap)
      if (variant) {
        return { [element.selector]: getMutations(element.type, variant.info) }
      }
    }
    return {}
  }
)

const getDraftVariantUpdates = createSelector(
  getDraftElement,
  getDraftVariant,
  (element, variant) => {
    if (element && variant) {
      return { [element.selector]: getMutations(element.type, variant.info) }
    }
    return {}
  }
)

const getEditElementsUpdates = createSelector(
  getElementsUpdates,
  getDraftElementUpdates,
  getDraftVariantUpdates,
  (elements, draftElement, draftVariant) => {
    return { ...elements, ...draftElement, ...draftVariant } as IURLPreviewUpdates
  }
)

const getEditGoalsUrl = (state: IState) => state.editCampaign.info.draftGoal?.event.url ?? ''
const getEditGoalsConfiguration = createSelector(
  PreviewSelector.getInteractionMode,
  (interactionMode): IURLPreviewConfiguration => {
    const mode = interactionMode ?? PreviewInteractionMode.Selecting
    return {
      type: URLPreviewType.Clickable,
      elements: [],
      interactionMode: mode,
      showOutlines: mode === PreviewInteractionMode.Selecting
    }
  }
)

const getEditGoalsSelection = createSelector(getDraftGoal, goal => {
  if (goal === undefined) {
    return []
  }
  const { event } = goal
  return event.type === TrackEventType.Click ? event.elements.map(e => e.selector) : []
})

const getEditGoalsUpdates = createSelector(
  (_state: IState) => undefined,
  () => ({}) as IURLPreviewUpdates
)

const getSelectedVariant = (element: ICampaignElement, selectedVariantMap: ISelectedVariantMap) => {
  const variantId = selectedVariantMap[element.id]
  return variantId !== undefined ? element.variants.find(v => v.id === variantId) : undefined
}
