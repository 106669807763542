import { formatIngestedUrl } from '@/components/admin/common/util/formatIngestedUrl'
import { showConfirmDialog } from '@/components/admin/dialog/ConfirmDialog'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

export const setStepCampaignTypeSelection = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/setStepCampaignTypeSelection',
  async (_, { getState, dispatch }) => {
    const { values, info } = getState().editCampaign

    if (info.step < CampaignStep.CampaignTypeSelection) {
      const goals = values.goals.map(goal => {
        const goalUrl = goal.event.url
        if (!goalUrl) return goal
        return {
          ...goal,
          event: {
            ...goal.event,
            url: formatIngestedUrl(goalUrl)
          }
        }
      })
      dispatch(EditCampaignAction.setGoals(goals))

      const shouldContinue = await showConfirmDialog({
        title: 'Goal confirmation',
        message: 'Once you submit you cannot change the order or add more goals to your funnel.',
        confirmText: 'Submit'
      })
      if (shouldContinue) {
        dispatch(EditCampaignAction.setStep(CampaignStep.CampaignTypeSelection))
      }
    } else {
      dispatch(EditCampaignAction.setStep(CampaignStep.CampaignTypeSelection))
    }
  }
)
