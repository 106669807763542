import { generateIntervals } from '@/components/admin/statistics/util'
import {
  ICreateCampaignMetricsTransformer,
  createCampaignMetricsTransformer
} from '@/lib/client/common/transform/campaign/createCampaignMetricsTransformer'
import { transformClientMetrics } from '@/lib/client/common/transform/campaign/transformClientMetrics'
import { fromDate } from '@/lib/common/dates/toDate'
import { ICampaignBenchmark } from '@/lib/common/entities/campaign/ICampaignBenchmark'
import { IMetrics } from '@/lib/common/entities/campaign/IMetrics'
import {
  ICampaignOverall,
  ICampaignStatistics,
  ICampaignTimeSeries,
  IServerCampaignStatistics,
  IServerTimeSeries,
  IStatisticsMetrics,
  IVariantOverall,
  IVariantTimeSeries
} from '@/lib/common/entities/campaign/statistics/ICampaignStatistics'
import { createMetrics } from '@/lib/common/stats/createMetrics'
import { addMetrics } from '@/lib/common/utils/addMetrics'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('transformCampaignStatistics')

type IElementAggregators = ReturnType<typeof createElementAggregators>

export const transformCampaignStatistics = (stats: IServerCampaignStatistics) => {
  const intervals = generateIntervals(stats.range, stats.granularity)
  const campaign = createCampaignAggregator(stats.timeSeries, stats.benchmark)
  const elements = createElementAggregators(stats)

  for (const interval of intervals) {
    const date = interval.start
    const serialized = fromDate(date) as unknown as number
    campaign.processMetrics(serialized)
    for (const element of elements) {
      for (const variant of element.variants) {
        const metrics = variant.aggregator.processMetrics(date, serialized)
        if (metrics) {
          campaign.processVariant(element.id, variant.control, metrics)
        }
      }
    }
    campaign.addPoint(date)
  }

  const result: ICampaignStatistics = {
    id: stats.id,
    range: stats.range,
    timezone: stats.timezone,
    granularity: stats.granularity,
    intervals,
    overall: campaign.getOverall(elements),
    timeSeries: campaign.getTimeSeries(),
    elements: {},
    goals: stats.goals
  }
  for (const element of elements) {
    result.elements[element.id] = {
      id: element.id,
      variants: {}
    }
    for (const variant of element.variants) {
      result.elements[element.id].variants[variant.id] = {
        id: variant.id,
        control: variant.control,
        overall: variant.aggregator.getOverall(),
        timeSeries: variant.aggregator.getTimeSeries()
      }
    }
  }
  return result
}

const createElementAggregators = (stats: IServerCampaignStatistics) => {
  return Object.values(stats.elements).map(element => ({
    id: element.id,
    variants: Object.values(element.variants).map(variant => ({
      id: variant.id,
      control: variant.control,
      aggregator: createVariantAggregator(variant.timeSeries, stats.benchmark)
    }))
  }))
}

const createCampaignAggregator = (
  serverTimeSeries: IServerTimeSeries,
  benchmark: ICampaignBenchmark
) => {
  let discreteTotal = createMetrics()
  const timeSeries: ICampaignTimeSeries = []
  let cumulative = serverTimeSeries.start
  let transformers:
    | {
        discrete: ICreateCampaignMetricsTransformer
        cumulative: ICreateCampaignMetricsTransformer
      }
    | undefined = undefined
  return {
    processMetrics(serialized: number) {
      const discrete = serverTimeSeries.values[serialized] as IMetrics | undefined
      discreteTotal = addMetrics(discreteTotal, discrete)
      cumulative = addMetrics(cumulative, discrete)
      transformers =
        cumulative !== undefined
          ? {
              discrete: createCampaignMetricsTransformer(discrete ?? createMetrics(), benchmark),
              cumulative: createCampaignMetricsTransformer(cumulative, benchmark)
            }
          : undefined
    },
    processVariant(elementId: number, control: boolean, metrics: IStatisticsMetrics) {
      transformers?.discrete.setMetrics(elementId, control, metrics.discrete)
      transformers?.cumulative.setMetrics(elementId, control, metrics.cumulative)
    },
    addPoint(date: Date) {
      timeSeries.push({
        date,
        metrics: transformers
          ? {
              discrete: transformers.discrete.getDerivedMetrics(),
              cumulative: transformers.cumulative.getDerivedMetrics()
            }
          : undefined
      })
    },
    getOverall(elements: IElementAggregators): ICampaignOverall {
      const transformer = createCampaignMetricsTransformer(discreteTotal, benchmark)
      for (const element of elements) {
        for (const variant of element.variants) {
          transformer.setMetrics(
            element.id,
            variant.control,
            variant.aggregator.getOverall().discrete
          )
        }
      }
      return {
        discrete: transformer.getDerivedMetrics(),
        cumulative: transformers
          ? transformers.cumulative.getDerivedMetrics()
          : transformer.getDerivedMetrics()
      }
    },
    getTimeSeries(): ICampaignTimeSeries {
      return timeSeries
    }
  }
}

const createVariantAggregator = (
  serverTimeSeries: IServerTimeSeries,
  benchmark: ICampaignBenchmark
) => {
  let discreteTotal = createMetrics()
  let cumulative = serverTimeSeries.start
  let discrete: IMetrics | undefined = undefined
  const timeSeries: IVariantTimeSeries = []
  return {
    processMetrics(date: Date, serialized: number) {
      discrete = serverTimeSeries.values[serialized] as IMetrics | undefined
      discreteTotal = addMetrics(discreteTotal, discrete)
      cumulative = addMetrics(cumulative, discrete)
      if (cumulative !== undefined && discrete === undefined) {
        discrete = createMetrics()
      }
      timeSeries.push({
        date,
        metrics:
          cumulative && discrete
            ? {
                discrete: transformClientMetrics(discrete, benchmark),
                cumulative: transformClientMetrics(cumulative, benchmark)
              }
            : undefined
      })
      return cumulative && discrete ? { discrete, cumulative } : undefined
    },
    getOverall(): IVariantOverall {
      return {
        discrete: transformClientMetrics(discreteTotal, benchmark),
        cumulative: transformClientMetrics(cumulative ?? createMetrics(), benchmark)
      }
    },
    getTimeSeries(): IVariantTimeSeries {
      return timeSeries
    }
  }
}
