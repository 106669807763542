import { IImageProgressStatus, IImageResultStatus } from '@/components/admin/common/image/types'
import { generateImage } from '@/lib/client/common/api/image/generateImage'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { ImageApi } from '@/lib/client/redux/common/image/ImageApi'
import { GenerateImageAction } from '@/lib/client/redux/image/generate/GenerateImageAction'
import { GenerateImageSelector } from '@/lib/client/redux/image/generate/GenerateImageSelector'
import { createImageStatus } from '@/lib/client/redux/image/util'
import { IThunkConfig } from '@/lib/client/redux/types'
import { IGenerateImageWithPromptPayload } from '@/lib/common/entities/api/image'
import { IImageElement } from '@/lib/common/entities/campaign/element/IImageElement'
import { assertDefined } from '@/lib/common/guards/assertDefined'

const duration = 20

export const generate = createAsyncThunk<void, IImageElement, IThunkConfig>(
  'generateImage/generate',
  async (element, { getState, dispatch }) => {
    const status = GenerateImageSelector.getStatus(getState())
    if (status?.type === 'progress') {
      return
    }

    dispatch(GenerateImageAction.start())
    // const service = GenerateImageSelector.getService(getState())
    const prompt = GenerateImageSelector.getPrompt(getState())
    const imageType = GenerateImageSelector.getType(getState())

    const controlUrl = element.control?.info.src
    assertDefined(controlUrl, 'Original does not have url')

    const promise = dispatch(ImageApi.endpoints.getImageInfo.initiate(controlUrl))
    const { dimensions } = await promise.unwrap()
    promise.unsubscribe()

    const payload: IGenerateImageWithPromptPayload = {
      prompt,
      dimensions,
      ids: [0, 1, 2, 3],
      service: 'midjourney',
      imageType
    }

    dispatch(
      GenerateImageAction.setStatus({
        type: 'progress',
        images: createImageStatus<IImageProgressStatus>(payload.ids, undefined, () => ({
          type: 'generate' as const,
          duration,
          dimensions
        }))
      })
    )

    await generateImage(payload, {
      generate: response => {
        dispatch(
          GenerateImageAction.setStatus({
            type: 'progress',
            images: createImageStatus<IImageProgressStatus>(payload.ids, response.images, () => ({
              type: 'generate',
              duration,
              dimensions
            }))
          })
        )
      },
      save: response => {
        dispatch(
          GenerateImageAction.setStatus({
            type: 'progress',
            images: createImageStatus<IImageProgressStatus>(payload.ids, response.images, () => ({
              type: 'save',
              duration,
              dimensions
            }))
          })
        )
      },
      result: (response, subscription) => {
        subscription.unsubscribe()
        dispatch(
          GenerateImageAction.setStatus({
            type: 'result',
            images: createImageStatus<IImageResultStatus>(payload.ids, response.images, () => ({
              type: 'result',
              duration,
              dimensions
            }))
          })
        )
      },
      error: (_data, subscription) => {
        subscription.unsubscribe()
        dispatch(GenerateImageAction.setStatus({ type: 'error' }))
      }
    })
  }
)
