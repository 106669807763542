import { AnimationAction } from '@/lib/client/redux/animation/AnimationAction'
import { AnimationId } from '@/lib/client/redux/animation/types'
import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const updateAnimationStart = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    actionCreator: DraftElementAction.init,
    effect: async (action, { dispatch }) => {
      if (action.payload) {
        dispatch(AnimationAction.setAnimationStart(AnimationId.SideMenuOverlay))
      }
    }
  })
}
