import { createSelector } from '@reduxjs/toolkit'

import { IElement, INewVariant, IVariantOfElement } from '@/lib/client/redux/campaign/edit/types'
import { IState } from '@/lib/client/redux/types'
import { config } from '@/lib/common/config'
import { ICampaignElementType } from '@/lib/common/entities/campaign/element/ICampaignElementType'
import { isNewVariant } from '@/lib/common/entities/campaign/variant/util'

type IGetDraftElement = <T extends ICampaignElementType = ICampaignElementType>(
  state: IState
) => IElement<typeof state.draftElement.values.element, T> | undefined

export const getDraftElement = ((state: IState) =>
  state.draftElement.values.element) as IGetDraftElement
export const getOriginalElement = ((state: IState) =>
  state.draftElement.values.originalElement) as IGetDraftElement
export const getIsEditingSingleVariant = (state: IState) =>
  state.draftElement.values.isSingleVariantMode
export const getDraftType = (state: IState) => state.draftElement.values.type
export const getDraftVariantId = (state: IState) => state.draftElement.values.focusedVariantId

type IGetDraftVariant = <T extends ICampaignElementType>(
  state: IState
) => IVariantOfElement<ReturnType<typeof getDraftElement>, T> | undefined
export const getDraftVariant = createSelector(
  getDraftElement,
  getDraftVariantId,
  (draftElement, draftVariantId) => {
    if (draftElement === undefined || draftVariantId === undefined) {
      return undefined
    }
    return draftElement.variants.find(v => v.id === draftVariantId)
  }
) as IGetDraftVariant

export const getOverlayType = createSelector(getDraftElement, draftElement => draftElement?.type)
export const getOverlayOpen = createSelector(
  getDraftElement,
  draftElement => draftElement !== undefined
)

export const getOriginalTextValue = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.control?.info.value : undefined
})

export const getOriginalTextColor = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.domStats?.textColor[0]?.value : undefined
})

export const getOriginalBackgroundColor = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.domStats?.backgroundColor[0]?.value : undefined
})

export const getOriginalFontSize = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.domStats?.fontSize[0]?.size : undefined
})

export const getOriginalBorderColor = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.domStats?.borderColor[0]?.value : undefined
})

export const getOriginalBorderWidth = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.domStats?.borderWidth[0]?.width : undefined
})

export const getOriginalPadding = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.domStats?.padding[0] : undefined
})

export const getOriginalBorderRadius = createSelector(getDraftElement, element => {
  return element?.type === 'text' ? element.domStats?.borderRadius[0] : undefined
})

export const hasDraftElementChanges = createSelector(
  getDraftElement,
  getOriginalElement,
  (draftElement, originalElement) => {
    if (draftElement === undefined || originalElement === undefined) {
      return false
    }

    return JSON.stringify(draftElement) !== JSON.stringify(originalElement)
  }
)

type IGetNewVariants = <T extends ICampaignElementType>(
  state: IState
) => INewVariant<IVariantOfElement<ReturnType<typeof getDraftElement>, T>>[]
export const getNewVariants = createSelector(getDraftElement, element => {
  if (!element) {
    return []
  }
  return element.variants.filter(v => isNewVariant(v))
}) as IGetNewVariants

export const getOverlayVariants = createSelector(
  getDraftElement,
  getDraftVariantId,
  getIsEditingSingleVariant,
  (element, draftVariantId, isEditingSingleVariant) => {
    if (!element) {
      return []
    }

    if (isEditingSingleVariant) {
      const variant = element.variants.find(v => v.id === draftVariantId)
      if (!variant || !isNewVariant(variant)) {
        return []
      }
      return [variant]
    }

    return element.variants.filter(v => isNewVariant(v))
  }
) as IGetNewVariants

export const canAddVariantsToDraftElement = createSelector(
  getNewVariants,
  variants => variants && variants.length < config.settings.MAX_DRAFT_VARIANTS
)

export const getNewVariantCount = createSelector(getNewVariants, variants =>
  variants ? variants.length : 0
)

export const getTextObjective = createSelector(getDraftElement, element =>
  element?.type === 'text' ? element.objective : undefined
)
