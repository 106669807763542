export interface IKPIMetric {
  title: string
}

export enum KPIMetric {
  RawConversionsPerView = 'rawConversionsPerView',
  ConversionsPerSession = 'conversionsPerSession',
  RevenuePerSession = 'revenuePerSession'
}

export enum KPIMetricType {
  Improvement = 'improvement',
  Actual = 'actual',
  Original = 'original'
}

export enum ActivityMetric {
  Views = 'views',
  Visitors = 'visitors',
  Sessions = 'sessions'
}

export enum MetricAggregation {
  Discrete,
  Cumulative
}
