import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ICampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'

export const setVariants = createAsyncThunk<
  void,
  { elementId: number; variants: ICampaignVariant[] },
  IThunkConfig
>('editCampaign/setVariants', async ({ elementId, variants }, { getState, dispatch }) => {
  const suggestions = EditCampaignSelector.getSuggestedVariantsFactory(elementId)(getState())

  dispatch(
    EditCampaignAction.resetVariants({
      elementId,
      variants: [...suggestions, ...variants]
    })
  )
})
