import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { CampaignApi } from '@/lib/client/redux/campaign/CampaignApi'
import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftElementSelector } from '@/lib/client/redux/campaign/draft-element/DraftElementSelector'
import {
  getDraftElement,
  getDraftType,
  getNewVariants
} from '@/lib/client/redux/campaign/draft-element/selectors/common'
import { DraftType } from '@/lib/client/redux/campaign/draft-element/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { getEditMode, getId } from '@/lib/client/redux/campaign/edit/selectors/common'
import { CampaignEditMode } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { assertDefined } from '@/lib/common/guards/assertDefined'
import { Body } from '@/pages/api/team/[teamSlug]/campaign/add-variants'

export const save = createAsyncThunk<void, void, IThunkConfig>(
  'draftElement/save',
  async (_, { getState, dispatch }) => {
    const mode = getEditMode(getState())
    const element = getDraftElement<'text'>(getState())
    assertDefined(element, 'draftElement is undefined')

    dispatch(DraftElementAction.incrementSubmitCount())
    await dispatch(DraftElementAction.checkForErrors(element))
    const hasErrors = DraftElementSelector.hasFormErrors(getState())
    if (hasErrors) {
      const errors = DraftElementSelector.getFormErrors(getState())
      const error = Object.values(errors)[0]
      if (error?.variantId) {
        dispatch(DraftElementAction.focusDraftVariant(error?.variantId))
      }
      return
    }

    if (mode === CampaignEditMode.Create) {
      dispatch(EditCampaignAction.replaceElement(element))
      dispatch(DraftElementAction.setDraft(undefined))
    } else {
      const newVariants = getNewVariants(getState()) ?? []
      dispatch(EditCampaignAction.setIsSubmitting(true))
      const payload: Body = {
        campaignId: getId(getState()),
        elementId: element.id,
        variants: newVariants
      }
      const { addVariants } = CampaignApi.endpoints
      const team = getTeamSlug()
      const { variants, newVariantIds } = await dispatch(
        addVariants.initiate({ team, payload })
      ).unwrap()
      dispatch(EditCampaignAction.setVariants({ elementId: element.id, variants }))

      if (getDraftType(getState()) === DraftType.EditSuggestion) {
        // Now that suggested variant is a campaign variant we can remove it from suggested variants
        const suggestedVariantId = newVariants[0]?.id
        dispatch(
          EditCampaignAction.removeVariants({
            elementId: element.id,
            variantIds: [suggestedVariantId]
          })
        )
      }

      dispatch(EditCampaignAction.setIsSubmitting(false))
      dispatch(DraftElementAction.setDraft(undefined))
      dispatch(
        EditCampaignAction.updateVariantsAddedInSession({
          elementId: element.id,
          variantIds: newVariantIds
        })
      )
    }
  }
)
