import { IGenerateTextData } from '@/lib/client/common/api/text/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { GenerationError } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import {
  INewTextVariant,
  TextVariantType
} from '@/lib/common/entities/campaign/variant/ITextVariant'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('consumeVariantsFromPool')

export const consumeVariantsFromPool = createAsyncThunk<
  INewTextVariant[] | undefined,
  IGenerateTextData,
  IThunkConfig
>('editCampaign/consumeVariantsFromPool', async (data, { dispatch, getState }) => {
  const { element, options, count, type } = data
  if (element === undefined) {
    return
  }
  const map = EditCampaignSelector.getVariantPool(getState())
  const { tone } = options

  const pool = map[element.id]
  if (pool === undefined) {
    return undefined
  }
  const available = [...pool.available]
  const variants = available.splice(0, count).map(
    (variant): INewTextVariant => ({
      __type__: type ?? NewVariantType.Draft,
      id: generateTemporaryId(),
      type: 'text',
      key: '',
      info: {
        value: variant.value,
        tone,
        creationMethod: 'generated',
        textType: TextVariantType.Normal,
        styles: variant.styles
      }
    })
  )
  dispatch(
    EditCampaignAction.updateAvailableVariants({ elementId: element.id, variants: available })
  )
  if (variants.length < count) {
    dispatch(
      EditCampaignAction.setElementErrors({
        elementId: element.id,
        error: GenerationError.CouldNotGenerate
      })
    )
  } else {
    dispatch(
      EditCampaignAction.setElementErrors({
        elementId: element.id,
        error: undefined
      })
    )
  }

  return variants
})
