import { showConfirmDialog } from '@/components/admin/dialog/ConfirmDialog'
import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { CampaignApi } from '@/lib/client/redux/campaign/CampaignApi'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { IClientTextVariant } from '@/lib/common/entities/campaign/variant/ITextVariant'
import { assertDefined } from '@/lib/common/guards/assertDefined'

export const deleteServerVariant = createAsyncThunk<
  void,
  { variantId: number; elementId: number },
  IThunkConfig
>('editCampaign/deleteServerVariant', async ({ variantId, elementId }, { dispatch, getState }) => {
  const shouldDelete = await showConfirmDialog({
    title: 'Delete variant',
    message: 'Are you sure you want to delete this variant? Once deleted it cannot be recovered.',
    isConfirmButtonRed: true,
    confirmText: 'Yes, Delete'
  })

  if (!shouldDelete) {
    return
  }

  dispatch(EditCampaignAction.setIsActionLoading(true))

  await dispatch(
    CampaignApi.endpoints.deleteVariant.initiate({
      team: getTeamSlug(),
      payload: { id: variantId }
    })
  )

  const variants = EditCampaignSelector.getAllVariantsFactory(elementId)(getState())

  assertDefined(variants, 'variants is undefined')

  dispatch(
    EditCampaignAction.resetVariants({
      elementId: elementId,
      variants: (variants as IClientTextVariant[]).filter(variant => variant.id !== variantId)
    })
  )

  dispatch(EditCampaignAction.setIsActionLoading(false))
})
