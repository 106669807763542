import { produce } from 'immer'
import md5 from 'md5'
import { nanoid } from 'nanoid'

import { IGenerateTextData } from '@/lib/client/common/api/text/types'
import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { CampaignApi } from '@/lib/client/redux/campaign/CampaignApi'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { PreviewAction } from '@/lib/client/redux/preview/PreviewAction'
import { IURLPreviewContextEvent } from '@/lib/client/redux/preview/types'
import { IListenerAPI } from '@/lib/client/redux/types'
import { takeThunk } from '@/lib/client/redux/util'
import { getS3Key } from '@/lib/common/utils/getS3Key'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('updateElementMeta')

export const updateElementMeta = async (data: IGenerateTextData, listenerApi: IListenerAPI) => {
  const { dispatch, take } = listenerApi
  const { element, url } = data

  if (element.meta !== undefined) {
    return
  }

  const requestId = nanoid()
  dispatch(PreviewAction.sendAction({ type: 'context', selector: element.selector, requestId }))

  const action = await takeThunk(
    take,
    PreviewAction.sendEvent,
    payload => payload.type === 'context' && payload.requestId === requestId,
    20000
  )
  const ev = action.meta.arg as IURLPreviewContextEvent

  const cacheKey = getS3Key('describe', 'element', url, md5(element.selector))
  const response = await dispatch(
    CampaignApi.endpoints.updateElementMeta.initiate({
      team: getTeamSlug(),
      payload: { id: element.id, context: ev.context, cacheKey }
    })
  )
  if ('data' in response) {
    const meta = response.data
    dispatch(EditCampaignAction.updateElementById({ id: element.id, update: { meta } }))
    data.element = produce(data.element, draft => {
      draft.meta = meta
    })
  } else {
    throw new Error('Could not update element meta', { cause: response.error })
  }
}
