import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import {
  INewTextVariant,
  TextVariantType
} from '@/lib/common/entities/campaign/variant/ITextVariant'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'

export const addManualTextVariant = createAsyncThunk<void, void, IThunkConfig>(
  'draftElement/addManualTextVariant',
  async (_, { dispatch }) => {
    const variant: INewTextVariant = {
      __type__: NewVariantType.Draft,
      id: generateTemporaryId(),
      key: '',
      type: 'text',
      info: {
        value: undefined,
        creationMethod: 'custom',
        styles: [],
        textType: TextVariantType.Normal
      }
    }
    dispatch(DraftElementAction.addVariants({ variants: [variant], position: 'start' }))
    dispatch(DraftElementAction.focusDraftVariant(variant.id))
  }
)
