import { ScriptableContext } from 'chart.js'
import {
  toDate as _toDate,
  addDays,
  addHours,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear
} from 'date-fns'

import { ICampaignStatsContext } from '@/components/admin/campaign/stats/CampaignStatsContext'
import { IColor } from '@/components/admin/statistics/types'
import { IDateRange } from '@/lib/common/dates/IDateRange'
import { ISerializableDateRange } from '@/lib/common/dates/ISerializableDateRange'
import { toDate } from '@/lib/common/dates/toDate'
import { ICampaignMetrics, IClientMetrics } from '@/lib/common/entities/campaign/IMetrics'
import { MetricAggregation } from '@/lib/common/entities/campaign/KPIMetric'
import { Granularity } from '@/lib/common/entities/campaign/statistics/Granularity'
import { IStatisticsMetrics } from '@/lib/common/entities/campaign/statistics/ICampaignStatistics'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('statistics.util')

// export const format = (value: number | undefined) => {
//   if (value === undefined) {
//     return 0
//   }
//   if (isNaN(value)) {
//     return 0
//   }
//   if (!isFinite(value)) {
//     return ''
//   }
//   return value
// }

export const generateIntervals = (range: ISerializableDateRange, granularity: Granularity) => {
  const dates: IDateRange[] = []
  const end = toDate(range.end)

  let current = toDate(range.start)
  switch (granularity) {
    case Granularity.Hour:
      current = startOfHour(current)
      while (current <= end) {
        dates.push({ start: current, end: endOfHour(current) })
        current = addHours(current, 1)
      }
      return dates
    case Granularity.QuarterDay:
      current = startOfQuarterDay(current)
      while (current <= end) {
        dates.push({ start: current, end: endOfQuarterDay(current) })
        current = addHours(current, 6)
      }
      return dates
    case Granularity.Day:
      current = startOfDay(current)
      while (current <= end) {
        dates.push({ start: current, end: endOfDay(current) })
        current = addDays(current, 1)
      }
      return dates
    case Granularity.Week:
      current = startOfWeek(current, { weekStartsOn: 1 })
      while (current <= end) {
        dates.push({ start: current, end: endOfWeek(current, { weekStartsOn: 1 }) })
        current = addWeeks(current, 1)
      }
      return dates
    case Granularity.Month:
      current = startOfMonth(current)
      while (current <= end) {
        dates.push({ start: current, end: endOfMonth(current) })
        current = addMonths(current, 1)
      }
      return dates
    case Granularity.Quarter:
      current = startOfQuarter(current)
      while (current <= end) {
        dates.push({ start: current, end: endOfQuarter(current) })
        current = addQuarters(current, 1)
      }
      return dates
    case Granularity.Year:
      current = startOfYear(current)
      while (current <= end) {
        dates.push({ start: current, end: endOfYear(current) })
        current = addYears(current, 1)
      }
      return dates
  }
}

export const getLabels = (context: ICampaignStatsContext): string[] => {
  const { stats } = context.interval

  if (stats === undefined) {
    return []
  }

  return stats.intervals.map(interval => {
    let value = formatDate(interval.start, stats.granularity)
    if (context.comparison.stats) {
      value += ' vs ' + formatDate(interval.start, context.comparison.stats.granularity)
    }
    return value
  })
}

const formatDate = (date: Date, granularity: Granularity) => {
  const formats: { [index: string]: string } = {
    [Granularity.Hour]: 'h:mm a'
  }
  return format(date, formats[granularity] || 'M/d')
}

export const createGradientColor = (context: ScriptableContext<'line'>, color: string) => {
  const { ctx, chartArea } = context.chart
  if (!chartArea) {
    return color
  }

  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)

  const start = hexToRGB(color, 0.05)
  const end = hexToRGB(color, 0.5)

  gradient.addColorStop(0, start)
  gradient.addColorStop(1, end)

  return gradient
}

const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const startOfQuarterDay = (date: Date | number): Date => {
  const _date = _toDate(date)
  const currentHours = _date.getHours()
  const hour = currentHours - (currentHours % 6)
  _date.setHours(hour, 0, 0, 0)
  return _date
}

export const endOfQuarterDay = (date: Date | number): Date => {
  const _date = _toDate(date)
  const currentHours = _date.getHours()
  const hour = currentHours - (currentHours % 6) + 5
  _date.setHours(hour, 59, 59, 999)
  return _date
}

export const defaultColor = 'rgba(1,97,157,1)'

export const getOptionColor = (color: IColor | undefined) => {
  if (color === undefined) {
    return defaultColor
  } else if (typeof color === 'string') {
    return color
  } else {
    return color.value
  }
}

export const getMetrics = <T extends ICampaignMetrics | IClientMetrics>(
  stats: IStatisticsMetrics<T> | undefined,
  aggregation: MetricAggregation
) => {
  switch (aggregation) {
    case MetricAggregation.Cumulative:
      return stats?.cumulative
    case MetricAggregation.Discrete:
      return stats?.discrete
  }
}
