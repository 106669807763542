import React, { MouseEventHandler } from 'react'

import { Button } from '@/components/shared/button/Button'
import { useDialogActions } from '@/components/shared/dialog/useDialogActions'
import { showDialog } from '@/lib/client/common/util/dialog'

interface Props {
  title: string
  message: string
  confirmText: string
  onConfirm: () => void
  isConfirmButtonRed: boolean
}

export const ConfirmDialog: React.FC<Props> = ({
  title,
  message,
  confirmText,
  onConfirm,
  isConfirmButtonRed
}) => {
  const { hideDialog } = useDialogActions()

  const handleConfirm: MouseEventHandler = e => {
    e.stopPropagation()
    onConfirm()
    hideDialog()
  }

  const handleCancel: MouseEventHandler = e => {
    e.stopPropagation()
    hideDialog()
  }

  return (
    <div className="flex w-[520px] flex-col items-start justify-center rounded-xl bg-white px-10 py-8 shadow-2xl">
      <div className="flex justify-center mb-2">
        <h3 className="text-lg font-bold">{title}</h3>
      </div>
      <p className="mb-6 text-sm text-textPrimary">{message}</p>
      <div className="flex flex-row self-end space-x-4">
        <Button variant="outline" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color={isConfirmButtonRed ? 'danger' : 'primary'} onClick={handleConfirm}>
          {confirmText}
        </Button>
      </div>
    </div>
  )
}

interface showConfirmDialogProps {
  title: string
  message: string
  confirmText?: string
  onConfirm?: () => void
  isConfirmButtonRed?: boolean
}

export const showConfirmDialog = ({
  title,
  message,
  confirmText = 'Confirm',
  onConfirm,
  isConfirmButtonRed = false
}: showConfirmDialogProps): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    const handleConfirm = () => {
      onConfirm?.()
      resolve(true)
    }
    const element = (
      <ConfirmDialog
        title={title}
        message={message}
        confirmText={confirmText}
        onConfirm={handleConfirm}
        isConfirmButtonRed={isConfirmButtonRed}
      />
    )
    showDialog(element, undefined, (result?: any) => resolve(result === true))
  })
}
