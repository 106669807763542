import { IEditCampaignState } from '@/lib/client/redux/campaign/edit/types'

export const setElementErrors = async (
  state: IEditCampaignState,
  elementId: number,
  error: string | undefined
) => {
  if (error) {
    state.info.elementErrors[elementId] = error
  } else {
    delete state.info.elementErrors[elementId]
  }
}
