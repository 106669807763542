import { combineReducers } from '@reduxjs/toolkit'

import { AnimationSlice } from '@/lib/client/redux/animation/AnimationSlice'
import { DraftElementSlice } from '@/lib/client/redux/campaign/draft-element/DraftElementSlice'
import { EditCampaignSlice } from '@/lib/client/redux/campaign/edit/EditCampaignSlice'
import { ImageApi } from '@/lib/client/redux/common/image/ImageApi'
import { StorageApi } from '@/lib/client/redux/common/storage/StorageApi'
import { DialogSlice } from '@/lib/client/redux/dialog/DialogSlice'
import { GenerateImageSlice } from '@/lib/client/redux/image/generate/GenerateImageSlice'
import { ModifyImageSlice } from '@/lib/client/redux/image/modify/ModifyImageSlice'
import { IntroSurveySlice } from '@/lib/client/redux/intro-survey/IntroSurveySlice'
import { MenuSlice } from '@/lib/client/redux/menu/MenuSlice'
import { MessageSlice } from '@/lib/client/redux/message/MessageSlice'
import { PreviewSlice } from '@/lib/client/redux/preview/PreviewSlice'

import { baseApi } from './api'

export const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [StorageApi.reducerPath]: StorageApi.reducer,
  [ImageApi.reducerPath]: ImageApi.reducer,
  dialog: DialogSlice.reducer,
  animation: AnimationSlice.reducer,
  editCampaign: EditCampaignSlice.reducer,
  generateImage: GenerateImageSlice.reducer,
  modifyImage: ModifyImageSlice.reducer,
  menu: MenuSlice.reducer,
  message: MessageSlice.reducer,
  preview: PreviewSlice.reducer,
  introSurvey: IntroSurveySlice.reducer,
  draftElement: DraftElementSlice.reducer
})
