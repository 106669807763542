import { IElementMutation } from '@/lib/client/embed/shared/types'
import { IDOMStats } from '@/lib/common/entities/campaign/element/ITextElement'

export interface IPreviewState {
  url: string
  interactionMode: PreviewInteractionMode | undefined
  iframeStatus: URLPreviewIFrameStatus
  loadStatus: URLPreviewLoadStatus
  size: PreviewSize
  errorCount: number
}

export enum PreviewSize {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

export enum PreviewInteractionMode {
  Default = 'default',
  Selecting = 'selecting'
}

export enum URLPreviewType {
  None = 0,
  Text = 1,
  Image = 2,
  Clickable = 4
}

export interface IURLPreviewConfiguration {
  type: URLPreviewType
  elements: string[]
  showOutlines: boolean
  interactionMode: PreviewInteractionMode
}

export type IURLPreviewSelection = string[]

export interface IURLPreviewUpdates {
  [key: string]: IElementMutation[]
}

export enum URLPreviewLoadStatus {
  Initial,
  Loading,
  Success,
  Error
}

export enum URLPreviewIFrameStatus {
  Initial = 0,
  Ready = 1
}

export type IURLPreviewAction =
  | IURLPreviewUpdateAction
  | IURLPreviewFocusAction
  | IURLPreviewStatsAction
  | IURLPreviewContextAction

export interface IURLPreviewFocusAction {
  type: 'focus'
  selector: string
}

export interface IURLPreviewUpdateAction {
  type: 'update'
  configuration?: IURLPreviewConfiguration
  selection?: IURLPreviewSelection
  updates?: IURLPreviewUpdates
}

export interface IURLPreviewStatsAction {
  type: 'stats'
  selector: string
}

export interface IURLPreviewContextAction {
  type: 'context'
  selector: string
  requestId: string
}

export type IURLPreviewEvent =
  | IURLPreviewReadyEvent
  | IURLPreviewLoadEvent
  | IURLPreviewRetryEvent
  | IURLPreviewNewUrlEvent
  | IURLPreviewErrorEvent
  | IURLPreviewMetaEvent
  | IURLPreviewTextSelectEvent
  | IURLPreviewImageSelectEvent
  | IURLPreviewClickableSelectEvent
  | IURLPreviewStatsEvent
  | IURLPreviewContextEvent

export interface IURLPreviewReadyEvent {
  type: 'ready'
}

export interface IURLPreviewLoadEvent {
  type: 'load'
}

export interface IURLPreviewRetryEvent {
  type: 'retry'
}

export interface IURLPreviewNewUrlEvent {
  type: 'newUrl'
}

export interface IURLPreviewErrorEvent {
  type: 'error'
  message: string
}

export interface IURLPreviewMetaData {
  title: string
  description: string
  keywords: string
  stats: IDOMStats
}

export interface IURLPreviewMetaEvent {
  type: 'meta'
  data: IURLPreviewMetaData
}

export type ITextElementType = 'button' | 'link' | 'generic' | 'heading'

export interface IURLPreviewTextSelectEvent {
  type: 'text:select'
  selector: string
  value: string
  html: string
  textType: ITextElementType
  stats: IDOMStats
}

export interface IURLPreviewImageSelectEvent {
  type: 'image:select'
  selector: string
  src: string | undefined
  width: number
  height: number
}

export interface IURLPreviewClickableSelectEvent {
  type: 'clickable:select'
  selector: string
  label: string
}

export interface IURLPreviewStatsEvent {
  type: 'stats'
  selector: string
  stats: IDOMStats
}

export interface IURLPreviewContextEvent {
  type: 'context'
  requestId: string
  context: string
}
