import { IEmbedMutation } from '@/lib/client/embed/mutation/types'
import { IBaseMutation } from '@/lib/client/embed/shared/types'
import { ISampleLayoutInfo } from '@/lib/server/data/campaign/embed/types'

export function getLayoutMutations(info: ISampleLayoutInfo) {
  const mutations: (IEmbedMutation | IBaseMutation)[] = []
  switch (info.action) {
    case 'replace':
      mutations.push({ type: 'replaceContent', html: info.html })
      break
    case 'hide':
      mutations.push({ type: 'hideElement', selectors: info.selectors })
      break
  }
  return mutations
}
