import { generateImage } from '@/lib/client/common/api/image/generateImage'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { createImageVariantInfoUpdates } from '@/lib/client/redux/campaign/util/createImageVariantInfoUpdates'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { ImageApi } from '@/lib/client/redux/common/image/ImageApi'
import { IThunkConfig } from '@/lib/client/redux/types'
import { IGenerateImagePayload } from '@/lib/common/entities/api/image'
import { IImageElement } from '@/lib/common/entities/campaign/element/IImageElement'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { ISuggestedImageVariant } from '@/lib/common/entities/campaign/variant/IImageVariant'
import { assertDefined } from '@/lib/common/guards/assertDefined'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('generateSuggestedImageVariants')
const duration = 30

export const generateSuggestedImageVariants = createAsyncThunk<void, IImageElement, IThunkConfig>(
  'editCampaign/generateSuggestedImageVariants',
  async (element, { dispatch }) => {
    const url = element.control?.info.src
    assertDefined(url, 'Original does not have a valid url')

    const promise = dispatch(ImageApi.endpoints.getImageInfo.initiate(url))
    const { dimensions } = await promise.unwrap()
    promise.unsubscribe()

    const variants = Array(4)
      .fill(undefined)
      .map(() => {
        const variant: ISuggestedImageVariant = {
          __type__: NewVariantType.Suggested,
          id: generateTemporaryId(),
          type: 'image',
          key: '',
          info: {
            src: '',
            thumb: '',
            status: {
              type: 'start',
              duration,
              dimensions
            }
          }
        }
        return variant
      })

    dispatch(EditCampaignAction.addVariantsToElement({ element, variants, position: 'end' }))
    const elementId = element.id

    const payload: IGenerateImagePayload = {
      url,
      dimensions,
      ids: variants.map(variant => variant.id),
      service: 'midjourney'
    }
    await generateImage(payload, {
      analyze: response => {
        const updates = createImageVariantInfoUpdates(variants, response.images, image => ({
          status: { type: 'analyze', duration, dimensions, image }
        }))
        dispatch(EditCampaignAction.mergeImageVariantInfo({ elementId, updates }))
      },
      generate: response => {
        const updates = createImageVariantInfoUpdates(variants, response.images, image => ({
          status: { type: 'generate', duration, dimensions, image }
        }))
        dispatch(EditCampaignAction.mergeImageVariantInfo({ elementId, updates }))
        if (!element.prompt && response.prompt) {
          dispatch(
            EditCampaignAction.updateElementById({
              id: elementId,
              update: { prompt: response.prompt }
            })
          )
        }
      },
      save: response => {
        const updates = createImageVariantInfoUpdates(variants, response.images, image => ({
          status: { type: 'save', duration, dimensions, image }
        }))
        dispatch(EditCampaignAction.mergeImageVariantInfo({ elementId, updates }))
      },
      result: (response, subscription) => {
        subscription.unsubscribe()
        const updates = createImageVariantInfoUpdates(variants, response.images, image => ({
          src: image?.url ?? '',
          thumb: image?.url ?? '',
          status: undefined
        }))
        dispatch(EditCampaignAction.mergeImageVariantInfo({ elementId, updates }))
      },
      error: (_data, subscription) => {
        subscription.unsubscribe()
        dispatch(
          EditCampaignAction.removeVariants({ elementId, variantIds: variants.map(v => v.id) })
        )
      }
    })
  }
)
