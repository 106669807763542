import { IStorageKey } from '@/lib/client/common/entities/IStorageKey'
import { StorageApi } from '@/lib/client/redux/common/storage/StorageApi'
import { IState } from '@/lib/client/redux/types'

export const StorageSelector = {
  get: (state: IState, key: IStorageKey) => {
    const result = StorageApi.endpoints.getStorage.select(key)(state)
    return result.data ?? undefined
  }
}
