import { IDraftElementState, IDraftVariant } from '@/lib/client/redux/campaign/draft-element/types'
import { ICampaignElementType } from '@/lib/common/entities/campaign/element/ICampaignElementType'

export const getDraftVariant = <T extends ICampaignElementType>(state: IDraftElementState) => {
  if (!state.values.element) {
    return undefined
  }
  const { focusedVariantId, element } = state.values

  if (focusedVariantId === undefined) {
    return undefined
  }
  return element.variants.find(v => v.id === focusedVariantId) as IDraftVariant<T> | undefined
}
