import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { CampaignApi } from '@/lib/client/redux/campaign/CampaignApi'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { IClientTextVariant } from '@/lib/common/entities/campaign/variant/ITextVariant'
import { assertDefined } from '@/lib/common/guards/assertDefined'

export const updateVariantEnabled = createAsyncThunk<
  void,
  { id: number; isEnabled: boolean; elementId: number },
  IThunkConfig
>(
  'editCampaign/updateVariantEnabled',
  async ({ id, isEnabled, elementId }, { dispatch, getState }) => {
    dispatch(EditCampaignAction.setIsActionLoading(true))

    try {
      await dispatch(
        CampaignApi.endpoints.updateVariantEnabled.initiate({
          team: getTeamSlug(),
          payload: { id, isEnabled }
        })
      )

      const variants = EditCampaignSelector.getAllVariantsFactory(elementId)(getState())

      assertDefined(variants, 'variants is undefined')

      dispatch(
        EditCampaignAction.resetVariants({
          elementId: elementId,
          variants: (variants as IClientTextVariant[]).map(v =>
            v.id === id ? { ...v, isEnabled: isEnabled } : v
          )
        })
      )
    } finally {
      dispatch(EditCampaignAction.setIsActionLoading(false))
    }
  }
)
