import { IMetrics } from '@/lib/common/entities/campaign/IMetrics'

type IAddMetrics = {
  (a: IMetrics, b: IMetrics): IMetrics
  (a: IMetrics | undefined, b: IMetrics): IMetrics
  (a: IMetrics, b: IMetrics | undefined): IMetrics
  (a: IMetrics | undefined, b: IMetrics | undefined): IMetrics | undefined
}

export const addMetrics: IAddMetrics = (a: any, b: any) => {
  if (a !== undefined && b !== undefined) {
    return {
      views: a.views + b.views,
      visitors: a.visitors + b.visitors,
      sessions: a.sessions + b.sessions,
      conversions: a.conversions + b.conversions,
      funnelSessions: a.funnelSessions + b.funnelSessions,
      funnelConversions: a.funnelConversions + b.funnelConversions,
      revenue: a.revenue + b.revenue
    }
  } else if (a !== undefined) {
    return { ...a }
  } else if (b !== undefined) {
    return { ...b }
  } else {
    return undefined
  }
}
