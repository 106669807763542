import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { INavigatePayload } from '@/lib/client/redux/location/types'

export const convert = (payload: INavigatePayload) => {
  const shallow = payload?.shallow ?? true
  const hideQuery = payload?.hideQuery ?? true
  const inTeam = payload.inTeam ?? false

  if (payload.path === undefined) {
    return undefined
  }
  let pathname = payload.path
  const query = payload.query

  if (inTeam) {
    const team = getTeamSlug()
    pathname = `/admin/${team}${pathname}`
  }
  return {
    url: { pathname, query },
    as: hideQuery ? pathname : { pathname, query },
    options: shallow ? { shallow: true } : undefined
  }
}
