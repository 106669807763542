import { ICampaignBenchmark } from '@/lib/common/entities/campaign/ICampaignBenchmark'
import {
  ICampaignDerivedMetric,
  ICampaignMetrics,
  IClientDerivedMetric,
  IClientMetrics,
  IMetrics
} from '@/lib/common/entities/campaign/IMetrics'
import { IServerVariantMetrics } from '@/lib/common/entities/campaign/variant/IBaseVariant'

export const createMetrics = (): IMetrics => ({
  views: 0,
  visitors: 0,
  sessions: 0,
  conversions: 0,
  funnelSessions: 0,
  funnelConversions: 0,
  revenue: 0
})

export const createServerVariantMetrics = (): IServerVariantMetrics => ({
  ...createMetrics(),
  revenueSamples: 0,
  revenueBeta: 10
})

export const createDerivedMetrics = (): IClientDerivedMetric => ({
  value: 0,
  improvement: 0,
  benchmark: 0
})

export const createClientMetrics = (): IClientMetrics => ({
  ...createMetrics(),
  rawConversionsPerView: createDerivedMetrics(),
  conversionsPerSession: createDerivedMetrics(),
  revenuePerSession: createDerivedMetrics()
})

export const createCampaignDerivedMetrics = (): ICampaignDerivedMetric => ({
  value: 0,
  improvement: 0,
  potential: 0,
  potentialImprovement: 0,
  original: 0,
  benchmark: 0
})

export const createCampaignMetrics = (): ICampaignMetrics => ({
  ...createMetrics(),
  rawConversionsPerView: createCampaignDerivedMetrics(),
  conversionsPerSession: createCampaignDerivedMetrics(),
  revenuePerSession: createCampaignDerivedMetrics()
})

export const createCampaignBenchmark = (): ICampaignBenchmark => ({
  rawConversionsPerView: 0,
  conversionsPerSession: 0,
  revenuePerSession: 0
})
