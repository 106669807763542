import { clearErrors } from '@/lib/client/redux/campaign/edit/listener/clearErrors'
import { generateInitialTextVariants } from '@/lib/client/redux/campaign/edit/listener/generateInitialTextVariants'
import { generateSuggestedVariants } from '@/lib/client/redux/campaign/edit/listener/generateSuggestedVariants'
import { suggestSimilarVariant } from '@/lib/client/redux/campaign/edit/listener/suggestSimilarVariant'
import { updateSelectedVariant } from '@/lib/client/redux/campaign/edit/listener/updateSelectedVariant'
import { validateForm } from '@/lib/client/redux/campaign/edit/listener/validateForm'
import { validateFormValue } from '@/lib/client/redux/campaign/edit/listener/validateFormValue'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const EditCampaignListeners = (middleware: IListenersMiddleware) => {
  validateFormValue(middleware)
  validateForm(middleware)

  generateInitialTextVariants(middleware)
  generateSuggestedVariants(middleware)
  suggestSimilarVariant(middleware)
  updateSelectedVariant(middleware)
  clearErrors(middleware)
}
