import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'

export const updateElementBySelector = (
  el: ICampaignElement | undefined,
  selector: string,
  data: Partial<ICampaignElement>
) => {
  if (el && el.selector === selector) {
    for (const [key, value] of Object.entries(data)) {
      const element = el as any
      element[key] = value
    }
  }
}
