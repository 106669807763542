import { formatIngestedUrl } from '@/components/admin/common/util/formatIngestedUrl'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { getIngestedUrl, getStep } from '@/lib/client/redux/campaign/edit/selectors/common'
import { CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { LocationAction } from '@/lib/client/redux/location/LocationAction'
import { PreviewAction } from '@/lib/client/redux/preview/PreviewAction'
import { IThunkConfig } from '@/lib/client/redux/types'

export const setStepClosePopups = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/setStepClosePopups',
  async (_, { getState, dispatch }) => {
    const step = getStep(getState())

    dispatch(PreviewAction.setInteractionMode(undefined))

    if (step >= CampaignStep.ClosePopups) {
      dispatch(EditCampaignAction.setStep(CampaignStep.ClosePopups))
      return
    }

    const ingestedUrl = getIngestedUrl(getState())
    const formattedUrl = formatIngestedUrl(ingestedUrl)
    dispatch(EditCampaignAction.setIngestedUrl(formattedUrl))
    dispatch(EditCampaignAction.setStep(CampaignStep.ClosePopups))
    await dispatch(
      LocationAction.modifyQuery((url, as) => {
        url.set('url', formattedUrl)
        as.set('url', formattedUrl)
      })
    )
    await dispatch(EditCampaignAction.createCampaignScreenshot())
  }
)
