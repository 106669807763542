import { IGenerateTextData, IGenerateTextOptions } from '@/lib/client/common/api/text/types'
import { IArrayElement } from '@/lib/client/common/util/IArrayElement'
import { IFormState } from '@/lib/client/redux/form/types'
import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'
import { ICampaignElementType } from '@/lib/common/entities/campaign/element/ICampaignElementType'
import { INewCampaignGoal } from '@/lib/common/entities/campaign/goal/ICampaignGoal'
import { IEditCampaign, INewCampaign } from '@/lib/common/entities/campaign/ICampaign'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { ICampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { IImageVariantInfo } from '@/lib/common/entities/campaign/variant/IImageVariant'
import {
  ITextVariant,
  ITextVariantGenerateOptions
} from '@/lib/common/entities/campaign/variant/ITextVariant'
import { IGenerateTextVariant } from '@/lib/server/data/campaign/generateTextVariants'

export type IEditCampaignForm = IEditCampaign | INewCampaign
export interface IEditCampaignInfo {
  step: CampaignStep
  editMode: CampaignEditMode
  draftGoal?: INewCampaignGoal
  selectedVariantMap: ISelectedVariantMap
  searchImages: Record<string, any>
  elementErrors: Record<string, any>
  generateOutputOptions: ITextVariantGenerateOptions
  generating: Record<string, IGenerateTextData>
  elementCollapseMap: Record<string, boolean>
  suggestionsCollapseMap: Record<string, boolean>
  variantsCollapseMap: Record<string, boolean>
  variantPool: IVariantPoolMap
  variantPaginationMap: IVariantPaginationMap
  goalErrors: Record<string, Record<string, any>>
  colorSwatches: number[]
  elementSuggestions: Record<string, ITextVariant[]>
  isActionLoading: boolean
  addedVariantIdsInSession: Record<string, number[]>
}

export interface IEditCampaignState extends IFormState<IEditCampaignForm> {
  info: IEditCampaignInfo
}

export interface IVariantPaginationConfiguration {
  pageNr: number
  pageSize: number
}

export interface IVariantPaginationMap {
  [key: string]: IVariantPaginationConfiguration
}

export interface IVariantPool {
  options: IGenerateTextOptions
  available: IGenerateTextVariant[] // generated variants that are not being used currently
  total: IGenerateTextVariant[] // all generated variants
}

export type IVariantPoolMap = Record<number, IVariantPool>

export enum CampaignStep {
  ChooseURL,
  ClosePopups,
  EditElements,
  EditGoals,
  CampaignTypeSelection,
  Settings
}

export enum CampaignEditMode {
  Create = 'create',
  Update = 'update'
}

export type ISelectedVariantMap = Record<number, number>

export enum CampaignPreviewType {
  ClosePopups = 'ClosePopups',
  EditElements = 'EditElements',
  EditGoals = 'EditGoals',
  CampaignPreview = 'CampaignPreview'
}

export enum GenerationError {
  CouldNotGenerate = 'Could not generate enough variants'
}

export type IAddVariantPosition = 'start' | 'end' | number
export enum DraftElementEditType {
  Add = 'add',
  Update = 'update'
}

export type IElement<
  E extends ICampaignElement | undefined,
  T extends ICampaignElementType
> = Extract<E, { type: T }>

export type INewVariant<V extends ICampaignVariant | undefined> = Extract<
  V,
  { __type__: NewVariantType }
>

export type IVariantsOfElement<
  E extends ICampaignElement | undefined,
  T extends ICampaignElementType
> = IElement<E, T>['variants']

export type IVariantOfElement<
  E extends ICampaignElement | undefined,
  T extends ICampaignElementType
> = IArrayElement<IVariantsOfElement<E, T>>

export interface ISelectVariantsOptions {
  excludeSessionVariants: boolean
}

export interface IImageVariantInfoUpdate {
  variantId: number
  info: Partial<IImageVariantInfo>
}
