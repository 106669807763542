import React from 'react'

const Context = React.createContext<{ width: number; height: number }>({ width: 0, height: 0 })

export const useWindow = () => React.useContext(Context)

export const WindowProvider: React.FC = ({ children }) => {
  const [windowState, setWindowState] = React.useState({
    width: window ? window.innerWidth : 0,
    height: window ? window.innerHeight : 0
  })

  const onResize = React.useCallback(() => {
    setWindowState({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [])

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize)
    }
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return <Context.Provider value={windowState}>{children}</Context.Provider>
}
