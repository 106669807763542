import { createSlice } from '@reduxjs/toolkit'

import { createFormReducers } from '@/lib/client/redux/form/createFormReducers'
import { createInitialFormState } from '@/lib/client/redux/form/createInitialFormState'
import { IIntroSurveyState } from '@/lib/client/redux/intro-survey/types'

export const initialState: IIntroSurveyState = createInitialFormState({
  companyName: '',
  marketingSpend: '<10k',
  demographic: 'Entrepreneur',
  industry: 'Direct to Consumer',
  referral: ''
})

export const IntroSurveySlice = createSlice({
  name: 'introSurvey',
  initialState,
  reducers: {
    ...createFormReducers(initialState)
  }
})
