import { nanoid } from 'nanoid'
import React from 'react'

import { store } from '@/lib/client/redux/common/store'
import { DialogAction } from '@/lib/client/redux/dialog/DialogAction'
import { IDialogOptions } from '@/lib/client/redux/dialog/DialogSlice'

export const showDialog = (
  element: React.ReactElement,
  options?: IDialogOptions,
  onResult?: (result?: any) => void
) => {
  const id = nanoid()
  store.dispatch(
    DialogAction.showDialog({
      id,
      visible: true,
      animationState: 'show',
      element,
      options,
      onResult
    })
  )
  return id
}

export const hideDialog = (id: string) => {
  store.dispatch(DialogAction.hideDialog({ id }))
}

export type IDialogResult<T> = Promise<T> | undefined
