import { IImageStatus } from '@/components/admin/common/image/types'
import { IGenerateImage, IGenerateImageId } from '@/lib/common/entities/api/image'

type ICreateStatus<T extends IImageStatus> = (id: IGenerateImageId) => Partial<T>

export function createImageStatus<T extends IImageStatus>(
  ids: IGenerateImageId[],
  images: IGenerateImage[] | undefined,
  createStatus: ICreateStatus<T>
) {
  return ids.map((id, index) => {
    const status = createStatus(id)
    status.image = images?.[index]
    return status as T
  })
}
