import { isAnyOf } from '@reduxjs/toolkit'

import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftElementSelector } from '@/lib/client/redux/campaign/draft-element/DraftElementSelector'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const updateSelectedVariant = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    matcher: isAnyOf(
      DraftElementAction.setFocusedDraftVariant,
      DraftElementAction.deleteVariant.fulfilled
    ),
    effect: async (_, { getState, dispatch }) => {
      const element = DraftElementSelector.getDraftElement(getState())
      if (element === undefined) {
        return
      }
      const map = EditCampaignSelector.getSelectedVariantMap(getState())
      const id = map[element.id]
      const variant = element.variants.find(v => v.id === id)
      if (variant === undefined) {
        dispatch(EditCampaignAction.setSelectedVariant({ element, variantId: undefined }))
      }
    }
  })
}
