import { RgbaColor } from 'colord'

const pow24 = Math.pow(2, 24)
const pow16 = Math.pow(2, 16)
const pow8 = Math.pow(2, 8)

// javascript bitwise operators don't work for large numbers
export const toRGBA = (color: number): RgbaColor => {
  const r = Math.floor(color / pow24)
  const g = Math.floor((color % pow24) / pow16)
  const b = Math.floor((color % pow16) / pow8)
  const a = Math.round((100 * (color % pow8)) / 255) / 100
  return { r, g, b, a }
}
