import { subscribe } from '@/lib/common/socket/app-sync'
import { IEventMessage } from '@/lib/common/socket/IEventMessage'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('socket.getJobResult')

export const getJobResult = async <T>(jobId: string, timeout?: number) => {
  let timeoutId: any
  return new Promise<T>((resolve, reject) => {
    const subscription = subscribe(`job-${jobId}`, async (message: IEventMessage) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      log.debug('message', message)
      if (message.type === 'result') {
        resolve(message.data)
        subscription.unsubscribe()
      } else if (message.type === 'error') {
        reject(message.data)
        subscription.unsubscribe()
      }
    })
    log.info('subscription', subscription)
    if (timeout) {
      timeoutId = setTimeout(() => {
        reject(new Error('Timeout'))
        subscription.unsubscribe()
      }, timeout)
    }
  }).catch(error => {
    log.error('error', error)
    return undefined
  })
}
