import {
  ICampaignElement,
  IClientCampaignElement,
  INewCampaignElement
} from '@/lib/common/entities/campaign/element/ICampaignElement'

export const isNewElement = (
  element: ICampaignElement | undefined
): element is INewCampaignElement => element !== undefined && '__new__' in element

export const isClientElement = (
  element: ICampaignElement | undefined
): element is IClientCampaignElement => element !== undefined && !('__new__' in element)
