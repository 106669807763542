import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { getDraftElement } from '@/lib/client/redux/campaign/draft-element/selectors/common'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

export const focusDraftVariant = createAsyncThunk<void, number | undefined, IThunkConfig>(
  'draftElement/focusDraftVariant',
  async (variantId, { getState, dispatch }) => {
    const draftElement = getDraftElement(getState())

    if (!draftElement) {
      return
    }

    dispatch(DraftElementAction.setFocusedDraftVariant(variantId))
    dispatch(EditCampaignAction.setSelectedVariant({ element: draftElement, variantId }))
  }
)
