import { ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import { isClientElement } from '@/lib/common/entities/campaign/element/util'

export const getTopPerformingVariant = (element?: ITextElement) => {
  if (!isClientElement(element)) {
    return undefined
  }
  const variants = element.variants
    .filter(
      v => v.metrics?.conversionsPerSession.value && v.metrics?.conversionsPerSession.value > 0
    )
    .sort(
      (a, b) =>
        (b.metrics?.conversionsPerSession.value ?? 0) -
        (a.metrics?.conversionsPerSession.value ?? 0)
    )
  return variants?.[0]?.info.value
}
