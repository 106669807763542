import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'

export const updateElementById = (
  el: ICampaignElement | undefined,
  id: number,
  data: Partial<ICampaignElement>
) => {
  if (el && el.id === id) {
    for (const [key, value] of Object.entries(data)) {
      const element = el as any
      element[key] = value
    }
  }
}
