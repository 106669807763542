import { IGenerateTextData } from '@/lib/client/common/api/text/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { populateVariantPool } from '@/lib/client/redux/campaign/util/populateVariantPool'
import { IListenerAPI } from '@/lib/client/redux/types'
import { delay } from '@/lib/common/utils/delay'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('populateTextVariantPool')

export const generateTextVariants = async (data: IGenerateTextData, listenerApi: IListenerAPI) => {
  const { dispatch } = listenerApi

  dispatch(EditCampaignAction.setGenerationStatus(data))

  if (!(await populateVariantPool(data, listenerApi))) {
    await delay(500)
  }
  const variants = await dispatch(EditCampaignAction.consumeVariantsFromPool(data)).unwrap()

  dispatch(EditCampaignAction.clearGenerationStatus(data))

  return variants
}
