import { showConfirmDialog } from '@/components/admin/dialog/ConfirmDialog'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { LocationAction } from '@/lib/client/redux/location/LocationAction'
import { PreviewSelector } from '@/lib/client/redux/preview/PreviewSelector'
import { IThunkConfig } from '@/lib/client/redux/types'

export const setStepChooseURL = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/setStepChooseURL',
  async (_, { dispatch, getState }) => {
    const step = EditCampaignSelector.getStep(getState())
    const isSuccess = PreviewSelector.isSuccess(getState())

    if (step <= CampaignStep.ChooseURL) {
      dispatch(EditCampaignAction.setStep(CampaignStep.ChooseURL))
      return
    }

    if (!isSuccess) {
      dispatch(EditCampaignAction.setStep(CampaignStep.ChooseURL))
      return
    }

    const shouldStartOver = await showConfirmDialog({
      title: 'Start Over?',
      message:
        "Are you sure you want to go back to the first step? You'll have to input your URL again and start over."
    })

    if (shouldStartOver) {
      dispatch(EditCampaignAction.initCreateCampaign())
      await dispatch(
        LocationAction.modifyQuery((url, as) => {
          url.delete('url')
          as.delete('url')
        })
      )
    }
  }
)
