import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IMessage } from '@/lib/client/redux/message/types'

interface IState {
  value?: IMessage
}

const initialState: IState = {}

export const MessageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<IMessage>) => {
      state.value = action.payload
    },

    clearMessage: state => {
      state.value = undefined
    }
  }
})
