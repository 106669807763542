import { createAsyncThunk } from '@reduxjs/toolkit'

import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { CampaignEditMode, CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ICampaign } from '@/lib/common/entities/campaign/ICampaign'

export const initUpdateCampaign = createAsyncThunk<void, ICampaign, IThunkConfig>(
  'editCampaign/initUpdateCampaign',
  async (campaign, { dispatch, getState }) => {
    const lastCampaignId = getState().editCampaign.values.id
    const currentCampaignId = campaign.id

    if (lastCampaignId === currentCampaignId) return

    dispatch(
      EditCampaignAction.init({
        values: {
          id: campaign.id,
          ingestedUrl: campaign.ingestedUrl,
          elements: campaign.elements,
          meta: campaign.meta
        },
        info: {
          editMode: CampaignEditMode.Update,
          step: CampaignStep.EditElements
        }
      })
    )

    if (campaign.autoGenerationStatus.autoGenerationRequired) {
      dispatch(EditCampaignAction.generateSuggestedVariants(campaign))
    }
  }
)
