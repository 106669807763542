import { BaseQueryFn } from '@reduxjs/toolkit/query'
import localforage from 'localforage'

import { IDraft } from '@/lib/client/common/entities/IDraft'
import { IStorageKey } from '@/lib/client/common/entities/IStorageKey'
import { QueryReturnValue } from '@/lib/client/redux/common/types'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('localForageBaseQuery')

type Arg =
  | {
      method: 'get'
      key: IStorageKey
    }
  | {
      method: 'set'
      key: IStorageKey
      value: any
    }
  | {
      method: 'delete'
      key: IStorageKey
    }
  | {
      method: 'filter'
      key: IStorageKey
    }

const getKey = (key: IStorageKey) => (key.id !== undefined ? `${key.type}:${key.id}` : key.type)

export const localForageBaseQuery: BaseQueryFn = async (
  args: Arg
): Promise<QueryReturnValue<any, string | undefined, object>> => {
  switch (args.method) {
    case 'get': {
      const data: any = await localforage.getItem(getKey(args.key))
      const cacheVersion = getCacheVersion(args.key, data)
      const requestVersion = args.key.version ?? 1
      if (cacheVersion === undefined && requestVersion === 1) {
        return { data: data ?? null }
      } else if (cacheVersion !== requestVersion) {
        return { data: null }
      } else {
        return { data: data.value ?? null }
      }
    }
    case 'set': {
      const key = getKey(args.key)
      const __version__ = args.key.version ?? 1
      if (args.value === undefined) {
        await localforage.removeItem(key)
        return { data: null }
      } else {
        const data = await localforage.setItem(key, {
          value: args.value,
          __version__
        })
        return { data: data.value ?? null }
      }
    }
    case 'delete': {
      await localforage.removeItem(getKey(args.key))
      return { data: true }
    }
    case 'filter': {
      const data: IDraft[] = []
      const filterKey = getKey(args.key)
      await localforage.iterate((value, key) => {
        if (key.startsWith(filterKey)) {
          data.push(value as IDraft)
        }
      })
      return { data }
    }
  }
}

const getCacheVersion = (key: IStorageKey, value: any) => {
  if (!value || typeof value !== 'object') {
    return undefined
  }
  if (Object.prototype.hasOwnProperty.call(value, '__version__')) {
    return value.__version__ || 1
  } else {
    return undefined
  }
}
