import axios from 'axios'

import { formatIngestedUrl } from '@/components/admin/common/util/formatIngestedUrl'
import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { qs } from '@/lib/client/common/util/qs'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { TagId, baseApi } from '@/lib/client/redux/common/api'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { getJobResult } from '@/lib/common/socket/getJobResult'
import { logger } from '@/lib/common/utils/logging/logger'
import { Response } from '@/pages/api/team/[teamSlug]/campaign/screenshot'

const log = logger('redux.editCampaign/createCampaignScreenshot')

export const createCampaignScreenshot = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/createCampaignScreenshot',
  async (_, { dispatch, getState }) => {
    const ingestedUrl = EditCampaignSelector.getIngestedUrl(getState())
    const slug = getTeamSlug()

    try {
      const response = await axios.get<Response>(
        `/api/team/${slug}/campaign/screenshot` + qs({ url: formatIngestedUrl(ingestedUrl) })
      )
      const { jobId, thumbnailSmall: small, thumbnailLarge: large } = response.data
      await getJobResult(jobId)
      dispatch(baseApi.util.invalidateTags([{ type: 'campaign', id: TagId.All }]))
      dispatch(EditCampaignAction.setThumbnails({ small, large }))
    } catch (error) {
      log.error('Could not create screenshot', error)
    }
  }
)
