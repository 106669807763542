import { formatIngestedUrl } from '@/components/admin/common/util/formatIngestedUrl'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { CampaignEditMode, CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { TeamSelector } from '@/lib/client/redux/team/TeamSelector'
import { IThunkConfig } from '@/lib/client/redux/types'

export const initCreateCampaign = createAsyncThunk<void, string | undefined, IThunkConfig>(
  'editCampaign/initCreateCampaign',
  async (url, { getState, dispatch }) => {
    const ingestedUrl = url
      ? formatIngestedUrl(url)
      : EditCampaignSelector.getIngestedUrl(getState())
    const step = url ? CampaignStep.ClosePopups : CampaignStep.ChooseURL

    const subscription = TeamSelector.getSubscription(getState())

    dispatch(
      EditCampaignAction.init({
        values: {
          ingestedUrl,
          isEnabled: Boolean(subscription)
        },
        info: {
          step,
          editMode: CampaignEditMode.Create
        }
      })
    )
  }
)
