import { arrayMove } from '@dnd-kit/sortable'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { produce } from 'immer'

import { IGenerateTextData } from '@/lib/client/common/api/text/types'
import { validateVariantPool } from '@/lib/client/redux/campaign/edit/reducer/validateVariantPool'
import {
  CampaignEditMode,
  CampaignStep,
  GenerationError,
  IAddVariantPosition,
  IEditCampaignForm,
  IEditCampaignInfo,
  IEditCampaignState,
  IImageVariantInfoUpdate,
  IVariantPaginationConfiguration
} from '@/lib/client/redux/campaign/edit/types'
import { getElementVariants } from '@/lib/client/redux/campaign/util/getElementVariants'
import { getSwatches } from '@/lib/client/redux/campaign/util/getSwatches'
import { setElementErrors } from '@/lib/client/redux/campaign/util/setElementErrors'
import { updateConversionGoal } from '@/lib/client/redux/campaign/util/updateConversionGoal'
import { updateIndex } from '@/lib/client/redux/campaign/util/updateIndex'
import { createFormReducers } from '@/lib/client/redux/form/createFormReducers'
import { createInitialFormState } from '@/lib/client/redux/form/createInitialFormState'
import { IFormError } from '@/lib/client/redux/form/types'
import {
  IURLPreviewClickableSelectEvent,
  IURLPreviewMetaEvent
} from '@/lib/client/redux/preview/types'
import { CampaignType } from '@/lib/common/entities/campaign/CampaignType'
import {
  ICampaignElement,
  INewCampaignElement
} from '@/lib/common/entities/campaign/element/ICampaignElement'
import { TrackEventType } from '@/lib/common/entities/campaign/event/TrackEventType'
import { INewCampaignGoal } from '@/lib/common/entities/campaign/goal/ICampaignGoal'
import { ICampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { assertDefined } from '@/lib/common/guards/assertDefined'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'
import { removeItem } from '@/lib/common/utils/removeItem'
import { replaceItem } from '@/lib/common/utils/replaceItem'
import { setItem } from '@/lib/common/utils/setItem'
import {
  IGenerateTextResponse,
  IGenerateTextVariant
} from '@/lib/server/data/campaign/generateTextVariants'

export const initialState: IEditCampaignState = {
  ...createInitialFormState({
    id: 0,
    name: undefined,
    description: undefined,
    meta: undefined,
    type: undefined,
    ingestedUrl: '',
    elements: [],
    thumbnail: {
      small: '',
      large: ''
    },
    exclusiveAudienceMode: false,
    goals: [],
    trafficAllocation: 100,
    isEnabled: true
  }),
  info: {
    step: 0,
    editMode: CampaignEditMode.Create,
    generating: {},
    elementErrors: {},
    searchImages: {},
    variantPool: {},
    generateOutputOptions: {},
    variantPaginationMap: {},
    selectedVariantMap: {},
    elementCollapseMap: {},
    suggestionsCollapseMap: {},
    variantsCollapseMap: {},
    goalErrors: {},
    colorSwatches: [],
    elementSuggestions: {},
    isActionLoading: false,
    addedVariantIdsInSession: {}
  }
}

export const EditCampaignSlice = createSlice({
  name: 'editCampaign',
  initialState,
  reducers: {
    ...createFormReducers(initialState),

    setIngestedUrl: (state, action: PayloadAction<string>) => {
      state.values.ingestedUrl = action.payload
    },

    addElement: (state, action: PayloadAction<INewCampaignElement>) => {
      const { elements } = state.values
      elements.push(action.payload)
      updateIndex(elements)
    },

    replaceElement: (state, action: PayloadAction<ICampaignElement | undefined>) => {
      const { elements } = state.values
      const element = action.payload
      if (element === undefined) {
        return
      }
      replaceItem(elements, e => e.id === element.id, { ...element })
      updateIndex(elements)
    },

    removeElement: (state, action: PayloadAction<ICampaignElement>) => {
      const { elements } = state.values
      removeItem(elements, e => e.id === action.payload.id)
      updateIndex(elements)
    },

    processMetaEvent: (state, action: PayloadAction<IURLPreviewMetaEvent>) => {
      const { values, info } = state
      const { payload } = action

      values.name = payload.data.title
      values.meta = payload.data
      info.colorSwatches = getSwatches(payload.data.stats)
    },

    setThumbnails: (state, action: PayloadAction<{ small: string; large: string }>) => {
      state.values.thumbnail = action.payload
    },

    resetVariants: (
      state,
      action: PayloadAction<{
        elementId: number
        variants: ICampaignVariant[]
      }>
    ) => {
      const { variants } = action.payload
      const { elements } = state.values

      const element = elements.find(e => e.id === action.payload.elementId)
      if (!element) {
        return
      }
      element.variants = produce(variants, v => updateIndex(v)) as any
    },

    setSelectedVariant: (
      state,
      action: PayloadAction<{
        element: ICampaignElement
        variantId: number | undefined
      }>
    ) => {
      const { selectedVariantMap } = state.info
      const { element, variantId } = action.payload
      if (variantId) {
        selectedVariantMap[element.id] = variantId
      } else {
        delete selectedVariantMap[element.id]
      }
    },

    resetSelectedVariants: state => {
      state.info.selectedVariantMap = {}
    },

    updateAvailableVariants: (
      state,
      action: PayloadAction<{ elementId: number; variants: IGenerateTextVariant[] }>
    ) => {
      const { elementId, variants } = action.payload

      state.info.variantPool[elementId].available = variants
    },

    removeVariants: (state, action: PayloadAction<{ elementId: number; variantIds: number[] }>) => {
      const { variantIds, elementId } = action.payload
      const element = state.values.elements.find(e => e.id === elementId)
      if (!element) {
        return
      }
      const map = new Set(variantIds)
      element.variants = element.variants.filter(variant => !map.has(variant.id)) as any
      updateIndex(element.variants)
    },

    ensureValidElementSelection: (state, action: PayloadAction<number>) => {
      const elementId = action.payload

      const { selectedVariantMap } = state.info
      if (elementId === undefined) {
        return
      }
      const selectedId = selectedVariantMap[elementId]
      if (selectedId === undefined) {
        return
      }
      const element = state.values.elements.find(e => e.id === elementId)
      if (element === undefined) {
        return
      }
      const variant = element.variants.find(v => v.id === selectedId)
      if (variant === undefined) {
        delete selectedVariantMap[elementId]
      }
    },

    addVariantsToElement: (
      state,
      action: PayloadAction<{
        element: ICampaignElement
        position: IAddVariantPosition
        variants: ICampaignVariant[] | undefined
      }>
    ) => {
      const { position, variants } = action.payload
      const elementId = action.payload.element.id

      if (variants && variants.length === 0) {
        setElementErrors(state, elementId, GenerationError.CouldNotGenerate).then()
      }

      const element = state.values.elements.find(e => e.id === elementId)
      if (!element || !variants || variants.length === 0) {
        return
      }
      if (position === 'start') {
        getElementVariants(element)?.unshift(...variants)
      } else if (position === 'end') {
        getElementVariants(element)?.push(...variants)
      } else {
        getElementVariants(element)?.splice(position + 1, 0, ...variants)
      }
    },

    addVariantsToPool: (
      state,
      action: PayloadAction<{
        data: IGenerateTextData
        response: IGenerateTextResponse | undefined
      }>
    ) => {
      const { data, response } = action.payload

      if (response === undefined) {
        return
      }
      const map = state.info.variantPool
      const pool =
        map[data.element.id] ??
        (map[data.element.id] = {
          options: data.options,
          available: [],
          total: data.element.variants.map(v => ({
            value: v.info.value,
            styles: v.info.styles
          }))
        })
      pool.options = data.options
      pool.available.push(...response.variants)
      pool.total.push(...response.variants)
    },

    setGoals: (state, action: PayloadAction<INewCampaignGoal[]>) => {
      state.values.goals = action.payload
    },

    setDraftGoal: (state, action: PayloadAction<INewCampaignGoal | undefined>) => {
      state.info.draftGoal = action.payload
    },

    createDraftGoal: (state, action: PayloadAction<TrackEventType>) => {
      const { ingestedUrl, goals } = state.values

      let goal: INewCampaignGoal
      switch (action.payload) {
        case TrackEventType.Click: {
          goal = {
            id: generateTemporaryId(),
            event: {
              type: TrackEventType.Click,
              url: ingestedUrl,
              name: `Click Goal #${goals.length + 1}`,
              elements: []
            },
            isConversionGoal: false
          }
          break
        }
        case TrackEventType.PageView: {
          goal = {
            id: generateTemporaryId(),
            event: {
              type: TrackEventType.PageView,
              url: ingestedUrl,
              name: `Page View Goal #${goals.length + 1}`
            },
            isConversionGoal: false
          }
          break
        }

        default:
          throw new Error('Unsupported track event type in this step.')
      }
      state.info.draftGoal = goal
    },

    saveDraftGoal: state => {
      const { goals } = state.values
      const { draftGoal } = state.info

      if (draftGoal) {
        setItem(goals, g => g.id === draftGoal.id, draftGoal)
        updateConversionGoal(state)
        state.info.draftGoal = undefined
      }
    },

    swapGoals: (
      state,
      action: PayloadAction<{ idA: number | undefined; idB: number | undefined }>
    ) => {
      const { goals } = state.values
      const { idA, idB } = action.payload

      const indexA = goals.findIndex(g => g.id === idA)
      const indexB = goals.findIndex(g => g.id === idB)
      state.values.goals = arrayMove(goals, indexA, indexB)
      updateConversionGoal(state)
    },

    deleteGoal: (state, action: PayloadAction<number>) => {
      const { goals } = state.values
      state.values.goals = goals.filter(g => g.id !== action.payload)
      updateConversionGoal(state)
    },

    selectClickElement: (state, action: PayloadAction<IURLPreviewClickableSelectEvent>) => {
      const event = state.info.draftGoal?.event
      if (event?.type !== TrackEventType.Click) {
        throw new Error("Tried to select a preview element that doesn't exist")
      }

      const { payload } = action
      if (event.elements.find(e => e.selector === payload.selector)) {
        event.elements = event.elements.filter(e => e.selector !== payload.selector)
      } else {
        event.elements.push({
          id: generateTemporaryId(),
          label: payload.label,
          selector: payload.selector
        })
      }
    },

    deleteClickElement: (state, action: PayloadAction<number>) => {
      const { draftGoal } = state.info
      assertDefined(draftGoal, "Tried to remove a click element from a goal that doesn't exist")
      const { event } = draftGoal

      if (event.type === TrackEventType.Click) {
        event.elements = event.elements.filter(e => e.id !== action.payload)
      }
    },

    setStep: (state, action: PayloadAction<CampaignStep>) => {
      state.info.step = action.payload
      const container = document.getElementById('scrollContainer')
      if (container) {
        container.scrollTop = 0
      }
    },

    setElementErrors: (
      state,
      action: PayloadAction<{
        elementId: number
        error: GenerationError | undefined
      }>
    ) => {
      const { elementId, error } = action.payload
      setElementErrors(state, elementId, error)
    },

    setSearchImage: (
      state,
      action: PayloadAction<{ elementId: number; search: string | undefined }>
    ) => {
      const { elementId, search } = action.payload
      if (search) {
        state.info.searchImages[elementId] = search
      } else {
        delete state.info.searchImages[elementId]
      }
    },

    init: (
      _,
      action: PayloadAction<{
        values?: Partial<IEditCampaignForm>
        info?: Partial<IEditCampaignInfo>
      }>
    ) => {
      const { values, info } = action.payload
      return {
        ...initialState,
        values: { ...initialState.values, ...values },
        info: { ...initialState.info, ...info }
      }
    },

    setIsActionLoading: (state, action: PayloadAction<boolean>) => {
      state.info.isActionLoading = action.payload
    },

    setError: (state, action: PayloadAction<{ id: string; error: IFormError | undefined }>) => {
      const { id, error } = action.payload
      if (error !== undefined) {
        state.errors[id] = error
      } else {
        delete state.errors[id]
      }
    },

    updateElementById: (
      state,
      action: PayloadAction<{ id: number; update: Partial<ICampaignElement> }>
    ) => {
      const { id, update } = action.payload

      const element = state.values.elements.find(el => el.id === id)
      if (element) {
        Object.assign(element, update)
      }
    },

    updateElementBySelector: (
      state,
      action: PayloadAction<{ selector: string; update: Partial<ICampaignElement> }>
    ) => {
      const { selector, update } = action.payload

      const element = state.values.elements.find(el => el.selector === selector)
      if (element) {
        Object.assign(element, update)
      }
    },

    setCampaignType: (state, action: PayloadAction<CampaignType>) => {
      state.values.type = action.payload
    },

    setExclusiveAudienceMode: (state, action: PayloadAction<boolean>) => {
      state.values.exclusiveAudienceMode = action.payload
    },

    setVariantPaginationConfiguration: (
      state,
      action: PayloadAction<{ elementId: number; configuration: IVariantPaginationConfiguration }>
    ) => {
      const { elementId, configuration } = action.payload
      state.info.variantPaginationMap[elementId] = configuration
    },

    toggleElementCollapse: (state, action: PayloadAction<ICampaignElement>) => {
      const { elementCollapseMap: map } = state.info
      const { id } = action.payload

      if (map[id]) {
        delete map[id]
      } else {
        map[id] = true
        delete state.info.selectedVariantMap[id]
      }
    },

    toggleSuggestionsCollapse: (state, action: PayloadAction<ICampaignElement>) => {
      const { suggestionsCollapseMap: map } = state.info
      const { id } = action.payload

      if (map[id]) {
        delete map[id]
      } else {
        map[id] = true
        if (
          (state.info.elementSuggestions[id] || [])
            .map(v => v.id)
            .includes(state.info.selectedVariantMap[id])
        ) {
          delete state.info.selectedVariantMap[id]
        }
      }
    },

    toggleVariantsCollapse: (state, action: PayloadAction<ICampaignElement>) => {
      const { variantsCollapseMap: map } = state.info
      const { id } = action.payload

      if (map[id]) {
        delete map[id]
      } else {
        map[id] = true
        const element = state.values.elements.find(e => e.id === id)
        if (
          element &&
          element.variants.map(v => v.id).includes(state.info.selectedVariantMap[id])
        ) {
          delete state.info.selectedVariantMap[id]
        }
      }
    },

    updateVariantsAddedInSession: (
      state,
      action: PayloadAction<{ elementId: number; variantIds: number[] }>
    ) => {
      const { elementId, variantIds } = action.payload

      if (!state.info.addedVariantIdsInSession[elementId]) {
        state.info.addedVariantIdsInSession[elementId] = []
      }

      state.info.addedVariantIdsInSession[elementId].push(...variantIds)
    },

    setGenerationStatus: (state, action: PayloadAction<IGenerateTextData>) => {
      state.info.generating[action.payload.element.id] = { ...action.payload }
    },

    clearGenerationStatus: (state, action: PayloadAction<IGenerateTextData>) => {
      const { element } = action.payload
      delete state.info.generating[element.id]
    },

    validateVariantPool,

    updateObjective: (state, action: PayloadAction<{ elementId: number; objective: string }>) => {
      const element = state.values.elements.find(e => e.id === action.payload.elementId)
      if (element) {
        element.type === 'text' ? (element.objective = action.payload.objective) : undefined
      }
    },

    mergeImageVariantInfo: (
      state,
      action: PayloadAction<{ elementId: number; updates: IImageVariantInfoUpdate[] }>
    ) => {
      const element = state.values.elements.find(e => e.id === action.payload.elementId)
      if (element) {
        const variantMap = new Map(element.variants.map(variant => [variant.id, variant]))
        for (const update of action.payload.updates) {
          const variant = variantMap.get(update.variantId)
          if (variant) {
            variant.info = { ...variant.info, ...update.info }
          }
        }
      }
    }
  }
})
