import { createSelector } from '@reduxjs/toolkit'

import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { IState } from '@/lib/client/redux/types'
import { parseFeatureFlags } from '@/lib/client/redux/user/hooks/useFeatureFlags'
import { UserApi } from '@/lib/client/redux/user/UserApi'

const getContextFactory = createSelector(
  (slug: string) => slug,
  (slug: string) => UserApi.endpoints.getUserContext.select({ team: slug })
)

export const getContext = (state: IState) => getContextFactory(getTeamSlug())(state).data

export const getUser = (state: IState) => getContext(state)?.user
export const getInitials = createSelector(getUser, user => {
  const name = user?.realName
  return name
    ? name
        .split(' ')
        .slice(0, 2)
        .map(x => x.charAt(0))
    : undefined
})

export const getFeatureFlags = createSelector(getContext, context => {
  return parseFeatureFlags(context?.featureFlags)
})
