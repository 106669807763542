import { getImageMutations } from '@/lib/client/embed/mutation/getImageMutations'
import { getLayoutMutations } from '@/lib/client/embed/mutation/getLayoutMutations'
import { getTextMutations } from '@/lib/client/embed/mutation/getTextMutations'
import { ICampaignElementType } from '@/lib/common/entities/campaign/element/ICampaignElementType'
import {
  ISampleImageInfo,
  ISampleLayoutInfo,
  ISampleTextInfo
} from '@/lib/server/data/campaign/embed/types'

export const getMutations = (
  type: ICampaignElementType,
  info: ISampleTextInfo | ISampleImageInfo | ISampleLayoutInfo
) => {
  switch (type) {
    case 'text':
      return getTextMutations(info as ISampleTextInfo)
    case 'image':
      return getImageMutations(info as ISampleImageInfo)
    case 'layout':
      return getLayoutMutations(info as ISampleLayoutInfo)
  }
}
