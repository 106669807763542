import { AsyncThunk } from '@reduxjs/toolkit'

import { isPreparedAction } from '@/lib/client/common/util/isAction'
import { IListenerAPI } from '@/lib/client/redux/types'

export const takeThunk = async <T extends AsyncThunk<any, any, any>>(
  take: IListenerAPI['take'],
  thunk: T,
  predicate: (action: ReturnType<T['fulfilled']>['meta']['arg']) => boolean,
  timeout?: number
) => {
  const result = await take(
    action => isPreparedAction(action, thunk.fulfilled) && predicate(action.meta.arg),
    timeout
  )
  if (result === null) {
    throw new Error('timeout')
  }
  return result[0] as ReturnType<T['fulfilled']>
}
