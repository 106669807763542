// even if we could not find relevant domStats we will send the request for an attribute to
// AI generation. This way it's up to ai generation whether to generate values without a control

import { ITextAttributes, ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import {
  ITextVariantStyle,
  TextVariantStyleType
} from '@/lib/common/entities/campaign/variant/ITextVariant'

export const getStyles = (element: ITextElement, attributes: ITextAttributes) => {
  const { domStats } = element
  if (domStats === undefined) {
    return []
  }
  const styles: ITextVariantStyle[] = []
  if (attributes.color) {
    const textColor = domStats.textColor[0]?.value
    styles.push({ type: TextVariantStyleType.TextColor, value: textColor })
    const backgroundColor = domStats.backgroundColor[0]?.value
    styles.push({ type: TextVariantStyleType.BackgroundColor, value: backgroundColor })
    const borderColor = domStats.borderColor[0]?.value
    styles.push({ type: TextVariantStyleType.BorderColor, value: borderColor })
  }
  if (attributes.fontSize) {
    const fontSize = domStats.fontSize[0]?.size
    styles.push({ type: TextVariantStyleType.FontSize, value: fontSize })
  }
  if (attributes.borderWidth) {
    const borderWidth = domStats.borderWidth[0]?.width
    styles.push({ type: TextVariantStyleType.BorderWidth, value: borderWidth })
  }
  if (attributes.padding) {
    const paddingTop = domStats.padding[0]?.top
    const paddingRight = domStats.padding[0]?.right
    const paddingBottom = domStats.padding[0]?.bottom
    const paddingLeft = domStats.padding[0]?.left
    styles.push({
      type: TextVariantStyleType.Padding,
      value: {
        top: paddingTop,
        right: paddingRight,
        bottom: paddingBottom,
        left: paddingLeft
      }
    })
  }
  if (attributes.borderRadius) {
    const topLeft = domStats.borderRadius[0]?.topLeft
    const topRight = domStats.borderRadius[0]?.topRight
    const bottomLeft = domStats.borderRadius[0]?.bottomLeft
    const bottomRight = domStats.borderRadius[0]?.bottomRight
    styles.push({
      type: TextVariantStyleType.BorderRadius,
      value: {
        topLeft,
        topRight,
        bottomLeft,
        bottomRight
      }
    })
  }
  return styles
}
