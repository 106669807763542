import { ListenerMiddlewareInstance, createListenerMiddleware } from '@reduxjs/toolkit'

import { DraftElementListeners } from '@/lib/client/redux/campaign/draft-element/DraftElementListeners'
import { EditCampaignListeners } from '@/lib/client/redux/campaign/edit/EditCampaignListeners'
import { GenerateImageListener } from '@/lib/client/redux/image/generate/GenerateImageListener'

export const createListeners = () => {
  const middleware: any = createListenerMiddleware()
  EditCampaignListeners(middleware)
  DraftElementListeners(middleware)
  GenerateImageListener(middleware)
  return middleware as ListenerMiddlewareInstance
}
