import { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { Provider as ReduxProvider } from 'react-redux'

import { NoSSR } from '@/components/admin/common/NoSSR'
import { Providers } from '@/components/admin/common/Providers'
import { RootErrorBoundary } from '@/components/shared/common/RootErrorBoundary'
import { store } from '@/lib/client/redux/common/store'
import { config } from '@/lib/common/config'

// Import swiper css in `_app.tsx` for proper css loading in built environments
import '@/components/admin/common/Swiper'

function App({ Component, pageProps }: AppProps) {
  require('react-toastify/dist/ReactToastify.css')
  require('../styles/globals.css')

  return (
    <NoSSR>
      <ReduxProvider store={store}>
        {/* eslint-disable-next-line @next/next/no-script-component-in-head */}
        <Head>
          <title>{config.meta.title}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <link rel="mask-icon" href="/images/logo/safari-pinned-tab.svg" color="#013E64" />
          <link rel="apple-touch-icon" sizes="180x180" href="/images/logo/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/images/logo/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/images/logo/favicon-16x16.png" />
          <link rel="icon" href="/images/logo/favicon.ico" />
          <link rel="manifest" href="/site.webmanifest" />
          <meta name="theme-color" content="#013E64" />
          <meta name="msapplication-TileColor" content="#013E64" />
          <meta name="msapplication-TileImage" content="/images/logo/mstile-144x144.png" />
          <meta name="theme-color" content="#013E64" />
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <meta name="description" content="Validate ideas fast, with burner landing pages" />
          <meta name="keywords" content="pages,validation, lean method, startups" />
          <meta property="twitter:image:src" content={config.meta.image} />
          <meta property="twitter:card" content="summary" />
          <meta property="twitter:url" content={config.meta.url} />
          <meta property="twitter:title" content={config.meta.title} />
          <meta property="twitter:description" content={config.meta.description} />
          <meta property="og:image" content={config.meta.image} />
          <meta property="og:site_name" content={config.meta.url} />
          <meta property="og:type" content={config.meta.type} />
          <meta property="og:title" content={config.meta.title} />
          <meta property="og:url" content={config.meta.url} />
          <meta property="og:description" content={config.meta.description} />
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.env.GOOGLE_ANALYTICSID}`}
          />
          <Script
            id="dataLayer"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.env.GOOGLE_ANALYTICSID}', {
              page_path: window.location.pathname,
            });
          `
            }}
          />
        </Head>
        <RootErrorBoundary>
          <Providers>
            <Component key="component" {...pageProps} />
            <Script
              id="microsoft-clarity"
              dangerouslySetInnerHTML={{
                __html: `
(function(c,l,a,r,i,t,y){
c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "gxdye8gl38");
`
              }}
            />
          </Providers>
        </RootErrorBoundary>
      </ReduxProvider>
    </NoSSR>
  )
}

export default App
