import { qs } from '@/lib/client/common/util/qs'
import { baseApi } from '@/lib/client/redux/common/api'
import { ITeamPayload } from '@/lib/client/redux/types'
import * as GetUrlContext from '@/pages/api/team/[teamSlug]/url-context/by-url'
import * as SaveUrlContext from '@/pages/api/team/[teamSlug]/url-context/save'
import * as GetWaitlist from '@/pages/api/team/[teamSlug]/waitlist'
import * as GetUserContext from '@/pages/api/user/by-session'
import * as RegisterUser from '@/pages/api/user/register'
import * as SaveUser from '@/pages/api/user/save'
import * as ValidateName from '@/pages/api/user/validate-name'
import * as ValidatePassword from '@/pages/api/user/validate-password'

export const UserApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getUserContext: build.query<GetUserContext.Response, GetUserContext.Query>({
      query: payload => '/user/by-session' + qs(payload),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: [{ type: 'user' }]
    }),
    validatePassword: build.query<ValidatePassword.Response, ValidatePassword.Query>({
      query: payload => '/user/validate-password' + qs(payload)
    }),
    validateName: build.query<ValidateName.Response, ValidateName.Query>({
      query: payload => '/user/validate-name' + qs(payload)
    }),
    saveUser: build.mutation<SaveUser.Response, SaveUser.Body>({
      query: payload => ({
        url: '/user/save',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [{ type: 'user' }]
    }),
    registerUser: build.mutation<RegisterUser.Response, RegisterUser.Body>({
      query: payload => ({
        url: '/user/register',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [{ type: 'user' }]
    }),
    getWaitlist: build.query<GetWaitlist.Response, ITeamPayload<GetWaitlist.Query>>({
      query: ({ team, payload }) => `/team/${encodeURIComponent(team)}/waitlist` + qs(payload),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: [{ type: 'user' }]
    }),
    getUrlContext: build.query<GetUrlContext.Response, ITeamPayload<GetUrlContext.Query>>({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/url-context/by-url` + qs(payload)
      }),
      providesTags: [{ type: 'url-context' }]
    }),
    saveUrlContext: build.mutation<SaveUrlContext.Response, ITeamPayload<SaveUrlContext.Body>>({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/url-context/save`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [{ type: 'url-context' }]
    })
  })
})
