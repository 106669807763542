import { getConversionsPerSession } from '@/lib/client/common/util/getConversionsPerSession'
import { getRawConversionsPerView } from '@/lib/client/common/util/getRawConversionsPerView'
import { getRevenuePerSession } from '@/lib/client/common/util/getRevenuePerSession'
import { ICampaignBenchmark } from '@/lib/common/entities/campaign/ICampaignBenchmark'
import {
  ICampaignDerivedMetric,
  ICampaignMetrics,
  IMetrics
} from '@/lib/common/entities/campaign/IMetrics'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('createCampaignMetricsTransformer')

const metricDeriver = (value: number, benchmark: number | undefined) => {
  const original: Record<number | string, number> = {}
  const potential: Record<number | string, number> = {}
  return {
    setMetrics: (elementId: number | string, control: boolean, value: number | undefined) => {
      if (value === undefined) {
        return
      }
      const currentPotential = potential[elementId] ?? Number.MIN_VALUE
      if (value > currentPotential) {
        potential[elementId] = value
      }
      if (control) {
        original[elementId] = value
      }
    },
    getDerivedMetrics: (): ICampaignDerivedMetric => {
      const potentialAverage = getAverage(potential)
      const originalAverage = getAverage(original)
      return {
        value,
        benchmark: benchmark ?? 0,
        improvement: benchmark ? (value - benchmark) / benchmark : 0,
        potential: potentialAverage,
        potentialImprovement: benchmark ? (potentialAverage - benchmark) / benchmark : 0,
        original: originalAverage
      }
    }
  }
}

export const createCampaignMetricsTransformer = (
  metrics: IMetrics,
  benchmark: ICampaignBenchmark
) => {
  const rawConversionsPerView = metricDeriver(
    getRawConversionsPerView(metrics),
    benchmark.rawConversionsPerView
  )
  const conversionsPerSession = metricDeriver(
    getConversionsPerSession(metrics),
    benchmark.conversionsPerSession
  )
  const revenuePerSession = metricDeriver(
    getRevenuePerSession(metrics),
    benchmark.revenuePerSession
  )
  return {
    setMetrics: (elementId: number | string, control: boolean, metrics: IMetrics | undefined) => {
      if (metrics) {
        rawConversionsPerView.setMetrics(elementId, control, getRawConversionsPerView(metrics))
        conversionsPerSession.setMetrics(elementId, control, getConversionsPerSession(metrics))
        revenuePerSession.setMetrics(elementId, control, getRevenuePerSession(metrics))
      }
    },
    getDerivedMetrics: (): ICampaignMetrics => {
      return {
        ...metrics,
        rawConversionsPerView: rawConversionsPerView.getDerivedMetrics(),
        conversionsPerSession: conversionsPerSession.getDerivedMetrics(),
        revenuePerSession: revenuePerSession.getDerivedMetrics()
      }
    }
  }
}

export type ICreateCampaignMetricsTransformer = ReturnType<typeof createCampaignMetricsTransformer>

const getAverage = (map: Record<number, number>) => {
  let count = 0
  let total = 0
  for (const value of Object.values(map)) {
    if (value !== undefined) {
      count++
      total += value
    }
  }
  return count ? total / count : 0
}
