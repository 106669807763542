import Link from 'next/link'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Spinner } from '@/components/shared/loading/Spinner'

export type IButtonVariant = 'solid' | 'outline' | 'ghost' | 'gradient' | 'link' | 'ghost-inline'
export type IButtonColor = 'primary' | 'secondary' | 'danger'
export type IButtonSize = 'sm' | 'md' | 'lg'

interface Props {
  size?: IButtonSize
  href?: string
  isExternalLink?: boolean
  onClick?: React.MouseEventHandler
  className?: string
  enabled?: boolean
  loading?: boolean
  loadingText?: string
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  variant?: IButtonVariant
  color?: IButtonColor
}

export const Button: React.FC<Props> = ({
  href,
  isExternalLink,
  onClick,
  loading = false,
  enabled = !loading,
  loadingText,
  className,
  size = 'md',
  children,
  leftIcon,
  rightIcon,
  variant = 'solid',
  color = 'primary'
}) => {
  const content = loading ? (
    <div className="flex items-center">
      <Spinner className={classes.spinner(variant, color, size)} />
      {loadingText && <span className="ml-2">{loadingText}</span>}
    </div>
  ) : (
    <>
      {leftIcon}
      {children}
      {rightIcon}
    </>
  )
  if (href && enabled) {
    return (
      <Link
        className={classes.link(className, variant, color, size, enabled)}
        onClick={enabled ? onClick : undefined}
        href={href}
        target={isExternalLink ? '_blank' : undefined}
        rel={isExternalLink ? 'noopener noreferrer' : undefined}
      >
        {content}
      </Link>
    )
  } else {
    return (
      <a
        className={classes.link(className, variant, color, size, enabled)}
        onClick={enabled ? onClick : undefined}
      >
        {content}
      </a>
    )
  }
}

const classes = {
  link: (
    className: string | undefined,
    v: IButtonVariant,
    c: IButtonColor,
    s: IButtonSize,
    enabled: boolean
  ) => {
    const simple = v === 'link' || v === 'ghost' || v === 'ghost-inline'
    return twMerge(
      'flex gap-2 items-center justify-center min-w-[90px] whitespace-nowrap text-md cursor-pointer no-underline font-bold font-archivo rounded-[5px] select-none',
      'transition-all',
      !enabled && 'cursor-not-allowed',
      v === 'solid' && 'border border-transparent text-white',
      v === 'solid' && c === 'primary' && 'bg-primary',
      v === 'solid' && c === 'primary' && enabled && 'hover:brightness-[1.175]',
      v === 'solid' && c === 'primary' && !enabled && 'bg-gray-300',
      v === 'solid' && c === 'secondary' && 'bg-gray-600',
      v === 'solid' && c === 'secondary' && enabled && 'hover:brightness-[1.15]',
      v === 'solid' && c === 'secondary' && !enabled && 'bg-gray-300',
      v === 'solid' && c === 'danger' && 'bg-red-600',
      v === 'solid' && c === 'danger' && enabled && 'hover:brightness-[1.15]',
      v === 'solid' && c === 'danger' && !enabled && 'bg-red-300',
      v === 'gradient' && 'bg-gradient-primary text-white text-sm',
      v === 'outline' && 'bg-white border',
      v === 'outline' && c === 'primary' && 'border-primary text-primary',
      v === 'outline' && c === 'primary' && enabled && 'hover:brightness-[2]',
      v === 'outline' && c === 'secondary' && 'border-gray-300 text-gray-600',
      v === 'outline' && c === 'secondary' && enabled && 'hover:brightness-[1.15]',
      v === 'outline' && !enabled && 'border-gray-300 text-gray-300',
      simple && c === 'primary' && 'text-primary',
      simple && c === 'primary' && enabled && 'hover:brightness-[1.25]',
      simple && c === 'primary' && !enabled && 'text-gray-300',
      simple && c === 'secondary' && 'text-gray-600',
      simple && c === 'secondary' && enabled && 'hover:brightness-[1.25]',
      simple && c === 'secondary' && !enabled && 'text-gray-300',
      v === 'link' && 'px-0 min-w-[auto] underline',
      s === 'sm' && 'px-3 h-9 font-semibold text-sm min-w-[68px]',
      s === 'md' && 'px-6 h-10',
      s === 'lg' && 'px-[20px] h-11 text-xl',
      v === 'ghost-inline' && 'px-0 min-w-[0px]',
      className
    )
  },
  spinner: (variant: IButtonVariant, color: IButtonColor, size: IButtonSize) =>
    twMerge(
      'inline-block w-4 h-4 text-primary',
      size === 'sm' && 'w-3 h-3',
      size === 'md' && 'w-4 h-4',
      size === 'lg' && 'w-5 h-5',
      variant === 'solid' && 'text-white',
      variant === 'outline' && 'text-gray-300',
      variant === 'ghost' && 'text-gray-300',
      variant === 'link' && 'text-gray-300'
    )
}
