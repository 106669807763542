import React from 'react'

export const NoSSR: React.FC = ({ children }) => {
  const [client, setClient] = React.useState(false)
  React.useEffect(() => {
    setClient(true)
  }, [])
  if (client) {
    return <>{children}</>
  } else {
    return null
  }
}
