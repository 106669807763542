import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { PreviewAction } from '@/lib/client/redux/preview/PreviewAction'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const focusElement = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    actionCreator: EditCampaignAction.setSelectedVariant,
    effect: async (action, { dispatch }) => {
      dispatch(
        PreviewAction.sendAction({
          type: 'focus',
          selector: action.payload.element.selector
        })
      )
    }
  })
}
