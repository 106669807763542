import { ICampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { TextVariantType } from '@/lib/common/entities/campaign/variant/ITextVariant'

export const validateVariant = (variant: ICampaignVariant) => {
  if (variant.type === 'text') {
    if (variant.info.textType === TextVariantType.Normal) {
      return !!variant.info.value || variant.info.styles.length > 0
    } else {
      if (!variant.info.html) {
        variant.info.textType = TextVariantType.Normal
      }
      return true
    }
  } else {
    return true
  }
}
