import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'

import { config } from '@/lib/common/config'
import { publish as _publish } from '@/lib/common/socket/graphql/mutations'
import { subscribe as _subscribe } from '@/lib/common/socket/graphql/subscriptions'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('common.app-sync')

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: config.env.APP_SYNC_ENDPOINT,
      region: 'us-east-1',
      defaultAuthMode: 'apiKey',
      apiKey: config.env.APP_SYNC_API_KEY
    }
  }
})

const client = generateClient()

/**
 * @param  {string} channel the name of the channel
 * @param  {Object} message the data to publish to the channel
 */
export async function publish(channel: string, message: any) {
  try {
    return await client.graphql({
      query: _publish,
      variables: { name: channel, data: JSON.stringify(message) }
    })
  } catch (error) {
    log.error('publish error', error)
  }
}

export function subscribe(channel: string, next: (message: any) => void, error?: any) {
  return client.graphql({ query: _subscribe, variables: { name: channel } }).subscribe({
    next: data => {
      next(JSON.parse(data.data.subscribe.data))
    },
    // eslint-disable-next-line no-console
    error: error || console.log
  })
}
