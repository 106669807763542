import { IEmbedMutation } from '@/lib/client/embed/mutation/types'
import { IBaseMutation } from '@/lib/client/embed/shared/types'
import { ISampleImageInfo } from '@/lib/server/data/campaign/embed/types'

export function getImageMutations(info: ISampleImageInfo) {
  const { src } = info
  const mutations: (IEmbedMutation | IBaseMutation)[] = []
  if (src) {
    mutations.push({ type: 'imageSrc', value: src })
  }
  return mutations
}
