import { ThemeProvider } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import React from 'react'
import { ToastContainer, cssTransition } from 'react-toastify'

import { getStripe } from '@/components/admin/common/stripe'
import { theme } from '@/components/admin/common/theme'
import { UsersnapProvider } from '@/components/admin/common/UsersnapContext'
import { WindowProvider } from '@/components/shared/common/WindowProvider'
import { DialogContainer } from '@/components/shared/dialog/DialogContainer'

export const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <WindowProvider>
        <Elements stripe={getStripe()}>
          <UsersnapProvider>
            {children}
            <DialogContainer />
            <ToastContainer
              position="top-center"
              closeButton={false}
              transition={cssTransition({
                enter: 'slide-in-top',
                exit: 'slide-out-top'
              })}
              hideProgressBar
            />
          </UsersnapProvider>
        </Elements>
      </WindowProvider>
    </ThemeProvider>
  )
}
