import { TypedAddListener, addListener as _addListener } from '@reduxjs/toolkit'
import { useMemo, useRef } from 'react'
import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector
} from 'react-redux'

import { IDispatch, IState } from '@/lib/client/redux/types'

export const useDispatch: () => IDispatch = _useDispatch
export const useSelector: TypedUseSelectorHook<IState> = _useSelector
export const addListener = _addListener as TypedAddListener<IState, IDispatch>

export const useCachedSelector: typeof useSelector = selector => {
  const value = _useSelector(selector)
  const ref = useRef(value)
  if (value !== undefined) {
    ref.current = value
  }
  return ref.current
}

export const useSelectorFactory = <Params extends any[], Result>(
  selectorFactory: (...args: Params) => (state: IState) => Result,
  ...args: Params
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectorFactory(...args), args)
  return useSelector(selector)
}
