import { isAnyOf } from '@reduxjs/toolkit'

import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftElementSelector } from '@/lib/client/redux/campaign/draft-element/DraftElementSelector'
import { getDraftElement } from '@/lib/client/redux/campaign/draft-element/selectors/common'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const checkForErrors = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    matcher: isAnyOf(
      DraftElementAction.updateTextValue,
      DraftElementAction.updateTextStyle,
      DraftElementAction.updateHTMLValue,
      DraftElementAction.deleteVariant.fulfilled
    ),
    effect: async (_, { getState, dispatch }) => {
      const element = getDraftElement<'text'>(getState())
      const submitted = DraftElementSelector.isFormSubmitted(getState())
      const hasErrors = DraftElementSelector.hasFormErrors(getState())

      if (element === undefined || !submitted || !hasErrors) {
        return
      }

      dispatch(DraftElementAction.checkForErrors(element))
    }
  })
}
