import React from 'react'

import { useDialogContext } from '@/components/shared/dialog/DialogContext'
import { DialogAction } from '@/lib/client/redux/dialog/DialogAction'
import { useDispatch } from '@/lib/client/redux/hooks'

export const useDialogActions = () => {
  const dialog = useDialogContext()
  const dispatch = useDispatch()

  return React.useMemo(
    () => ({
      hideDialog: (result?: any) => {
        if (dialog) {
          dispatch(DialogAction.hideDialog({ id: dialog.id, result }))
        }
      }
    }),
    [dialog, dispatch]
  )
}
