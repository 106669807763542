import axios from 'axios'

import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { IGenerateImagePayload } from '@/lib/common/entities/api/image'
import { registerJobHandlers } from '@/lib/common/socket/registerJobHandlers'
import { logger } from '@/lib/common/utils/logging/logger'
import { teamApiUrl } from '@/lib/common/utils/urls'
import { Response } from '@/pages/api/team/[teamSlug]/campaign/generate-image'

const log = logger('generate')

export const generateImage = async (
  payload: IGenerateImagePayload,
  handlers: {
    [key: string]: (data: any, subscription: any) => void
  }
) => {
  const team = getTeamSlug()
  const response = await axios.post<Response>(teamApiUrl(team, '/campaign/generate-image'), payload)
  const { jobId } = response.data

  registerJobHandlers(jobId, handlers)
}
