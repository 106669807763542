import { nanoid } from 'nanoid'

import { qs } from '@/lib/client/common/util/qs'
import { config } from '@/lib/common/config'
import { IQuery } from '@/lib/common/navigation/IQuery'

export const adminUrl = (path: string = '', query?: IQuery | string) => `/admin${path}${qs(query)}`
export const publicAdminUrl = (path: string = '', query?: IQuery | string) =>
  `${config.env.ADMIN_URL}/admin${path}${qs(query)}`

export const apiUrl = (path: string = '', query?: IQuery | string) => `/api${path}${qs(query)}`
export const publicApiUrl = (path: string = '', query?: IQuery | string) =>
  `${config.env.ADMIN_URL}/api${path}${qs(query)}`

export const authUrl = (path: string = '', query?: IQuery | string) =>
  `/admin/auth${path}${qs(query)}`
export const publicAuthUrl = (path: string = '', query?: IQuery | string) =>
  `${config.env.ADMIN_URL}/admin/auth${path}${qs(query)}`

export const teamUrl = (team: string, path: string = '', query?: IQuery | string) =>
  `/admin/${team}${path}${qs(query)}`
export const publicTeamUrl = (team: string, path: string = '', query?: IQuery | string) =>
  `${config.env.ADMIN_URL}/admin/${team}${path}${qs(query)}`

export const teamApiUrl = (team: string, path: string = '', query?: IQuery | string) =>
  `/api/team/${team}${path}${qs(query)}`
export const publicTeamApiUrl = (team: string, path: string = '', query?: IQuery | string) =>
  `${config.env.ADMIN_URL}/api/team/${team}${path}${qs(query)}`

export const aiUrl = (path: string, query?: IQuery | string) =>
  `${config.env.AI_API_URL}${path}${qs(query)}`

export const publicUrl = (path: string, query?: IQuery | string) =>
  `${config.env.ADMIN_URL}${path}${qs(query)}`

export const createAICallback = () => {
  const jobId = nanoid()
  const callbackUrl = publicApiUrl(`/callback/publish?jobId=${jobId}`)
  return { jobId, callbackUrl }
}
