import { Transition } from '@headlessui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { DialogContext } from '@/components/shared/dialog/DialogContext'
import { DialogAction } from '@/lib/client/redux/dialog/DialogAction'
import { DialogSelector } from '@/lib/client/redux/dialog/DialogSelector'
import { IDialog } from '@/lib/client/redux/dialog/DialogSlice'
import { useDispatch, useSelector } from '@/lib/client/redux/hooks'

export const DialogContainer: React.FC = () => {
  const dialogs = useSelector(DialogSelector.getDialogs)
  const dispatch = useDispatch()

  if (dialogs.length === 0) {
    return null
  }
  const top = findTop(dialogs)

  return (
    <div className="absolute inset-0 z-[2000]">
      {dialogs.map((dialog, index) => (
        <DialogContext.Provider key={dialog.id} value={dialog}>
          <Transition
            className="absolute inset-0 bg-dialog"
            appear
            show={index === top}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-1000"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          />
          <Transition
            className={twMerge(
              'absolute inset-0 flex items-center justify-center',
              dialog.visible ? 'opacity-100' : 'opacity-0'
            )}
            appear
            show={dialog.visible}
            enter="transform transition duration-300 ease-out"
            enterFrom="opacity-50 scale-90"
            enterTo="opacity-100 scale-100"
            leave="transform transition duration-200 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
            afterEnter={() => {
              dispatch(DialogAction.setAnimationState({ id: dialog.id, state: 'idle' }))
            }}
            beforeLeave={() => {
              dispatch(DialogAction.setAnimationState({ id: dialog.id, state: 'hide' }))
            }}
            afterLeave={() => {
              dispatch(DialogAction.removeDialog(dialog.id))
              dialog.onResult?.(dialog.result)
            }}
          >
            {dialog.element}
          </Transition>
        </DialogContext.Provider>
      ))}
    </div>
  )
}

const findTop = (dialogs: IDialog[]) => {
  let top = -1
  for (let i = 0; i < dialogs.length; i++) {
    if (dialogs[i].visible) {
      top = i
    }
  }
  return top
}
