import { createAction } from '@reduxjs/toolkit'

import { DraftElementSlice } from '@/lib/client/redux/campaign/draft-element/DraftElementSlice'
import { addManualTextVariant } from '@/lib/client/redux/campaign/draft-element/thunk/addManualTextVariant'
import { cancel } from '@/lib/client/redux/campaign/draft-element/thunk/cancel'
import { checkForErrors } from '@/lib/client/redux/campaign/draft-element/thunk/checkForErrors'
import { deleteVariant } from '@/lib/client/redux/campaign/draft-element/thunk/deleteVariant'
import { focusDraftVariant } from '@/lib/client/redux/campaign/draft-element/thunk/focusDraftVariant'
import { save } from '@/lib/client/redux/campaign/draft-element/thunk/save'
import { setDraft } from '@/lib/client/redux/campaign/draft-element/thunk/setDraft'
import { toggleImageVariant } from '@/lib/client/redux/campaign/draft-element/thunk/toggleImageVariant'
import { uploadImageVariants } from '@/lib/client/redux/campaign/draft-element/thunk/uploadImageVariants'
import { ITextAttributes, ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import { ITextVariant, ITextVariantTone } from '@/lib/common/entities/campaign/variant/ITextVariant'

export const DraftElementAction = {
  ...DraftElementSlice.actions,
  addAITextVariant: createAction<{
    tone?: ITextVariantTone
    attributes?: ITextAttributes
    element?: ITextElement
  }>('draftElement/addAITextVariant'),
  addManualTextVariant,
  suggestSimilarVariant: createAction<ITextVariant>('draftElement/generateSimilarTextVariant'),
  cancel,
  checkForErrors,
  deleteVariant,
  save,
  setDraft,
  toggleImageVariant,
  uploadImageVariants,
  focusDraftVariant
}
