import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import {
  IImageLabelStatus,
  IModifyImageService,
  IModifyImageState
} from '@/lib/client/redux/image/modify/types'
import { IGenerateImageStatus } from '@/lib/client/redux/image/types'

const initialState: IModifyImageState = {
  generateStatus: undefined,
  search: '',
  prompt: '',
  service: 'stability'
}

export const ModifyImageSlice = createSlice({
  name: 'modifyImage',
  initialState,
  reducers: {
    start: (state, _action: PayloadAction) => {
      state.generateStatus = undefined
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setPrompt: (state, action: PayloadAction<string>) => {
      state.prompt = action.payload
    },
    setLabelStatus: (state, action: PayloadAction<IImageLabelStatus>) => {
      state.labelStatus = action.payload
    },
    setGenerateStatus: (state, action: PayloadAction<IGenerateImageStatus>) => {
      state.generateStatus = action.payload
    },
    setService: (state, action: PayloadAction<IModifyImageService>) => {
      state.service = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(DraftElementAction.setDraft.fulfilled, state => {
      state.generateStatus = undefined
      state.prompt = ''
      state.search = ''
      state.labelStatus = undefined
    })
  }
})
