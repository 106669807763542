import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

interface Args {
  elementId: number
  search?: string
}

export const searchImage = createAsyncThunk<void, Args, IThunkConfig>(
  'editCampaign/searchImage',
  async ({ elementId, search }, { getState, dispatch }) => {
    const searchImages = EditCampaignSelector.getSearchImages(getState())
    if (search === undefined && searchImages[elementId] === undefined) {
      search = '' // await getSearch(element.control.src) // add back when classification gets better
    }
    dispatch(EditCampaignAction.setSearchImage({ elementId, search }))
  }
)
