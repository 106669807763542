import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

export const navigateStep = createAsyncThunk<void, CampaignStep, IThunkConfig>(
  'editCampaign/navigateStep',
  async (step, { dispatch }) => {
    switch (step) {
      case CampaignStep.ChooseURL:
        await dispatch(EditCampaignAction.setStepChooseURL())
        break
      case CampaignStep.ClosePopups:
        await dispatch(EditCampaignAction.setStepClosePopups())
        break
      case CampaignStep.EditElements:
        await dispatch(EditCampaignAction.setStepEditElements())
        break
      case CampaignStep.EditGoals:
        await dispatch(EditCampaignAction.setStepEditGoals())
        break
      case CampaignStep.CampaignTypeSelection:
        await dispatch(EditCampaignAction.setStepCampaignTypeSelection())
        break
      case CampaignStep.Settings:
        await dispatch(EditCampaignAction.setStepSettings())
        break
    }
  }
)
