import { toHSLA } from './toHSLA'

export const sortColors = (colors: number[]) => {
  const converted = colors.map(color => ({
    color,
    hsla: toHSLA(color)
  }))
  converted.sort((a, b) => {
    if ((a.hsla.s === 0 || b.hsla.s === 0) && a.hsla.s !== b.hsla.s) {
      return b.hsla.s - a.hsla.s
    }
    if (a.hsla.h !== b.hsla.h) {
      return a.hsla.h - b.hsla.h
    }
    if (a.hsla.s !== b.hsla.s) {
      return a.hsla.s - b.hsla.s
    }
    if (a.hsla.s === 0 && b.hsla.s === 0) {
      if (a.hsla.l !== b.hsla.l) {
        return b.hsla.l - a.hsla.l
      }
    }
    return b.hsla.a - a.hsla.a
  })
  return converted.map(c => c.color)
}
