import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('StorageApi')

export enum TagId {
  All = '__all__'
}

export const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: headers => {
    headers.set('Content-Type', 'application/json')
    return headers
  }
})

export const baseApi = createApi({
  baseQuery,
  tagTypes: [
    'audience',
    'campaign',
    'campaign-stats',
    'checklist',
    'concierge',
    'design',
    'domain',
    'experiment',
    'media',
    'page',
    'team',
    'user',
    'variable',
    'notification',
    'url-context',
    'admin',
    'url'
  ],
  keepUnusedDataFor: 3600,
  endpoints: () => ({})
})
