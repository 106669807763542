import axios from 'axios'

import { IGenerateTextData } from '@/lib/client/common/api/text/types'
import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { logger } from '@/lib/common/utils/logging/logger'
import { teamApiUrl } from '@/lib/common/utils/urls'
import { IGenerateTextPayload } from '@/lib/server/data/campaign/generateTextVariants'
import { Response } from '@/pages/api/team/[teamSlug]/campaign/generate-text'

const log = logger('generate')

export const generateText = async (
  data: IGenerateTextData,
  generateCount: number,
  debug?: boolean
) => {
  const { element, options } = data
  const payload: IGenerateTextPayload = {
    type: element.textType,
    meta: element.meta,
    value: options.value,
    count: generateCount,
    additional: options.additional,
    tone: options.tone,
    currentVariants: data.variants ?? [],
    styles: options.styles,
    debug
  }

  const team = getTeamSlug()
  const response = await axios.post<Response>(teamApiUrl(team, '/campaign/generate-text'), payload)
  return response.data
}
