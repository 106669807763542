import * as yup from 'yup'
import { AnyObject } from 'yup'

import { IFormErrors } from '@/lib/client/redux/form/types'

export type IReduxValidator = ReturnType<typeof reduxValidator>

export const reduxValidator =
  (schema: yup.ObjectSchema<any>) =>
  async (id: string | undefined, state: any, context: any, errors: IFormErrors) => {
    if (id && schema.tests.length === 0) {
      const result = { ...errors }
      await validateOne(schema, state, context, result, id as string)
      return result
    } else {
      return await validateAll(schema, state, context)
    }
  }

const validateOne = async <T, S extends AnyObject>(
  schema: yup.ObjectSchema<any>,
  values: T,
  context: S,
  errors: IFormErrors,
  key: string
) => {
  try {
    await schema.validateAt(key, values, {
      abortEarly: false,
      context
    })
    delete errors[key]
  } catch (error: any) {
    const message: string | undefined = Array.isArray(error.inner)
      ? error.inner[0].message
      : undefined
    if (message) {
      errors[key] = {
        type: 'redux-validate',
        message
      }
    } else {
      delete errors[key]
    }
  }
}
const validateAll = async <T, S extends AnyObject>(
  schema: yup.ObjectSchema<any>,
  values: T,
  context: S
) => {
  try {
    await schema.validate(values, {
      abortEarly: false,
      context
    })
    return {}
  } catch (error: any) {
    const errors: IFormErrors = {}
    if (Array.isArray(error.inner)) {
      for (const err of error.inner) {
        const { path, type, message } = err
        const name = path || type
        errors[name] = {
          type: 'redux-validate',
          message
        }
      }
    }
    return errors
  }
}
