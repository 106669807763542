import { isAnyOf } from '@reduxjs/toolkit'

import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const clearErrors = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    matcher: isAnyOf(
      EditCampaignAction.generateInitialTextVariants,
      EditCampaignAction.generateSuggestedVariants,
      EditCampaignAction.suggestSimilarVariant
    ),
    effect: async (action, { getState }) => {
      const { info } = EditCampaignSelector.getState(getState())
      const errors = info.elementErrors

      let elementId = null as number | null

      if (isGenerateInitialTextVariantsAction(action)) {
        elementId = action.payload.id
      } else if (isGenerateSuggestedVariantsAction(action)) {
        elementId = action.payload.id
      } else if (isSuggestSimilarVariantAction(action)) {
        elementId = action.payload.element.id
      }

      if (!elementId) return

      if (errors[elementId]) {
        const newErrors = { ...errors }
        delete newErrors[elementId]
      }
    }
  })
}

function isGenerateInitialTextVariantsAction(
  action: any
): action is ReturnType<typeof EditCampaignAction.generateInitialTextVariants> {
  return action.type === EditCampaignAction.generateInitialTextVariants.type
}

function isGenerateSuggestedVariantsAction(
  action: any
): action is ReturnType<typeof EditCampaignAction.generateSuggestedVariants> {
  return action.type === EditCampaignAction.generateSuggestedVariants.type
}

function isSuggestSimilarVariantAction(
  action: any
): action is ReturnType<typeof EditCampaignAction.suggestSimilarVariant> {
  return action.type === EditCampaignAction.suggestSimilarVariant.type
}
