import { EditCampaignSlice } from '@/lib/client/redux/campaign/edit/EditCampaignSlice'
import { createFormSelectors } from '@/lib/client/redux/form/createFormSelectors'

import * as common from './selectors/common'
import * as preview from './selectors/preview'

export const EditCampaignSelector = {
  ...common,
  ...preview,
  ...createFormSelectors(EditCampaignSlice)
}
