import { WithRouterProps } from 'next/dist/client/with-router'
import Image from 'next/image'
import Link from 'next/link'
import { withRouter } from 'next/router'
import React, { ErrorInfo } from 'react'

import { config } from '@/lib/common/config'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('component.RootErrorBoundary')

interface State {
  hasError: boolean
}

class Component extends React.Component<WithRouterProps, State> {
  constructor(props: WithRouterProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.error(error, this.props.router.asPath, errorInfo.componentStack)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center w-screen h-screen min-w-sm">
          <div className="flex">
            <Link className="flex items-center cursor-pointer" href={config.env.MARKETING_URL}>
              <div className="relative">
                <Image src="/images/logo/logo.svg" width={96} height={48} alt={config.env.BRAND} />
              </div>
            </Link>
          </div>
          <div className="mt-12 text-3xl">Something went wrong</div>
        </div>
      )
    }

    return this.props.children
  }
}

export const RootErrorBoundary = withRouter(Component)
