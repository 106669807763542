import {
  ActionCreatorWithPayload,
  ActionCreatorWithPreparedPayload,
  AsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit'
import { UnknownAction } from 'redux'

import { IThunkConfig } from '@/lib/client/redux/types'

export const isAction = <T>(
  action: UnknownAction,
  creator: ActionCreatorWithPayload<T>
): action is ReturnType<ActionCreatorWithPayload<T>> => action.type === creator.type

export const isPreparedAction = <Args extends unknown[], P, T extends string, E, M>(
  action: any,
  creator: ActionCreatorWithPreparedPayload<Args, P, T, E, M>
): action is ReturnType<ActionCreatorWithPreparedPayload<Args, P, T, E, M>> =>
  action.type === creator.type

export const isThunk = <Return, ThunkArg>(
  action: UnknownAction,
  creator: AsyncThunk<Return, ThunkArg, IThunkConfig>
): action is PayloadAction<ThunkArg> => action.type === creator.fulfilled.type
