import { PayloadAction } from '@reduxjs/toolkit'

import { IGenerateTextData, IGenerateTextOptions } from '@/lib/client/common/api/text/types'
import { IEditCampaignState, IVariantPool } from '@/lib/client/redux/campaign/edit/types'

export const validateVariantPool = (
  state: IEditCampaignState,
  action: PayloadAction<IGenerateTextData>
) => {
  const data = action.payload
  const { variantPool } = state.info

  const pool = variantPool[data.element.id]
  if (pool && !isPoolValid(pool, data)) {
    pool.available = []
  }
}

const isPoolValid = (pool: IVariantPool, item: IGenerateTextData) => {
  return getIsPoolValidJSON(pool.options) === getIsPoolValidJSON(item.options)
}

const getIsPoolValidJSON = (options: IGenerateTextOptions) => {
  return JSON.stringify({
    value: options.value,
    styles: options.styles,
    tone: options.tone,
    objective: options.additional
  })
}
