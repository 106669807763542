import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IGenerateImageService, IGenerateImageState } from '@/lib/client/redux/image/generate/types'
import { IGenerateImageStatus } from '@/lib/client/redux/image/types'
import { IGenerateImageType } from '@/lib/common/entities/api/image'

const initialState: IGenerateImageState = {
  prompt: '',
  status: undefined,
  categoriesVisible: true,
  service: 'midjourney',
  type: 'photo'
}

export const GenerateImageSlice = createSlice({
  name: 'generateImage',
  initialState,
  reducers: {
    start: (state, _action: PayloadAction) => {
      state.status = undefined
    },
    setPrompt: (state, action: PayloadAction<string>) => {
      state.prompt = action.payload
    },
    setStatus: (state, action: PayloadAction<IGenerateImageStatus>) => {
      state.status = action.payload
    },
    setCategoriesVisible: (state, action: PayloadAction<boolean>) => {
      state.categoriesVisible = action.payload
    },
    setType: (state, action: PayloadAction<IGenerateImageType>) => {
      if (state.type !== action.payload) {
        state.type = action.payload
      } else {
        state.type = undefined
      }
    },
    setService: (state, action: PayloadAction<IGenerateImageService>) => {
      state.service = action.payload
    }
  }
})
