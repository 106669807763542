import inspect from 'object-inspect'

export class BaseLogger {
  getMessage(data: any[]) {
    const value = data?.[0]
    if (value === undefined) {
      return 'undefined'
    }
    if (value === null) {
      return 'null'
    }
    if (value instanceof Error) {
      return value.message
    }
    return value.toString()
  }

  format(value: any) {
    if (value instanceof Error) {
      return value.stack
    }
    if (value?.toJSON) {
      return JSON.stringify(value, null, 2)
    }
    return inspect(value, { maxStringLength: 1000, indent: '\t', depth: 10 })
  }
}
