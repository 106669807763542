import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface IMenuState {
  collapsed: boolean
}

const initialState: IMenuState = {
  collapsed: false
}

export const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setCollapsed: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload
    }
  }
})
