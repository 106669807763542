import * as yup from 'yup'

import { reduxValidator } from '@/components/shared/form/reduxValidator'
import { config } from '@/lib/common/config'
import { testURL } from '@/lib/common/utils/testURL'

export const validateEditCampaign = reduxValidator(
  yup.object().shape({
    ingestedUrl: yup
      .string()
      .required('Please enter a URL')
      .test('url', 'Please check that you have entered a valid URL', testURL),
    name: yup
      .string()
      .required('Please enter a name')
      .min(3, 'Name must be at least 3 characters')
      .max(
        config.campaign.nameMaxLength,
        `name must be at most ${config.campaign.nameMaxLength} characters`
      ),
    description: yup
      .string()
      .max(
        config.campaign.descriptionMaxLength,
        `Description must be at most ${config.campaign.descriptionMaxLength} characters`
      )
      .nullable(),
    inviteeEmail: yup.string().email('Please enter a valid email address'),
    elements: yup.array().of(yup.object().required())
    // goals: yup.array().of( // todo - spencer
    //   yup.object({
    //     url: yup
    //       .string()
    //       .url('Please enter a valid URL such as https://www.example.com')
    //       .required('Please enter a valid URL'),
    //     label: yup
    //       .string()
    //       .required('Please enter a CTA text')
    //       .min(3, 'CTA text must be at least 3 characters')
    //       .max(50, 'CTA text must be at most 50 characters')
    //   })
    // )
  })
)
