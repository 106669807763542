import { Stripe, loadStripe } from '@stripe/stripe-js'

import { config } from '@/lib/common/config'

let stripe: Promise<Stripe | null>
export const getStripe = () => {
  if (!stripe) {
    stripe = loadStripe(config.env.STRIPE_PUBLISHABLEKEY)
  }
  return stripe
}
