import axios from 'axios'

import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

export const validateImageURL = createAsyncThunk<boolean, string, IThunkConfig>(
  'editCampaign/validateImageURL',
  async src => {
    try {
      const team = getTeamSlug()
      const { data } = await axios.get(
        `/api/team/${team}/campaign/is-image?url=${encodeURIComponent(src)}`
      )
      return data.result as boolean
    } catch {
      return false
    }
  }
)
