/*
Takes in a url and return the complete url in the form of https://example.com or http://example.com

google.com -> https://google.com
http://google.com -> http://google.com
https://google.com -> https://google.com
google.com/path -> https://google.com/path
http://google.com/path -> http://google.com/path
https://google.com/path -> https://google.com/path
https://admin.google.com/path/ -> https://admin.google.com/path/
*/

export const formatIngestedUrl = (ingestedUrl: string): string => {
  if (!ingestedUrl) {
    return ''
  }
  if (ingestedUrl.startsWith('https://') || ingestedUrl.startsWith('http://')) {
    return ingestedUrl.trim()
  } else {
    // must be example.com or example.com/path
    return 'https://' + ingestedUrl.trim()
  }
}
