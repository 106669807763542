import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { CampaignApi } from '@/lib/client/redux/campaign/CampaignApi'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { PreviewAction } from '@/lib/client/redux/preview/PreviewAction'
import { PreviewSelector } from '@/lib/client/redux/preview/PreviewSelector'
import { IURLPreviewEvent } from '@/lib/client/redux/preview/types'
import { IThunkConfig } from '@/lib/client/redux/types'

export const sendEvent = createAsyncThunk<void, IURLPreviewEvent, IThunkConfig>(
  'preview/sendEvent',
  async (ev, { dispatch, getState }) => {
    dispatch(PreviewAction.processEvent(ev))
    if (ev.type === 'error') {
      const url = PreviewSelector.getUrl(getState())
      const errorCount = PreviewSelector.getErrorCount(getState())
      if (url && errorCount === 2) {
        CampaignApi.endpoints.logPreviewError.initiate({
          team: getTeamSlug(),
          payload: { url }
        })
      }
    }
  }
)
