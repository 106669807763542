import { IGenerateTextData, IGenerateTextOptions } from '@/lib/client/common/api/text/types'
import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftElementSelector } from '@/lib/client/redux/campaign/draft-element/DraftElementSelector'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { getEditMode } from '@/lib/client/redux/campaign/edit/selectors/common'
import { generateTextVariants } from '@/lib/client/redux/campaign/util/generateTextVariants'
import { getStyles } from '@/lib/client/redux/campaign/util/getStyles'
import { populateVariantPool } from '@/lib/client/redux/campaign/util/populateVariantPool'
import { IListenersMiddleware } from '@/lib/client/redux/types'
import { ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { assertDefined } from '@/lib/common/guards/assertDefined'
import { getTopPerformingVariant } from '@/lib/common/stats/getTopPerformingVariant'

export const addAITextVariant = (middleware: IListenersMiddleware) =>
  middleware.startListening({
    actionCreator: DraftElementAction.addAITextVariant,
    effect: async (action, listenerApi) => {
      const { dispatch, getState } = listenerApi

      const mode = getEditMode(getState())

      let element: ITextElement | undefined = undefined

      if (mode === 'create') {
        element = action.payload.element
      } else if (mode === 'update') {
        element = DraftElementSelector.getDraftElement<'text'>(getState())
      }

      assertDefined(element, 'draftElement is undefined')

      const { tone, attributes = element.attributes } = action.payload
      const topPerformingVariant = getTopPerformingVariant(element)
      const value = topPerformingVariant ?? element.control?.info.value
      const options: IGenerateTextOptions = {
        value: attributes.text ? value : undefined,
        tone,
        additional: element.objective,
        styles: getStyles(element, attributes)
      }
      const position = 'start'
      const data: IGenerateTextData = {
        url: EditCampaignSelector.getIngestedUrl(getState()),
        element,
        options,
        count: 1,
        position,
        type: mode === 'create' ? NewVariantType.Suggested : NewVariantType.Draft
      }
      const variants = await generateTextVariants(data, listenerApi)
      if (mode === 'create') {
        dispatch(EditCampaignAction.addVariantsToElement({ element, variants, position }))
      } else if (mode === 'update') {
        dispatch(DraftElementAction.addVariants({ variants, position }))
      }

      await populateVariantPool({ ...data, count: 10 }, listenerApi)
    }
  })
