import { IGenerateTextData, IGenerateTextOptions } from '@/lib/client/common/api/text/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { generateTextVariants } from '@/lib/client/redux/campaign/util/generateTextVariants'
import { getStyles } from '@/lib/client/redux/campaign/util/getStyles'
import { populateVariantPool } from '@/lib/client/redux/campaign/util/populateVariantPool'
import { IListenersMiddleware } from '@/lib/client/redux/types'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'

export const generateInitialTextVariants = (middleware: IListenersMiddleware) =>
  middleware.startListening({
    actionCreator: EditCampaignAction.generateInitialTextVariants,
    effect: async (action, listenerApi) => {
      const { dispatch, getState } = listenerApi

      const url = EditCampaignSelector.getIngestedUrl(getState())
      const element = action.payload
      const attributes = element.attributes
      const options: IGenerateTextOptions = {
        value: attributes.text ? element.control?.info.value : undefined,
        tone: undefined,
        additional: element.objective,
        styles: getStyles(element, element.attributes)
      }
      const position = 'start'
      const data: IGenerateTextData = {
        url,
        element,
        options,
        count: 3,
        position,
        type: NewVariantType.Suggested
      }
      const variants = await generateTextVariants(data, listenerApi)
      dispatch(EditCampaignAction.addVariantsToElement({ element, variants, position }))

      await populateVariantPool({ ...data, count: 10 }, listenerApi)
    }
  })
