import { createAction } from '@reduxjs/toolkit'

import { IGenerateTextData } from '@/lib/client/common/api/text/types'
import { EditCampaignSlice } from '@/lib/client/redux/campaign/edit/EditCampaignSlice'
import { createCampaignScreenshot } from '@/lib/client/redux/campaign/edit/thunk/campaign/createCampaignScreenshot'
import { deleteServerVariant } from '@/lib/client/redux/campaign/edit/thunk/campaign/deleteServerVariant'
import { initCreateCampaign } from '@/lib/client/redux/campaign/edit/thunk/campaign/initCreateCampaign'
import { initUpdateCampaign } from '@/lib/client/redux/campaign/edit/thunk/campaign/initUpdateCampaign'
import { navigateStep } from '@/lib/client/redux/campaign/edit/thunk/campaign/navigateStep'
import { setStepCampaignTypeSelection } from '@/lib/client/redux/campaign/edit/thunk/campaign/setStepCampaignTypeSelection'
import { setStepChooseURL } from '@/lib/client/redux/campaign/edit/thunk/campaign/setStepChooseURL'
import { setStepClosePopups } from '@/lib/client/redux/campaign/edit/thunk/campaign/setStepClosePopups'
import { setStepEditElements } from '@/lib/client/redux/campaign/edit/thunk/campaign/setStepEditElements'
import { setStepEditGoals } from '@/lib/client/redux/campaign/edit/thunk/campaign/setStepEditGoals'
import { setStepSettings } from '@/lib/client/redux/campaign/edit/thunk/campaign/setStepSettings'
import { submitForm } from '@/lib/client/redux/campaign/edit/thunk/campaign/submitForm'
import { createElement } from '@/lib/client/redux/campaign/edit/thunk/element/createElement'
import { deleteElement } from '@/lib/client/redux/campaign/edit/thunk/element/deleteElement'
import { searchImage } from '@/lib/client/redux/campaign/edit/thunk/element/searchImage'
import { selectElement } from '@/lib/client/redux/campaign/edit/thunk/element/selectElement'
import { approveSuggestedVariant } from '@/lib/client/redux/campaign/edit/thunk/variant/approveSuggestedVariant'
import { consumeVariantsFromPool } from '@/lib/client/redux/campaign/edit/thunk/variant/consumeVariantsFromPool'
import { duplicateVariant } from '@/lib/client/redux/campaign/edit/thunk/variant/duplicateVariant'
import { editVariant } from '@/lib/client/redux/campaign/edit/thunk/variant/editVariant'
import { generateSuggestedImageVariants } from '@/lib/client/redux/campaign/edit/thunk/variant/generateSuggestedImageVariants'
import { setVariants } from '@/lib/client/redux/campaign/edit/thunk/variant/setVariants'
import { updateVariantEnabled } from '@/lib/client/redux/campaign/edit/thunk/variant/updateVariantEnabled'
import { validateImageURL } from '@/lib/client/redux/campaign/edit/thunk/variant/validateImageURL'
import { ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import { ICampaign } from '@/lib/common/entities/campaign/ICampaign'
import { ITextVariant } from '@/lib/common/entities/campaign/variant/ITextVariant'

export const EditCampaignAction = {
  ...EditCampaignSlice.actions,
  generateInitialTextVariants: createAction<ITextElement>(
    'editCampaign/generateInitialTextVariants'
  ),
  generateSuggestedVariants: createAction<ICampaign>('editCampaign/generateSuggestedVariants'),
  suggestSimilarVariant: createAction<{ source: ITextVariant; element: ITextElement }>(
    'editCampaign/suggestSimilarVariant'
  ),
  populateVariantPool: createAction<IGenerateTextData>('editCampaign/populateVariantPool'),
  createCampaignScreenshot,
  navigateStep,
  setStepChooseURL,
  setStepClosePopups,
  setStepEditElements,
  setStepEditGoals,
  setStepCampaignTypeSelection,
  setStepSettings,
  createElement,
  deleteElement,
  searchImage,
  selectElement,
  validateImageURL,
  submitForm,
  deleteServerVariant,
  updateVariantEnabled,
  approveSuggestedVariant,
  editVariant,
  duplicateVariant,
  setVariants,
  consumeVariantsFromPool,
  initCreateCampaign,
  initUpdateCampaign,
  generateSuggestedImageVariants
}
