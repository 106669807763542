import { IImageVariantInfoUpdate } from '@/lib/client/redux/campaign/edit/types'
import { IGenerateImage } from '@/lib/common/entities/api/image'
import {
  IImageVariant,
  IImageVariantInfo
} from '@/lib/common/entities/campaign/variant/IImageVariant'

type ICreate = (image: IGenerateImage | undefined) => Partial<IImageVariantInfo>

export function createImageVariantInfoUpdates(
  variants: IImageVariant[],
  images: IGenerateImage[] | undefined,
  create: ICreate
): IImageVariantInfoUpdate[] {
  const imageMap = new Map(images ? images.map(image => [image.id, image]) : [])
  return variants.map(variant => ({
    variantId: variant.id,
    info: create(imageMap.get(variant.id))
  }))
}
