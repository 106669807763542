import { configureStore } from '@reduxjs/toolkit'

import { baseApi } from '@/lib/client/redux/common/api'
import { createListeners } from '@/lib/client/redux/common/createListeners'
import { ImageApi } from '@/lib/client/redux/common/image/ImageApi'
import { rootReducer } from '@/lib/client/redux/common/rootReducer'
import { StorageApi } from '@/lib/client/redux/common/storage/StorageApi'
import { reduxLogger } from '@/lib/common/utils/logging/reduxLogger'

const middlewares = [baseApi.middleware, StorageApi.middleware, ImageApi.middleware, reduxLogger]
const listenerMiddleware = createListeners()

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .prepend(listenerMiddleware.middleware)
      .concat(middlewares)
})
2
