import { IGenerateTextData, IGenerateTextOptions } from '@/lib/client/common/api/text/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { generateTextVariants } from '@/lib/client/redux/campaign/util/generateTextVariants'
import { getStyles } from '@/lib/client/redux/campaign/util/getStyles'
import { IListenersMiddleware } from '@/lib/client/redux/types'
import { ICampaign } from '@/lib/common/entities/campaign/ICampaign'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'

export const generateSuggestedVariants = (middleware: IListenersMiddleware) =>
  middleware.startListening({
    actionCreator: EditCampaignAction.generateSuggestedVariants,
    effect: async (action, listenerApi) => {
      const { dispatch } = listenerApi
      const campaign = action.payload

      const elementsNeedingSuggestions = getElementsNeedingSuggestions(campaign)

      await Promise.all(
        campaign.elements.map(async element => {
          if (elementsNeedingSuggestions.includes(element.id)) {
            if (element.type === 'text') {
              const attributes = element.attributes
              const options: IGenerateTextOptions = {
                value: attributes.text ? element.control?.info.value : undefined,
                tone: undefined,
                styles: getStyles(element, element.attributes)
              }
              const position = 'start'
              const data: IGenerateTextData = {
                url: campaign.ingestedUrl,
                element,
                options,
                count: 3,
                position,
                type: NewVariantType.Suggested
              }
              const variants = await generateTextVariants(data, listenerApi)
              dispatch(EditCampaignAction.addVariantsToElement({ element, variants, position }))
            } else if (element.type === 'image') {
              dispatch(EditCampaignAction.generateSuggestedImageVariants(element))
            }
          }
        })
      )
    }
  })

const getElementsNeedingSuggestions = (campaign: ICampaign) => {
  const { autoGenerationStatus } = campaign

  let elementsNeedingSuggestions: number[] = []

  if (
    autoGenerationStatus &&
    autoGenerationStatus.autoGenerationRequired &&
    autoGenerationStatus.reason.type === 'winners'
  ) {
    elementsNeedingSuggestions = Object.values(autoGenerationStatus.reason.elements)
      .filter(status => status.type === 'decisive')
      .map(status => status.element)
  }

  return elementsNeedingSuggestions
}
