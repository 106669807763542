export enum TrackEventType {
  Unknown = 0,
  PageView = 1,
  Click = 2
}

export const formatEventName = (eventName: TrackEventType) => {
  switch (eventName) {
    case TrackEventType.PageView:
      return 'Page View'
    case TrackEventType.Click:
      return 'Click'
    default:
      return 'Unknown'
  }
}
