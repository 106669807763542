import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

export const setStepSettings = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/setStepSettings',
  async (_, { dispatch }) => {
    dispatch(EditCampaignAction.setStep(CampaignStep.Settings))
  }
)
