import { IFormState } from '@/lib/client/redux/form/types'

export const createInitialFormState = <Form extends object>(values: Form): IFormState<Form> => ({
  values,
  meta: {
    isSubmitting: false,
    hasFormInput: false,
    submitCount: 0
  },
  errors: {}
})
