import { config } from '@/lib/common/config'
import { ISubscription } from '@/lib/common/entities/ISubscription'

export const isOnPaid = (subscription?: ISubscription) => {
  return Boolean(
    subscription &&
      subscription.status === 'active' &&
      (subscription?.periodEndsAt || Number.MIN_SAFE_INTEGER) > Math.floor(Date.now() / 1000)
  )
}

export const isOnTrial = (subscription?: ISubscription) => {
  return Boolean(
    subscription &&
      subscription.status === 'trialing' &&
      (subscription?.trialEndsAt || Number.MIN_SAFE_INTEGER) > Math.floor(Date.now() / 1000)
  )
}

export const isTrialPeriodEnded = (subscription?: ISubscription) => {
  return Boolean(
    subscription &&
      subscription.status === 'trialing' &&
      (subscription?.trialEndsAt || Number.MAX_SAFE_INTEGER) < Math.floor(Date.now() / 1000)
  )
}

export const isSubscriptionPeriodEnded = (subscription?: ISubscription) => {
  return Boolean(
    subscription && (subscription.status === 'past_due' || subscription.status === 'canceled')
  )
}

export const isSubscriptionCanceled = (subscription?: ISubscription) => {
  return Boolean(
    subscription &&
      (subscription.status === 'canceled' || subscription.status === 'incomplete_expired')
  )
}

export const getSubscriptionTier = (subscription?: ISubscription) => {
  if (subscription && subscription.productId === config.billing.businessPlan.productId) {
    return config.billing.businessPlan
  }

  if (subscription && subscription.productId === config.billing.standardPlan.productId) {
    return config.billing.standardPlan
  }

  if (subscription && subscription.productId === config.billing.starterPlan.productId) {
    return config.billing.starterPlan
  }

  return null
}
