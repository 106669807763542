import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

type IModifier = (url: URLSearchParams, as: URLSearchParams) => void

export const modifyQuery = createAsyncThunk<void, IModifier, IThunkConfig>(
  'location/modifyQuery',
  async modifier => {
    const state = window.history.state
    const url = new URL(window.history.state.url, window.location.href)
    const as = new URL(window.history.state.as, window.location.href)
    modifier(url.searchParams, as.searchParams)
    window.history.replaceState(
      {
        ...state,
        url: url.pathname + url.search,
        as: as.pathname + as.search
      },
      '',
      as.pathname + as.search
    )
  }
)
