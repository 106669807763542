import { createApi } from '@reduxjs/toolkit/query/react'

import { IStorageKey } from '@/lib/client/common/entities/IStorageKey'
import { localForageBaseQuery } from '@/lib/client/redux/common/storage/localForageBaseQuery'

export const StorageApi = createApi({
  baseQuery: localForageBaseQuery,
  keepUnusedDataFor: 3600,
  reducerPath: 'storageApi',
  tagTypes: ['draft', 'dashboard'],
  endpoints: build => ({
    getStorage: build.query<any, IStorageKey>({
      query: key => ({
        method: 'get',
        key
      })
    }),
    setStorage: build.mutation<void, { key: IStorageKey; value: any }>({
      query: ({ key, value }) => {
        return {
          method: 'set',
          key,
          value
        }
      },
      async onQueryStarted({ key, value }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          StorageApi.util.updateQueryData('getStorage', key, () => value ?? null)
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  })
})
