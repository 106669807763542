import { createAction } from '@reduxjs/toolkit'

import { PreviewSlice } from '@/lib/client/redux/preview/PreviewSlice'
import { sendEvent } from '@/lib/client/redux/preview/thunks/sendEvent'
import { IURLPreviewAction } from '@/lib/client/redux/preview/types'

export const PreviewAction = {
  ...PreviewSlice.actions,
  sendAction: createAction<IURLPreviewAction>('preview/sendAction'),
  sendEvent,
  processContext: createAction<string>('preview/processContext')
}
