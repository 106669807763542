import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AnimationId, IAnimationState } from '@/lib/client/redux/animation/types'

const initialState: IAnimationState = {}

export const AnimationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    setAnimationStart: (state, action: PayloadAction<AnimationId>) => {
      state[action.payload] = true
    },
    setAnimationEnd: (state, action: PayloadAction<AnimationId>) => {
      delete state[action.payload]
    }
  }
})
