import { BaseQueryFn } from '@reduxjs/toolkit/query'

import { IImageInfo, QueryReturnValue } from '@/lib/client/redux/common/types'

type Return = QueryReturnValue<IImageInfo, string | undefined, object>

export const imageQuery: BaseQueryFn = async (url: string | undefined): Promise<Return> => {
  return new Promise<Return>(resolve => {
    if (url === undefined) {
      resolve({ error: 'url is undefined' })
      return
    }
    const img = new Image()
    img.onload = ev => {
      const el = ev.target as HTMLImageElement
      resolve({
        data: {
          url,
          dimensions: {
            width: el.naturalWidth,
            height: el.naturalHeight
          }
        }
      })
    }
    img.onerror = () =>
      resolve({
        error: 'Could not get image info'
      })
    img.src = url
  })
}
