import { ILogger } from '@/lib/common/utils/logging/ILogger'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('redux')

export const reduxLogger = () => (next: any) => (action: any) => {
  if (action.type.startsWith('storageApi/')) {
    log.execute(action, logStorage, 'redux.storage')
  } else if (action.type.startsWith('api/')) {
    log.execute(action, logApi, 'redux.api')
  } else {
    log.execute(action, logAction, 'redux.action')
  }
  return next(action)
}

function logStorage(log: ILogger, category: string, action: any) {
  const { error, meta, payload } = action
  const endpoint = action.meta?.arg?.endpointName
  let args = meta?.arg?.originalArgs
  if (args?.url) {
    args = args.url
  } else if (args?.key) {
    args = args.key.type + ', ' + args.key.id
  } else if (args?.type) {
    args = args.type + ', ' + args.id
  }
  if (action.type.endsWith('fulfilled')) {
    log.info(category, `${endpoint}(${args})`, payload)
  } else if (action.type.endsWith('rejected') && error.name !== 'ConditionError') {
    log.error(category, `${endpoint}(${args})`, error)
  }
}

function logApi(log: ILogger, category: string, action: any) {
  const { error, meta, payload } = action
  const endpoint = action.meta?.arg?.endpointName
  const args = meta?.arg?.originalArgs
  if (action.type.endsWith('fulfilled')) {
    log.info(category, endpoint, args, payload)
  } else if (
    action.type.endsWith('rejected') &&
    error.name !== 'ConditionError' &&
    action.payload?.status !== 401
  ) {
    log.error(category, endpoint, args, payload)
  }
}

function logAction(log: ILogger, category: string, action: any) {
  if (action.error) {
    log.error(category, action.type, action.error.message, action.error.stack)
  } else if (action.meta) {
    log.info(category, action.type, action.meta.arg)
  } else {
    log.info(category, action.type, action.payload)
  }
}
