import { ICampaignElementType } from '@/lib/common/entities/campaign/element/ICampaignElementType'
import { ICampaignBenchmark } from '@/lib/common/entities/campaign/ICampaignBenchmark'
import { IClientMetrics, IMetrics } from '@/lib/common/entities/campaign/IMetrics'

export interface IServerBaseVariant<T extends ICampaignElementType, S> {
  id: number
  key: string
  elementId: number
  metrics: IServerVariantMetrics
  isControl: boolean
  isEnabled: boolean
  creationDate: number
  type: T
  benchmark: ICampaignBenchmark
  info: S
}

export interface IServerVariantMetrics extends IMetrics {
  revenueSamples: number
  revenueBeta: number
}

export interface IClientBaseVariant<T extends ICampaignElementType, S>
  extends Omit<IServerBaseVariant<T, S>, 'metrics'> {
  draft?: boolean
  metrics: IClientMetrics
  index: number
}

export interface INewBaseVariant<T extends ICampaignElementType, S> {
  // without this typeguards are not working properly, as without __type__ INew is a subset of IClient
  // and being able to properly distinguish is very helpful, so let's make sure we can properly
  // type check
  __type__: INewVariantType
  id: number
  creationDate?: number
  key?: string
  type: T
  info: S
}

export interface IDraftBaseVariant<T extends ICampaignElementType, S>
  extends INewBaseVariant<T, S> {
  __type__: NewVariantType.Draft
}

export interface ISuggestedBaseVariant<T extends ICampaignElementType, S>
  extends INewBaseVariant<T, S> {
  __type__: NewVariantType.Suggested
}

export const enum NewVariantType {
  Draft = 'draft',
  Suggested = 'suggested'
}

export type INewVariantType = NewVariantType
