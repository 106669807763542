import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import {
  ICampaignVariant,
  IClientCampaignVariant,
  INewCampaignVariant,
  ISuggestedCampaignVariant
} from '@/lib/common/entities/campaign/variant/ICampaignVariant'

export const isNewVariant = (
  variant: ICampaignVariant | undefined
): variant is INewCampaignVariant =>
  variant !== undefined && '__type__' in variant && variant.__type__ === NewVariantType.Draft

export const isSuggestedVariant = (
  variant: ICampaignVariant | undefined
): variant is ISuggestedCampaignVariant =>
  variant !== undefined && '__type__' in variant && variant.__type__ === NewVariantType.Suggested

export const isClientVariant = (
  variant: ICampaignVariant | undefined
): variant is IClientCampaignVariant => variant !== undefined && !('__type__' in variant)
