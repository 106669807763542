import { BaseLogger } from '@/lib/common/utils/logging/BaseLogger'
import { ILogger } from '@/lib/common/utils/logging/ILogger'

/* eslint-disable no-console */

export class LogtailLogger extends BaseLogger implements ILogger {
  private readonly logger: any
  private readonly type: string

  constructor(token: string) {
    super()
    if (typeof window === 'undefined') {
      const { Logtail } = require('@logtail/node')
      this.logger = new Logtail(token)
      this.type = 'node'
    } else {
      const { Logtail } = require('@logtail/browser')
      this.logger = new Logtail(token)
      this.type = 'browser'
    }
  }

  async info(category: string, ...data: any[]) {
    const info: any = {
      type: this.type,
      category
    }
    if (data !== undefined) {
      info.data = data.map(item => this.format(item))
    }
    const args = [this.getMessage(data), info]
    console.log(...args)
    await this.logger.info(...args)
  }

  async debug(category: string, ...data: any[]) {
    const info: any = {
      type: this.type,
      category
    }
    if (data !== undefined) {
      info.data = data.map(item => this.format(item))
    }
    const args = [this.getMessage(data), info]
    console.log(...args)
    await this.logger.debug(...args)
  }

  async error(category: string, ...data: any[]) {
    const info: any = {
      type: this.type,
      category
    }
    if (data !== undefined) {
      info.data = data.map(item => this.format(item))
    }
    const args = [this.getMessage(data), info]
    console.log(...args)
    await this.logger.error(...args)
  }

  async flush() {
    await this.logger.flush()
  }
}
