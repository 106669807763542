import {
  transformAddVariants,
  transformCampaign
} from '@/lib/client/common/transform/campaign/transformCampaign'
import { transformCampaignStatistics } from '@/lib/client/common/transform/campaign/transformCampaignStatistics'
import { qs } from '@/lib/client/common/util/qs'
import { TagId, baseApi } from '@/lib/client/redux/common/api'
import { tags } from '@/lib/client/redux/common/tags'
import { ITeamPayload } from '@/lib/client/redux/types'
import { ICampaign, ICampaignSummary } from '@/lib/common/entities/campaign/ICampaign'
import { ICampaignStatistics } from '@/lib/common/entities/campaign/statistics/ICampaignStatistics'
import { IClientCampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import * as AddVariants from '@/pages/api/team/[teamSlug]/campaign/add-variants'
import * as GetCampaign from '@/pages/api/team/[teamSlug]/campaign/by-id'
import * as GetCampaignsByTeam from '@/pages/api/team/[teamSlug]/campaign/by-team'
import * as CreateCampaign from '@/pages/api/team/[teamSlug]/campaign/create'
import * as DeleteCampaign from '@/pages/api/team/[teamSlug]/campaign/delete'
import * as DeleteVariant from '@/pages/api/team/[teamSlug]/campaign/delete-variant'
import * as LogPreviewError from '@/pages/api/team/[teamSlug]/campaign/log-preview-error'
import * as SaveCampaign from '@/pages/api/team/[teamSlug]/campaign/save'
import * as GetStatsByCampaign from '@/pages/api/team/[teamSlug]/campaign/stats'
import * as UpdateElementMeta from '@/pages/api/team/[teamSlug]/campaign/update-element-meta'
import * as UpdateCampaignEnabled from '@/pages/api/team/[teamSlug]/campaign/update-enabled'
import * as UpdateVariantEnabled from '@/pages/api/team/[teamSlug]/campaign/update-variant-enabled'
import * as GetCampaignPerformance from '@/pages/api/team/[teamSlug]/tools/campaign/performance/campaigns'

export const CampaignApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getCampaign: build.query<ICampaign, ITeamPayload<GetCampaign.Query>>({
      query: ({ team, payload }) =>
        `/team/${encodeURIComponent(team)}/campaign/by-id${qs(payload)}`,
      providesTags: result => [{ type: 'campaign', id: result?.id }],
      transformResponse: transformCampaign
    }),
    getCampaignsByTeam: build.query<ICampaign[], string>({
      query: team => `/team/${encodeURIComponent(team)}/campaign/by-team`,
      providesTags: [{ type: 'campaign', id: TagId.All }],
      transformResponse: (response: GetCampaignsByTeam.Response) => response.map(transformCampaign)
    }),
    getCampaigns: build.query<ICampaignSummary[], string>({
      query: team => `/team/${encodeURIComponent(team)}/campaign/all`
    }),
    createCampaign: build.mutation<CreateCampaign.Response, ITeamPayload<CreateCampaign.Body>>({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/campaign/create`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'campaign', id: TagId.All },
        { type: 'user' },
        { type: 'team', id: arg.team }
      ]
    }),
    saveCampaign: build.mutation<ICampaign, ITeamPayload<SaveCampaign.Body>>({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/campaign/save`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (_result, _error, arg) =>
        tags({ type: 'campaign', id: TagId.All }, { type: 'campaign', id: arg.payload.id }),
      transformResponse: transformCampaign
    }),
    updateCampaignEnabled: build.mutation<
      UpdateCampaignEnabled.Response,
      ITeamPayload<UpdateCampaignEnabled.Query>
    >({
      query: ({ team, payload }) =>
        `/team/${encodeURIComponent(team)}/campaign/update-enabled${qs(payload)}`,
      invalidatesTags: (_result, _error, arg) => [
        { type: 'campaign', id: TagId.All },
        { type: 'campaign', id: arg.payload.id },
        { type: 'notification' }
      ],
      async onQueryStarted({ team, payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          CampaignApi.util.updateQueryData('getCampaignsByTeam', team, draft => {
            const campaign = draft.find((campaign: ICampaignSummary) => campaign.id === payload.id)
            if (campaign) {
              campaign.isEnabled = payload.isEnabled
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    deleteCampaign: build.mutation<DeleteCampaign.Response, ITeamPayload<DeleteCampaign.Query>>({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/campaign/delete${qs(payload)}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'campaign', id: TagId.All }, { type: 'user' }]
    }),
    updateVariantEnabled: build.mutation<
      UpdateVariantEnabled.Response,
      ITeamPayload<UpdateVariantEnabled.Query>
    >({
      query: ({ team, payload }) =>
        `/team/${encodeURIComponent(team)}/campaign/update-variant-enabled${qs(payload)}`,
      invalidatesTags: result => [
        { type: 'campaign', id: TagId.All },
        { type: 'campaign', id: result }
      ]
    }),
    deleteVariant: build.mutation<DeleteVariant.Response, ITeamPayload<DeleteVariant.Query>>({
      query: ({ team, payload }) =>
        `/team/${encodeURIComponent(team)}/campaign/delete-variant${qs(payload)}`,
      invalidatesTags: result => [
        { type: 'campaign', id: TagId.All },
        { type: 'campaign', id: result }
      ]
    }),
    addVariants: build.mutation<
      { variants: IClientCampaignVariant[]; newVariantIds: number[] },
      ITeamPayload<AddVariants.Body>
    >({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/campaign/add-variants`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'campaign', id: TagId.All },
        { type: 'campaign', id: arg.payload.campaignId }
      ],
      transformResponse: transformAddVariants
    }),
    getStatsByCampaign: build.query<ICampaignStatistics, ITeamPayload<GetStatsByCampaign.Body>>({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/campaign/stats`,
        method: 'POST',
        body: payload
      }),
      transformResponse: transformCampaignStatistics,
      providesTags: result => [{ type: 'campaign-stats', id: result?.id }]
    }),
    logPreviewError: build.mutation<LogPreviewError.Response, ITeamPayload<LogPreviewError.Query>>({
      query: ({ team, payload }) =>
        `/team/${encodeURIComponent(team)}/campaign/log-preview-error${qs(payload)}`
    }),
    getCampaignPerformance: build.query<GetCampaignPerformance.Response, string>({
      query: team => `/team/${encodeURIComponent(team)}/campaign/performance/campaigns`
    }),
    updateElementMeta: build.mutation<
      UpdateElementMeta.Response,
      ITeamPayload<UpdateElementMeta.Body>
    >({
      query: ({ team, payload }) => ({
        url: `/team/${encodeURIComponent(team)}/campaign/update-element-meta`,
        method: 'POST',
        body: payload
      })
    })
  })
})
