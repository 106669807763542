import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'

import {
  DraftType,
  IDraftElementForm,
  IDraftElementState
} from '@/lib/client/redux/campaign/draft-element/types'
import { EditCampaignSlice } from '@/lib/client/redux/campaign/edit/EditCampaignSlice'
import { IAddVariantPosition } from '@/lib/client/redux/campaign/edit/types'
import { getDraftVariant } from '@/lib/client/redux/campaign/util/getDraftVariant'
import { getElementVariants } from '@/lib/client/redux/campaign/util/getElementVariants'
import { updateElementById } from '@/lib/client/redux/campaign/util/updateElementById'
import { updateElementBySelector } from '@/lib/client/redux/campaign/util/updateElementBySelector'
import { updateIndex } from '@/lib/client/redux/campaign/util/updateIndex'
import { validateVariant } from '@/lib/client/redux/campaign/util/validateVariant'
import { createFormReducers } from '@/lib/client/redux/form/createFormReducers'
import { createInitialFormState } from '@/lib/client/redux/form/createInitialFormState'
import { ICampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import {
  ITextVariant,
  ITextVariantStyle,
  TextVariantStyleType,
  TextVariantType
} from '@/lib/common/entities/campaign/variant/ITextVariant'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'
import { removeItem } from '@/lib/common/utils/removeItem'
import { setItem } from '@/lib/common/utils/setItem'

const initialState: IDraftElementState = createInitialFormState({
  element: undefined,
  focusedVariantId: undefined,
  type: DraftType.Default,
  isSingleVariantMode: false,
  formSubmitted: false
})

export const DraftElementSlice = createSlice({
  name: 'draftElement',
  initialState,
  reducers: {
    ...createFormReducers(initialState),

    init: (_, action: PayloadAction<Partial<IDraftElementForm>>) => ({
      ...initialState,
      values: { ...initialState.values, ...action.payload }
    }),

    setFocusedDraftVariant: (state, action: PayloadAction<number | undefined>) => {
      const variants = getElementVariants(state.values.element)

      if (!state.values.element) {
        return
      }

      state.values.focusedVariantId = action.payload
      if (variants && action.payload === undefined) {
        removeItem(variants, v => !validateVariant(v))
      }
    },

    addVariants: (
      state,
      action: PayloadAction<{
        variants: ICampaignVariant[] | undefined
        position: IAddVariantPosition
      }>
    ) => {
      const { variants: variantsToAdd, position } = action.payload

      const variants = getElementVariants(state.values.element)
      if (!variants || !variantsToAdd || variantsToAdd.length === 0) {
        return
      }
      if (position === 'start') {
        variants.unshift(...variantsToAdd)
      } else if (position === 'end') {
        variants.push(...variantsToAdd)
      } else {
        variants.splice(position + 1, 0, ...variantsToAdd)
      }
    },

    toggleVariantTextType: (state, action: PayloadAction<ITextVariant>) => {
      if (!state.values.element) {
        return
      }

      const { element } = state.values
      const variants = getElementVariants<'text'>(element)
      const variant = variants?.find(v => v.id === action.payload.id)
      if (!variant) {
        return
      }
      if (variant.info.textType === TextVariantType.Advanced) {
        variant.info.textType = TextVariantType.Normal
      } else {
        variant.info.textType = TextVariantType.Advanced
        if (variant.info.html === undefined) {
          variant.info.html = variant.info.value
        }
      }
      state.values.focusedVariantId = variant.id
    },

    removeVariant: (state, action: PayloadAction<ICampaignVariant>) => {
      const variants = getElementVariants(state.values.element)
      const variant = action.payload

      if (variants) {
        removeItem(variants, v => v.id === variant.id)
        updateIndex(variants)
      }
    },

    updateTextValue: (state, action: PayloadAction<string | undefined>) => {
      const variant = getDraftVariant<'text'>(state)
      if (variant) {
        variant.info.value = action.payload
      }
    },

    updateHTMLValue: (state, action: PayloadAction<string | undefined>) => {
      const variant = getDraftVariant<'text'>(state)
      if (variant) {
        variant.info.html = action.payload
      }
    },

    updateTextStyle: (
      state,
      action: PayloadAction<{ type: TextVariantStyleType; style: ITextVariantStyle | undefined }>
    ) => {
      const variant = getDraftVariant<'text'>(state)
      if (variant) {
        const { type, style } = action.payload
        if (style) {
          setItem(variant.info.styles, s => s.type === type, style)
        } else {
          removeItem(variant.info.styles, s => s.type === type)
        }
      }
    },

    updateTextObjective: (state, action: PayloadAction<string>) => {
      if (!state.values.element) {
        return
      }

      const { element } = state.values
      if (element?.type === 'text') {
        element.objective = action.payload
      }
    },

    duplicateTextVariant: (state, action: PayloadAction<ITextVariant>) => {
      const variants = getElementVariants(state.values.element)
      const source = action.payload

      if (variants) {
        const index = variants.findIndex(v => v.id === source.id)
        if (index !== -1) {
          variants.splice(index + 1, 0, {
            ...action.payload,
            id: generateTemporaryId()
          })
        }
        updateIndex(variants)
      }
    }
  },

  extraReducers: builder => {
    builder.addCase(EditCampaignSlice.actions.updateElementById, (state, action) => {
      const { id } = action.payload
      updateElementById(state.values.element, id, action.payload)
    })
    builder.addCase(EditCampaignSlice.actions.updateElementBySelector, (state, action) => {
      const { selector } = action.payload
      updateElementBySelector(state.values.element, selector, action.payload)
    })
    builder.addMatcher(
      isAnyOf(EditCampaignSlice.actions.init, EditCampaignSlice.actions.setStep),
      _ => ({ ...initialState })
    )
  }
})
