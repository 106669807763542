import { ISerializableDate } from '@/lib/common/dates/ISerializableDate'

export const toDate = (value: ISerializableDate) => {
  const date = new Date(value as unknown as number)
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  )
}

export const fromDate = (value: Date): ISerializableDate => {
  return Date.UTC(
    value.getFullYear(),
    value.getMonth(),
    value.getDate(),
    value.getHours(),
    value.getMinutes(),
    value.getSeconds(),
    value.getMilliseconds()
  ) as unknown as ISerializableDate
}

export const toString = (value: ISerializableDate) => {
  return toDate(value).toLocaleString()
}
