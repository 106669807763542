import { getConversionsPerSession } from '@/lib/client/common/util/getConversionsPerSession'
import { getRawConversionsPerView } from '@/lib/client/common/util/getRawConversionsPerView'
import { getRevenuePerSession } from '@/lib/client/common/util/getRevenuePerSession'
import { ICampaignBenchmark } from '@/lib/common/entities/campaign/ICampaignBenchmark'
import { IClientMetrics, IMetrics } from '@/lib/common/entities/campaign/IMetrics'

const transformClientMetric = (value: number, benchmark: number) => {
  return {
    value,
    improvement: benchmark ? (value - benchmark) / benchmark : 0,
    benchmark
  }
}

export const transformClientMetrics = (
  metrics: IMetrics,
  benchmark: ICampaignBenchmark
): IClientMetrics => {
  return {
    ...metrics,
    rawConversionsPerView: transformClientMetric(
      getRawConversionsPerView(metrics),
      benchmark.rawConversionsPerView
    ),
    conversionsPerSession: transformClientMetric(
      getConversionsPerSession(metrics),
      benchmark.conversionsPerSession
    ),
    revenuePerSession: transformClientMetric(
      getRevenuePerSession(metrics),
      benchmark.revenuePerSession
    )
  }
}
