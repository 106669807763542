import { createApi } from '@reduxjs/toolkit/query/react'

import { imageQuery } from '@/lib/client/redux/common/image/imageQuery'
import { IImageInfo } from '@/lib/client/redux/common/types'

export const ImageApi = createApi({
  baseQuery: imageQuery,
  keepUnusedDataFor: 3600,
  reducerPath: 'imageApi',
  tagTypes: [],
  endpoints: build => ({
    getImageInfo: build.query<IImageInfo, string | undefined>({
      query: url => url
    })
  })
})
