import { createSelector } from '@reduxjs/toolkit'

import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { UserSelector } from '@/lib/client/redux/user/UserSelector'
import { isOnPaid, isOnTrial } from '@/lib/common/utils/subscriptionUtils'

export const getTeam = createSelector(
  UserSelector.getContext,
  () => getTeamSlug(),
  (context, slug) => {
    if (context === undefined) {
      return undefined
    }
    return context.teams.find(t => t.slug === slug)
  }
)

export const getSubscription = createSelector(getTeam, team => team?.subscription)

export const hasSubscriberAccess = createSelector(
  UserSelector.getFeatureFlags,
  getSubscription,
  (featureFlags, subscription) => {
    if (!subscription) {
      // user has yet to get far enough into app to start trial or subscription
      return true
    }

    return isOnPaid(subscription) || isOnTrial(subscription)
  }
)
