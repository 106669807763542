import { createCampaignMetricsTransformer } from '@/lib/client/common/transform/campaign/createCampaignMetricsTransformer'
import { transformClientMetrics } from '@/lib/client/common/transform/campaign/transformClientMetrics'
import {
  IClientCampaignElement,
  IServerCampaignElement
} from '@/lib/common/entities/campaign/element/ICampaignElement'
import { ICampaign, IServerCampaign } from '@/lib/common/entities/campaign/ICampaign'
import {
  IClientCampaignVariant,
  IServerCampaignVariant
} from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { logger } from '@/lib/common/utils/logging/logger'
import * as AddVariants from '@/pages/api/team/[teamSlug]/campaign/add-variants'

const log = logger('transformCampaign')

export const transformCampaign = (campaign: IServerCampaign) => {
  const transformer = createCampaignMetricsTransformer(campaign.metrics, campaign.benchmark)
  const elements = transformElements(campaign)
  for (const element of elements) {
    if (element.control) {
      transformer.setMetrics(element.id, true, element.control.metrics)
    } else {
      log.error('control missing', element)
    }
    for (const variant of element.variants) {
      transformer.setMetrics(element.id, false, variant.metrics)
    }
  }
  const result: ICampaign = {
    ...campaign,
    elements,
    metrics: transformer.getDerivedMetrics()
  }
  return result
}

export const transformElements = (campaign: IServerCampaign) =>
  campaign.elements.map((e, index) => transformElement(e, index))

export const transformElement = (serverElement: IServerCampaignElement, index: number) => {
  const clientElement: IClientCampaignElement = {
    ...serverElement,
    index,
    control: transformVariant(serverElement.control, 0) as any,
    variants: transformVariants(serverElement.variants) as any
  }
  return clientElement
}

export const transformAddVariants = (data: AddVariants.Response) => {
  return {
    variants: transformVariants(data.variants),
    newVariantIds: data.newVariantIds
  }
}

export const transformVariants = (serverVariants: IServerCampaignVariant[]) => {
  const clientVariants: IClientCampaignVariant[] = []
  serverVariants.forEach((serverVariant, index) => {
    const clientVariant = transformVariant(serverVariant, index)
    if (clientVariant) {
      clientVariants.push(clientVariant)
    }
  })
  return clientVariants
}

export const transformVariant = (
  serverVariant: IServerCampaignVariant | undefined,
  index: number
) => {
  if (serverVariant === undefined) {
    return undefined
  }
  const clientVariant: IClientCampaignVariant = {
    ...serverVariant,
    index: index,
    metrics: transformClientMetrics(serverVariant.metrics, serverVariant.benchmark)
  }
  return clientVariant
}
