import { config } from '@/lib/common/config'
import { BaseLogger } from '@/lib/common/utils/logging/BaseLogger'
import { ILogger } from '@/lib/common/utils/logging/ILogger'

const isLocal = config.env.ENVIRONMENT === 'local'

/* eslint no-console: 0 */
export class ConsoleLogger extends BaseLogger implements ILogger {
  info(category: string, ...data: any[]) {
    if (isLocal) {
      console.groupCollapsed(
        '%cINFO %c' + this.formatDate() + ' ' + category + ' %c' + this.getMessage(data),
        'color: gray; font-weight: lighter; padding: 2px',
        'color: gray; font-weight: lighter; padding: 2px',
        'color: black'
      )
      for (const item of data) {
        console.log(item)
      }
      console.groupEnd()
    } else {
      console.log(...data)
    }
  }

  debug(category: string, ...data: any[]) {
    if (isLocal) {
      console.groupCollapsed(
        '%cDEBUG %c' + this.formatDate() + ' ' + category + ' %c' + this.getMessage(data),
        'color: green; padding: 2px',
        'color: gray; font-weight: lighter; padding: 2px',
        'color: black'
      )
      for (const item of data) {
        console.log(item)
      }
      console.groupEnd()
    } else {
      console.log(...data)
    }
  }

  error(category: string, ...data: any[]) {
    if (isLocal) {
      console.groupCollapsed(
        '%cERROR %c' + this.formatDate() + ' ' + category + ' %c' + this.getMessage(data),
        'color: red; padding: 2px',
        'color: red; font-weight: lighter; padding: 2px',
        'color: red'
      )
      for (const item of data) {
        console.log(item)
      }
      console.groupEnd()
    } else {
      console.log(...data)
    }
  }

  formatDate() {
    const date = new Date()
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`
  }

  async flush() {}
}
