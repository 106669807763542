import { showConfirmDialog } from '@/components/admin/dialog/ConfirmDialog'
import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { getDraftElement } from '@/lib/client/redux/campaign/draft-element/selectors/common'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'

export const deleteElement = createAsyncThunk<void, ICampaignElement, IThunkConfig>(
  'editCampaign/deleteElement',
  async (element, { getState, dispatch }) => {
    if (
      await showConfirmDialog({
        title: 'Delete element',
        message:
          'Are you sure you want to delete this element? Once deleted it cannot be recovered.',
        isConfirmButtonRed: true
      })
    ) {
      const draftElement = getDraftElement(getState())
      if (element.id === draftElement?.id) {
        dispatch(DraftElementAction.setDraft(undefined))
      }
      dispatch(EditCampaignAction.setSelectedVariant({ element, variantId: undefined }))
      dispatch(EditCampaignAction.removeElement(element))
    }
  }
)
