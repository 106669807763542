import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { getElements } from '@/lib/client/redux/campaign/edit/selectors/common'
import { CampaignStep } from '@/lib/client/redux/campaign/edit/types'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { PreviewAction } from '@/lib/client/redux/preview/PreviewAction'
import { IThunkConfig } from '@/lib/client/redux/types'

export const setStepEditGoals = createAsyncThunk<void, void, IThunkConfig>(
  'editCampaign/setStepEditGoals',
  async (_, { getState, dispatch }) => {
    const elements = getElements(getState())
    const element = elements.find(el => el.variants.length === 0)
    if (element) {
      dispatch(
        DraftElementAction.setDraft({
          element: element
        })
      )
    } else {
      dispatch(EditCampaignAction.setStep(CampaignStep.EditGoals))
    }
    dispatch(PreviewAction.setInteractionMode(undefined))
  }
)
