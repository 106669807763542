import { showConfirmDialog } from '@/components/admin/dialog/ConfirmDialog'
import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftElementSelector } from '@/lib/client/redux/campaign/draft-element/DraftElementSelector'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'

export const cancel = createAsyncThunk<void, void, IThunkConfig>(
  'draftElement/cancel',
  async (_, { getState, dispatch }) => {
    const hasChanges = DraftElementSelector.hasDraftElementChanges(getState())
    if (
      !hasChanges ||
      (await showConfirmDialog({
        title: 'Are you sure you want to cancel?',
        message: 'You have unsaved changes.',
        isConfirmButtonRed: true
      }))
    ) {
      dispatch(DraftElementAction.setDraft(undefined))
    }
  }
)
