import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import {
  IURLPreviewImageSelectEvent,
  IURLPreviewTextSelectEvent
} from '@/lib/client/redux/preview/types'
import { IThunkConfig } from '@/lib/client/redux/types'
import { INewImageElement } from '@/lib/common/entities/campaign/element/IImageElement'
import { INewTextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import { INewImageVariant } from '@/lib/common/entities/campaign/variant/IImageVariant'
import { INewTextVariant } from '@/lib/common/entities/campaign/variant/ITextVariant'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'

type IEvent = IURLPreviewTextSelectEvent | IURLPreviewImageSelectEvent

export const createElement = createAsyncThunk<void, IEvent, IThunkConfig>(
  'editCampaign/createElement',
  async (ev, { dispatch }) => {
    const element = create(ev)
    if (element) {
      dispatch(EditCampaignAction.addElement(element))
      if (element.type === 'text') {
        dispatch(EditCampaignAction.generateInitialTextVariants(element))
      } else if (element.type === 'image') {
        dispatch(EditCampaignAction.generateSuggestedImageVariants(element))
      }
    }
  }
)

const create = (event: IEvent) => {
  switch (event.type) {
    case 'text:select': {
      const element: INewTextElement = {
        __new__: true,
        id: generateTemporaryId(),
        selector: event.selector,
        type: 'text',
        textType: event.textType,
        control: {
          info: {
            value: event.value,
            html: event.html
          }
        } as INewTextVariant,
        variants: [],
        attributes: {
          text: true,
          color: false,
          fontSize: false,
          borderColor: false,
          borderWidth: false,
          padding: false,
          borderRadius: false
        },
        objective: '',
        index: 0,
        domStats: event.stats
      }
      return element
    }
    case 'image:select': {
      const element: INewImageElement = {
        __new__: true,
        id: generateTemporaryId(),
        key: '',
        selector: event.selector,
        type: 'image',
        control: {
          info: {
            src: event.src
          }
        } as INewImageVariant,
        variants: [],
        index: 0
      }
      return element
    }
  }
}
