import Router from 'next/router'

import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { INavigatePayload } from '@/lib/client/redux/location/types'
import { convert } from '@/lib/client/redux/location/util/convert'
import { IThunkConfig } from '@/lib/client/redux/types'

export const replace = createAsyncThunk<void, INavigatePayload, IThunkConfig>(
  'location/replace',
  async args => {
    const params = convert(args)
    if (params) {
      await Router.replace(params.url, params.as, params.options)
    }
  }
)
