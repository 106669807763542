import { isAnyOf } from '@reduxjs/toolkit'

import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { validateEditCampaign } from '@/lib/client/redux/campaign/util/validateEditCampaign'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const validateForm = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    matcher: isAnyOf(EditCampaignAction.init, EditCampaignAction.processMetaEvent),
    effect: async (_, { getState, dispatch }) => {
      const { values, info, errors } = EditCampaignSelector.getState(getState())
      const newErrors = await validateEditCampaign(undefined, values, info, errors)
      dispatch(EditCampaignAction.setFormErrors(newErrors))
    }
  })
}
