import Router from 'next/router'

import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { INavigatePayload } from '@/lib/client/redux/location/types'
import { convert } from '@/lib/client/redux/location/util/convert'
import { IThunkConfig } from '@/lib/client/redux/types'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('push')

export const push = createAsyncThunk<void, INavigatePayload, IThunkConfig>(
  'location/push',
  async args => {
    const params = convert(args)
    if (params) {
      await Router.push(params.url, params.as, params.options)
    }
  }
)
