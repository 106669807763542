import React, { useContext, useEffect, useState } from 'react'

import { useUser } from '@/lib/client/redux/user/hooks/useUser'
import { config } from '@/lib/common/config'

interface GlobalApi {
  init: (options: any) => Promise<void>
  destroy: () => Promise<void>
  logEvent: (eventName: string) => Promise<void>
  on: (eventName: string, callback: (event: any) => void) => void
  off: (eventName: string, callback: (event: any) => void) => void
}

export const UsersnapContext = React.createContext<GlobalApi | null>(null)

declare global {
  interface Window {
    onUsersnapCXLoad: any
  }
}

export const UsersnapProvider = ({ children }: any) => {
  const [usersnapApi, setUsersnapApi] = useState(null)
  const user = useUser()

  useEffect(() => {
    let usersnapApi: any = null
    window.onUsersnapCXLoad = function (api: any) {
      api.init({
        user: {
          email: user?.email,
          userId: user?.id
        }
      })
      usersnapApi = api
      setUsersnapApi(api)
    }
    const script = document.createElement('script')
    script.defer = 1 as any
    script.src = `https://widget.usersnap.com/global/load/${config.env.USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`
    document.getElementsByTagName('head')[0].appendChild(script)

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy()
      }
      script.remove()
    }
  }, [user])

  return <UsersnapContext.Provider value={usersnapApi}>{children}</UsersnapContext.Provider>
}

export function useUsersnapApi() {
  return useContext(UsersnapContext)
}
