import { IDOMStats } from '@/lib/common/entities/campaign/element/ITextElement'
import { sortColors } from '@/lib/common/utils/color/sortColors'

export function getSwatches(stats: IDOMStats): number[] {
  if (stats === undefined) {
    return []
  }
  const colors = new Set<number>()
  const text = pool(stats.textColor.map(item => item.value))
  const background = pool(stats.backgroundColor.map(item => item.value))
  while (colors.size < 5 && text.hasItems()) {
    colors.add(text.getItem())
  }
  while (colors.size < 8 && background.hasItems()) {
    colors.add(background.getItem())
  }
  while (colors.size < 8 && text.hasItems()) {
    colors.add(text.getItem())
  }
  return sortColors(Array.from(colors))
}

function pool(items: any[]) {
  let index = 0
  return {
    hasItems: () => index < items.length,
    getItem: () => items[index++],
    count: () => index
  }
}
