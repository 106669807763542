import axios from 'axios'

import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftElementSelector } from '@/lib/client/redux/campaign/draft-element/DraftElementSelector'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { config } from '@/lib/common/config'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'
import { teamApiUrl } from '@/lib/common/utils/urls'

export const uploadImageVariants = createAsyncThunk<string | undefined, File[], IThunkConfig>(
  'draftElement/uploadImageVariants',
  async (files, { getState, dispatch }) => {
    const team = getTeamSlug()
    const variantCount = DraftElementSelector.getNewVariantCount(getState())

    const MAX_SIZE = config.settings.MAX_IMAGE_SIZE_MB * 1024 * 1024 // 4MB
    for (const file of files) {
      if (file.size > MAX_SIZE) {
        return `File is too large. The maximum file size is ${config.settings.MAX_IMAGE_SIZE_MB}MB.`
      }
    }

    if (files.length === 0) {
      return 'File type not supported. We accept JPG, PNG and GIF.'
    }
    if (variantCount + files.length > config.settings.MAX_DRAFT_VARIANTS) {
      return `You have uploaded too many files. You can have up to ${config.settings.MAX_DRAFT_VARIANTS} variant selections for this element.`
    }
    const url = teamApiUrl(team, '/campaign/upload-images')
    const response = await axios.postForm<{ files: string[] }>(url, {
      file: files
    })
    dispatch(
      DraftElementAction.addVariants({
        variants: response.data.files.map(file => ({
          __type__: NewVariantType.Draft,
          id: generateTemporaryId(),
          index: 0,
          type: 'image',
          info: {
            src: file,
            thumb: file
          }
        })),
        position: 'end'
      })
    )
    return undefined
  }
)
