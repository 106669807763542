import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftType } from '@/lib/client/redux/campaign/draft-element/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import {
  getDraftVariantsFactory,
  getEditMode
} from '@/lib/client/redux/campaign/edit/selectors/common'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ICampaignElement } from '@/lib/common/entities/campaign/element/ICampaignElement'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { ICampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { isSuggestedVariant } from '@/lib/common/entities/campaign/variant/util'
import { generateTemporaryId } from '@/lib/common/utils/generateTemporaryId'

export const duplicateVariant = createAsyncThunk<
  void,
  { element: ICampaignElement; variant: ICampaignVariant; isSingleVariantMode?: boolean },
  IThunkConfig
>(
  'editCampaign/duplicateVariant',
  async ({ element, variant, isSingleVariantMode = true }, { dispatch, getState }) => {
    const mode = getEditMode(getState())

    if (mode === 'create') {
      const clone = {
        ...variant,
        id: generateTemporaryId(),
        __type__: NewVariantType.Suggested
      }

      const existingVariants = getDraftVariantsFactory(element.id)(getState())
      dispatch(
        EditCampaignAction.setVariants({
          elementId: element.id,
          variants: [...existingVariants, clone]
        })
      )
      return
    }

    const draftElement = { ...element }

    const duplicateVariantId = generateTemporaryId()
    draftElement.variants = [
      ...draftElement.variants,
      {
        ...variant,
        id: duplicateVariantId,
        __type__: NewVariantType.Draft
      }
    ] as any[] // todo - spencer

    dispatch(
      DraftElementAction.setDraft({
        element: draftElement,
        focusedVariantId: duplicateVariantId,
        isSingleVariantMode,
        type: isSuggestedVariant(variant) ? DraftType.EditSuggestion : undefined,
        originalElement: element
      })
    )
  }
)
