import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import {
  getDraftElement,
  getDraftVariant
} from '@/lib/client/redux/campaign/draft-element/selectors/common'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ICampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { assertDefined } from '@/lib/common/guards/assertDefined'

export const deleteVariant = createAsyncThunk<void, ICampaignVariant, IThunkConfig>(
  'draftElement/deleteVariant',
  async (variant, { getState, dispatch }) => {
    const element = getDraftElement(getState())
    assertDefined(element, 'element is undefined')

    const draftVariant = getDraftVariant(getState())
    if (variant.id === draftVariant?.id) {
      dispatch(DraftElementAction.focusDraftVariant(undefined))
    }
    dispatch(DraftElementAction.removeVariant(variant))
  }
)
