import {
  INewTextVariant,
  ITextVariant,
  ITextVariantStyle,
  TextVariantStyleType,
  TextVariantType
} from '@/lib/common/entities/campaign/variant/ITextVariant'

export const isEqual = (a: INewTextVariant, b: ITextVariant) => {
  if (a.info.textType !== b.info.textType) return false

  switch (a.info.textType) {
    case TextVariantType.Normal: {
      return getAttributes(a) === getAttributes(b)
    }
    case TextVariantType.Advanced:
      return a.info.html === b.info.html
  }
}

const getAttributes = (variant: ITextVariant) => {
  return JSON.stringify({
    value: variant.info.value,
    [TextVariantStyleType.TextColor]: getStyleAttribute(
      variant.info.styles,
      TextVariantStyleType.TextColor
    ),
    [TextVariantStyleType.BackgroundColor]: getStyleAttribute(
      variant.info.styles,
      TextVariantStyleType.BackgroundColor
    ),
    [TextVariantStyleType.FontSize]: getStyleAttribute(
      variant.info.styles,
      TextVariantStyleType.FontSize
    )
  })
}

const getStyleAttribute = (styles: ITextVariantStyle[], type: TextVariantStyleType) => {
  return styles.find(style => style.type === type)?.value
}
