import { config } from '@/lib/common/config'
import { ConsoleLogger } from '@/lib/common/utils/logging/ConsoleLogger'
import { ILogger } from '@/lib/common/utils/logging/ILogger'
import { LogtailLogger } from '@/lib/common/utils/logging/LogtailLogger'

let _logger: ILogger
if (config.env.LOGTAIL_TOKEN) {
  _logger = new LogtailLogger(config.env.LOGTAIL_TOKEN)
} else {
  _logger = new ConsoleLogger()
}

type IExecute = (logger: ILogger, category: string, data: any) => void

export const logger = (category: string) => {
  return {
    execute: (data: any, execute: IExecute, cat?: string) => {
      if (cat !== undefined) {
        category = cat
      }
      if (isValidCategory(category)) {
        execute(_logger, category, data)
      }
    },
    info: (...data: any[]) => {
      if (isValidCategory(category)) {
        _logger.info(category, ...data)
      }
    },
    debug: (...data: any[]) => {
      _logger.debug(category, ...data)
    },
    error: (...data: any[]) => {
      _logger.error(category, ...data)
    },
    flush: () => {
      return _logger.flush()
    },
    timer: () => {
      const start = Date.now()
      return (message: string, ...data: any[]) => {
        _logger.debug(category, `${message}: ${Date.now() - start} ms`, ...data)
      }
    }
  }
}

function isValidCategory(category: string) {
  const { type } = config.logging
  const matches = matchesCategory(category)
  return type === 'blacklist' ? !matches : matches
}

function matchesCategory(category: string) {
  const { categories } = config.logging
  for (const prefix of categories) {
    if (prefix === '*' || category.startsWith(prefix)) {
      return true
    }
  }
  return false
}
