import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { GenerateImageAction } from '@/lib/client/redux/image/generate/GenerateImageAction'
import { IListenersMiddleware } from '@/lib/client/redux/types'

export const setPrompt = (middleware: IListenersMiddleware) => {
  middleware.startListening({
    actionCreator: DraftElementAction.setDraft.fulfilled,
    effect: async (action, { dispatch }) => {
      const element = action.meta.arg?.element
      if (element?.type === 'image' && element.prompt) {
        dispatch(GenerateImageAction.setPrompt(element.prompt))
      }
    }
  })
}
