import { getTeamSlug } from '@/lib/client/common/navigation/getTeamSlug'
import { CampaignApi } from '@/lib/client/redux/campaign/CampaignApi'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { getEditMode, getElement, getId } from '@/lib/client/redux/campaign/edit/selectors/common'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IThunkConfig } from '@/lib/client/redux/types'
import { NewVariantType } from '@/lib/common/entities/campaign/variant/IBaseVariant'
import { ISuggestedCampaignVariant } from '@/lib/common/entities/campaign/variant/ICampaignVariant'
import { isSuggestedVariant } from '@/lib/common/entities/campaign/variant/util'
import { assertDefined } from '@/lib/common/guards/assertDefined'
import { Body } from '@/pages/api/team/[teamSlug]/campaign/add-variants'

export const approveSuggestedVariant = createAsyncThunk<
  void,
  { elementId: number; variant: ISuggestedCampaignVariant },
  IThunkConfig
>(
  'editCampaign/approveSuggestedVariant',
  async ({ elementId, variant }, { dispatch, getState }) => {
    const mode = getEditMode(getState())
    if (mode === 'create') {
      const element = getElement(elementId)(getState())
      const v = element.variants.find(v => v.id === variant.id)
      const variants = element.variants.filter(current => current !== v)
      assertDefined(v, 'variant is undefined')
      if (isSuggestedVariant(v)) {
        dispatch(
          EditCampaignAction.resetVariants({
            elementId,
            variants: [...variants, { ...v, __type__: NewVariantType.Draft }]
          })
        )
      }
    } else if (mode === 'update') {
      const newVariants = [
        {
          ...variant,
          __type__: NewVariantType.Draft
        }
      ]
      dispatch(EditCampaignAction.setIsActionLoading(true))
      const payload: Body = {
        campaignId: getId(getState()),
        elementId: elementId,
        variants: newVariants
      }
      const { addVariants } = CampaignApi.endpoints
      const team = getTeamSlug()
      const { variants, newVariantIds } = await dispatch(
        addVariants.initiate({ team, payload })
      ).unwrap()

      dispatch(EditCampaignAction.setVariants({ elementId, variants }))
      dispatch(EditCampaignAction.setIsActionLoading(false))
      dispatch(EditCampaignAction.removeVariants({ elementId, variantIds: [variant.id] }))
      dispatch(
        EditCampaignAction.updateVariantsAddedInSession({
          elementId,
          variantIds: newVariantIds
        })
      )
    }
  }
)
