import { getVariantPool } from '@/lib/client/redux/campaign/edit/selectors/common'
import { IState } from '@/lib/client/redux/types'
import { ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import { TextVariantType } from '@/lib/common/entities/campaign/variant/ITextVariant'
import { logger } from '@/lib/common/utils/logging/logger'

const log = logger('getCurrentTextVariants')

export const getCurrentTextVariants = (state: IState, element: ITextElement) => {
  const pool = getVariantPool(state)
  const variantPool = pool[element.id]
  const variants: string[] = []
  if (variantPool === undefined) {
    for (const v of element.variants) {
      if (v.info.textType === TextVariantType.Normal) {
        const value = v.info.value?.trim()
        if (value) {
          variants.push(value)
        }
      }
    }
  } else {
    for (const v of variantPool.total) {
      const value = v.value?.trim()
      if (value) {
        variants.push(value)
      }
    }
  }
  return variants
}
