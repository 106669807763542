export function assertDefined<T>(
  value: T | undefined,
  message: string,
  data?: any
): asserts value is T {
  if (value === undefined) {
    if (data !== undefined) {
      throw new Error(`${message}: ${JSON.stringify(data)}`)
    } else {
      throw new Error(`${message}`)
    }
  }
}
