import { createSelector } from '@reduxjs/toolkit'

import { URLPreviewIFrameStatus, URLPreviewLoadStatus } from '@/lib/client/redux/preview/types'
import { IState } from '@/lib/client/redux/types'

export const getUrl = (state: IState) => state.preview.url
export const getInteractionMode = (state: IState) => state.preview.interactionMode
export const getLoadStatus = (state: IState) => state.preview.loadStatus

export const getIFrameStatus = (state: IState) => state.preview.iframeStatus

export const getSize = (state: IState) => state.preview.size
export const isLoading = createSelector(
  getLoadStatus,
  status => status === URLPreviewLoadStatus.Loading
)
export const isSuccess = createSelector(
  getLoadStatus,
  status => status === URLPreviewLoadStatus.Success
)
export const hasError = createSelector(
  getLoadStatus,
  status => status === URLPreviewLoadStatus.Error
)
export const isReady = createSelector(
  getIFrameStatus,
  getLoadStatus,
  (iframeStatus, loadStatus) =>
    iframeStatus === URLPreviewIFrameStatus.Ready &&
    (loadStatus === URLPreviewLoadStatus.Success || loadStatus === URLPreviewLoadStatus.Error)
)
export const getErrorCount = (state: IState) => state.preview.errorCount
