import { PayloadAction } from '@reduxjs/toolkit'

import {
  IFormErrors,
  IFormState,
  ISetFormErrorPayload,
  ISetFormValuePayload
} from '@/lib/client/redux/form/types'

export const createFormReducers = <Form extends object>(_: IFormState<Form>) => ({
  setFormValue: (state: IFormState<Form>, action: PayloadAction<ISetFormValuePayload<Form>>) => {
    const { id, value } = action.payload
    state.meta.hasFormInput = true
    state.values[id] = value
  },

  setIsSubmitting: (state: IFormState<Form>, action: PayloadAction<boolean>) => {
    state.meta.isSubmitting = action.payload
  },

  setFormErrors: (state: IFormState<Form>, action: PayloadAction<IFormErrors>) => {
    state.errors = action.payload
  },

  setFormError: (state: IFormState<Form>, action: PayloadAction<ISetFormErrorPayload>) => {
    const { id, error } = action.payload
    if (error !== undefined) {
      state.errors[id] = error
    } else {
      delete state.errors[id]
    }
  },

  incrementSubmitCount: (state: IFormState<Form>) => {
    state.meta.submitCount = state.meta.submitCount + 1
  }
})
