import { DraftElementAction } from '@/lib/client/redux/campaign/draft-element/DraftElementAction'
import { DraftElementSelector } from '@/lib/client/redux/campaign/draft-element/DraftElementSelector'
import { DraftError } from '@/lib/client/redux/campaign/draft-element/types'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { isEqual } from '@/lib/client/redux/campaign/util/isEqual'
import { createAsyncThunk } from '@/lib/client/redux/common/createAsyncThunk'
import { IFormErrors } from '@/lib/client/redux/form/types'
import { IThunkConfig } from '@/lib/client/redux/types'
import { ITextElement } from '@/lib/common/entities/campaign/element/ITextElement'
import {
  IClientTextVariant,
  INewTextVariant,
  ITextVariant
} from '@/lib/common/entities/campaign/variant/ITextVariant'
import { assertDefined } from '@/lib/common/guards/assertDefined'

export const checkForErrors = createAsyncThunk<void, ITextElement, IThunkConfig>(
  'draftElement/checkForErrors',
  async (draftElement, { getState, dispatch }) => {
    assertDefined(draftElement, 'draftElement is undefined')

    const newVariants = DraftElementSelector.getNewVariants<'text'>(getState()) ?? []
    const existingVariants = (EditCampaignSelector.getClientVariantsFactory(draftElement.id)(
      getState()
    ) ?? []) as IClientTextVariant[]
    const variants = [...newVariants, ...existingVariants]

    const errors: IFormErrors = {}
    for (const newVariant of newVariants) {
      const error = checkIfDuplicateExists(newVariant, variants)
      if (error) {
        errors[newVariant.id] = error
      }
    }

    dispatch(DraftElementAction.setFormErrors(errors))
  }
)

const checkIfDuplicateExists = (original: INewTextVariant, variants: ITextVariant[]) => {
  for (const variant of variants) {
    if (variant.id !== original.id && isEqual(original, variant)) {
      return {
        type: DraftError.DuplicateVariant,
        message:
          'You have a duplicate variant. Please change an attribute or delete the duplicate variant to continue.',
        variantId: original.id
      }
    }
  }
}
