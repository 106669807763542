import { generateText } from '@/lib/client/common/api/text/generateText'
import { IGenerateTextData } from '@/lib/client/common/api/text/types'
import { EditCampaignAction } from '@/lib/client/redux/campaign/edit/EditCampaignAction'
import { EditCampaignSelector } from '@/lib/client/redux/campaign/edit/EditCampaignSelector'
import { getCurrentTextVariants } from '@/lib/client/redux/campaign/util/getCurrentTextVariants'
import { updateElementMeta } from '@/lib/client/redux/campaign/util/updateElementMeta'
import { IListenerAPI } from '@/lib/client/redux/types'

export const populateVariantPool = async (data: IGenerateTextData, listenerApi: IListenerAPI) => {
  const { dispatch, getState } = listenerApi

  dispatch(EditCampaignAction.validateVariantPool(data))
  const pool = EditCampaignSelector.getVariantPoolFactory(data.element.id)(getState())

  const generateCount = pool ? Math.max(data.count - pool.available.length, 0) : data.count
  if (generateCount === 0) {
    return false
  }

  data.variants = getCurrentTextVariants(getState(), data.element)
  await updateElementMeta(data, listenerApi)

  const response = await generateText(data, generateCount)
  dispatch(EditCampaignAction.addVariantsToPool({ data, response }))

  return true
}
