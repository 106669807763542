import { createSelector } from '@reduxjs/toolkit'

import { IFormSelectors, IFormSlice, IFormState } from '@/lib/client/redux/form/types'
import { IState } from '@/lib/client/redux/types'

export const createFormSelectors = <Form extends object, Selectors extends object>(
  slice: IFormSlice<Form>,
  creator?: (selectors: IFormSelectors<Form>) => Selectors
) => {
  const getState = (state: IState) => state[slice.name as keyof IState] as IFormState<Form>
  const formSelectors: IFormSelectors<Form> = {
    getFormState: (state: IState) => getState(state),
    getFormValues: (state: IState) => getState(state).values,
    getFormMeta: (state: IState) => getState(state).meta,
    getFormErrors: (state: IState) => getState(state).errors,
    hasFormErrors: createSelector(
      (state: IState) => getState(state).errors,
      errors => Object.entries(errors).length > 0
    ),
    getFormErrorFactory: createSelector(
      (id: string) => id,
      id =>
        createSelector(
          (state: IState) => getState(state).errors,
          errors => errors[id]
        )
    ),
    hasFormInput: (state: IState) => getState(state).meta.hasFormInput,
    isFormSubmitting: (state: IState) => getState(state).meta.isSubmitting,
    getFormSubmitCount: (state: IState) => getState(state).meta.submitCount,
    isFormSubmitted: (state: IState) => getState(state).meta.submitCount > 0
  }
  return {
    ...formSelectors,
    ...creator?.(formSelectors)
  }
}
